import React, { useEffect } from 'react'
import Pagination from '../../Layout/Pagination';
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import { useModalDialog } from '../../Layout/ModalDialogCustomized';
import HomeOERequestsSingleRecordTable from './HomeOERequestsSingleRecordTable';
import { Button, Checkbox, TextField, Divider } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Moment from 'moment';
import dataReadyIconSVG from "../../../Icons/dataReadyIcon.svg";
import editPenIcon from "../../../Icons/editPen.svg";
import saveIconSVG from "../../../Icons/save.svg";
import oeDataReadyDisabled from "../../../Icons/oeDataReadyDisabled.svg";
import oeDataReadyEnabled from "../../../Icons/oeDataReadyEnabled.svg";
import GenericTable from "../../Layout/GenericTable";
import * as APIs from "../../../Data/APIs";
import Zoom from '@material-ui/core/Zoom';
import Truncate from "react-truncate";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomTooltip } from "../../Layout/Tooltip";
import { styled } from '@mui/system';
import { FailureModal } from '../../Modals/FailureModal';


const HomeOERequestsTable = ({
    advancedFiltersClicked,
    quickFiltersClicked,
    oERequestsList,
    setOERequestsList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    selectedPageAdv,
    onSetSelectedPageAdv,
    pageSizeAdv,
    onSetPageSizeAdv,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    getOERequestsQuickData,
    getOERequestsAdvancedData,
    groupsAndOEContactsList,
    setSelectedOEs,
    selectedOEs,
    ...props
}) => {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const [sortOrder, setSortOrder] = React.useState("ASC");
    const [SelectAllOEsChecked, setSelectAllOEsChecked] = React.useState(false);
    const { openLoader, closeLoader } = useLoader();
    const [editIsolvedContact, setEditIsolvedContact] = React.useState(false);
    const dialog = useModalDialog(0);
    const [isolvedContacts, setIsolvedContacts] = React.useState({});
    const [checkedOEs, setCheckedOEs] = React.useState([]);

    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '0 10px',
        color: "var(--grey2-secondary-color)",
        backgroundColor: "var(--grey1)",
        fontSize: "12px",
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });

    const getTitleSendOEButton = (oeRequest) => {
        if (oeRequest?.OERequestStatusID === 4) {
            return <div>This button is disabled because OE request is Blocked</div>;
        } else if (oeRequest?.RequestTypeName === "One Time") {
            return <div>Please add a comment if the OE data is ready</div>;
        } else if (oeRequest?.OERequestStatusID === 3) {
            return <div>This button is disabled because OE request is Cancelled</div>;
        } else if (Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate) {
            return <div>Plan Year Start Date has passed, updates will go through the weekly files</div>;
        } else if ((new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45) {
            return <div>The earliest for an OE file to be sent is 45 days before the PYSD to capture terminations as expected</div>;
        }
        else {
            return <div>OE Data is Ready</div>;
        }
    }


    const handleSelectAllOEsChange = (value) => (e) => {
        setSelectAllOEsChecked(e.target.checked);
    }

    const handleSelectAllOEsClick = (value) => (event) => {
        let selectedOEsArray = selectedOEs;
        if (!value || value === "") selectedOEsArray = [];
        else if (selectedOEsArray.indexOf(...value) >= 0) {
            selectedOEsArray.splice(selectedOEsArray.indexOf(...value));
        }
        else {
            selectedOEsArray.length = 0;
            selectedOEsArray.push(...value);
        }
        setSelectedOEs([...selectedOEsArray]);
    }




    // -------------------------------- NEW TABLE ---------------------------------
    const isIsovedSuperAdminOrSuperAdmin = () => {
        //attached action ID to isolvedSuperAdmin
        return userActions?.includes("57") || userActions?.includes("20");
    }

    const editIsolvedContactFn = (oeRequest) => {
        openLoader();
        const editIsolvedContactRequest = {
            OERequestID: oeRequest?.Id,
            IsolvedContact: isolvedContacts[oeRequest?.Id]?.CognitoID,
        };

        APIs.editOEClientContact(editIsolvedContactRequest).then((r) => {
            if (quickFiltersClicked) getOERequestsQuickData(); else getOERequestsAdvancedData();
            setEditIsolvedContact(false);
            closeLoader();
        });
    }

    const handleExpectedChanges = (OERequestId) => {
        openLoader();
        APIs.getOERequestDetails(OERequestId).then(d => {
            if (d?.success === 1) {
                dialog.openDialog(
                    <div>
                        <h5 className="black1 fw-700">Expected Changes</h5>
                        {/* <Divider /> */}
                    </div>,
                    <>
                        <textarea
                            type="text"
                            className="action-item-textarea textfield-div"
                            placeholder="Expected Changes"
                            rows={7}
                            disabled
                            value={d?.data?.OEChanges}
                            style={{ minWidth: '900px' }}
                        />
                    </>

                )
            }
        })
            .catch(err => console.log('err', err))
            .finally(() => {
                closeLoader();
            })
    }



    const handleOEsSelectionClick = (value) => (event) => {
        let selectedOEsArray = selectedOEs;
        let CurrentIndex = selectedOEs.findIndex(item => item?.Id == value?.Id);
        if (!value || value === "") selectedOEsArray = [];
        else if (CurrentIndex >= 0) {
            selectedOEsArray.splice(CurrentIndex, 1);
        }
        else {
            selectedOEsArray.push(value);
        }
        setSelectedOEs([...selectedOEsArray]);
    }

    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey);
            onSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey);
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const baseColumnsArray = [
        {
            name: 'OE Request ID',
            width: '70px',
            sortable: true,
            sortingkey: 'Id',
            id: 'Id',
            selector: (c) =>
                !userRoles?.includes("6") ?
                    <a href={`/OERequests/ViewConnectivityRequest/${c?.ConnectivityRequestID}/ViewOERequest/${c?.Id}`}>{c?.Id}</a> :
                    <>{c?.Id}</>
        },
        {
            name: 'Client Name',
            sortable: true,
            sortingkey: 'GroupName',
            id: 'GroupName',
            selector: (c) =>
                !userRoles?.includes("6") ?
                    <a href={`/GroupRequests/ViewGroupRequest/${c?.GroupRequestID}`}>{c?.PeoName ? c?.PeoName : c?.GroupName}</a> :
                    <>{c?.PeoName ? c?.PeoName : c?.GroupName}</>
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: (c) => !userRoles?.includes("6") ?
                <a href={`/ConnectivityRequests/ViewConnectivityRequest/${c.ConnectivityRequestID}`}>{c?.CarrierName}</a> :
                <>{c?.CarrierName}</>
        },
        {
            name: 'Updated Group Structure',
            width: '70px',
            sortable: true,
            sortingkey: 'UpdatedGroupStructure',
            id: 'UpdatedGroupStructure',
            selector: (c) => c?.UpdatedGroupStructure === 1 ? "Yes" : "No"
        },
        {
            name: 'Plan Year Start Date',
            sortable: true,
            sortingkey: 'PlanYearStartDate',
            id: 'PlanYearStartDate',
            selector: c => c?.PlanYearStartDate ? Moment(c?.PlanYearStartDate).format('MM/DD/YYYY') : '',
        },
        {
            name: 'Request Type',
            sortable: true,
            sortingkey: 'RequestTypeName',
            id: 'RequestTypeName',
            selector: c => c?.RequestTypeName
        },
        {
            name: "Client's Expected Data Ready Date",
            width: '110px',
            sortable: true,
            sortingkey: 'ClientDataExpectedDate',
            id: 'ClientDataExpectedDate',
            selector: (c) => Moment(c?.ClientDataExpectedDate).format('MM/DD/YYYY')
        },
        {
            name: 'Draft',
            width: '70px',
            sortable: true,
            sortingkey: 'IsDraftOERequest',
            id: 'IsDraftOERequest',
            selector: (c) => c?.IsDraftOERequest === 1 ? "Yes" : "No"
        },
        {
            name: "OE File Transmission Date",
            width: '100px',
            sortable: true,
            sortingkey: 'OEFileSubmissionDate',
            id: 'OEFileSubmissionDate',
            selector: (c) => c?.OEFileSubmissionDate !== null && c?.OEFileSubmissionDate !== "0000-00-00" ? Moment(c?.OEFileSubmissionDate).format('MM/DD/YYYY') : ""
        },
        {
            name: "Send OE Data",
            width: '70px',
            selector: (c) =>
                (userActions?.includes("57") || userActions?.includes("20")) ? (
                    <CustomTooltip TransitionComponent={Zoom} title={c?.SendOEFileDone === 1 ? `OE Data is Ready` : getTitleSendOEButton(c)}>
                        <span>
                            <button disabled={
                                selectedOEs.length != 0 ||
                                c?.RequestTypeName === "One Time" ||
                                c?.SendOEFileDone === 1 ||
                                c?.cStatusID === 3 ||
                                c?.OERequestStatusID === 4 ||
                                Moment(todayDate).format('YYYY-MM-DD') >= c?.PlanYearStartDate ||
                                (new Date(c?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                            }
                                style={(selectedOEs.length != 0 || c?.SendOEFileDone === 1 || c?.OERequestStatusID === 3 || c?.OERequestStatusID === 4 || Moment(todayDate).format('YYYY-MM-DD') >= c?.PlanYearStartDate) ? { pointerEvents: "none", } : {}} className="sendOEButtonDashboard" onClick={(e) => {
                                    openLoader();
                                    APIs.sendOEFile({ OERequestIDs: [c.Id] }).then(r => {
                                        if (r.success === "1") {
                                            openLoader();
                                            APIs.getOERequestDetails(c.Id).then(r => {
                                                openLoader();
                                                e.preventDefault();
                                                let editOEBody = {
                                                    OERequestID: r.data.Id,
                                                    PlanYearStartDate: r.data.PlanYearStartDate,
                                                    ClientDataExpectedDate: r.data.ClientDataExpectedDate,
                                                    OEType: r.data.OEType,
                                                    OEChanges: r.data.OEChanges,
                                                    ISolvedDataChanges: r.data.ISolvedDataChanges,
                                                    UpdatedGroupStructure: r.data.UpdatedGroupStructure,
                                                    IsolvedContact: r.data.IsolvedContactCognitoID,
                                                    OERequestStatusID: r.data.OERequestStatusID,
                                                    OERequestPhaseID: 4,
                                                    AssignedTo: r.data.AssignedToCognitoID,
                                                    OEFileSubmissionDate: r.data.OEFileSubmissionDate,
                                                    NoAttachmentUpdate: true,
                                                    IsDraftOERequest: r.data?.IsDraftOERequest,
                                                    CanResumeProducionPYSD: r.data?.CanResumeProducionPYSD ?? 0
                                                }
                                                // Api for edit OE Request 
                                                APIs.editOERequest(editOEBody).then(r => window.location.reload())
                                            })
                                        } else {
                                            closeLoader();
                                            FailureModal(dialog, {
                                                title: 'Failure',
                                                body: r.msg,
                                            })
                                        }
                                    })
                                }}>
                                {/* <ForwardToInboxIcon style={oeRequest?.SendOEFileDone === 1 ? { color: "var(--green)", fontSize: "30px" } : { cursor: "pointer", fontSize: "30px" }} /> */}
                                {c?.SendOEFileDone === 1 ?
                                    <svg width="19" height="13" viewBox="0 0 19 13" >
                                        <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                                    </svg> :
                                    c?.OERequestStatusID === 3 ||
                                        c?.RequestTypeName === "One Time" ||
                                        c?.OERequestStatusID === 4 ||
                                        Moment(todayDate).format('YYYY-MM-DD') >= c?.PlanYearStartDate ||
                                        (new Date(c?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                        </svg> :
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                        </svg>
                                }
                            </button>
                        </span>
                    </CustomTooltip>
                ) : (
                    <CustomTooltip TransitionComponent={Zoom} title={`OE Data is Ready`}>
                        {/* <ForwardToInboxIcon style={oeRequest?.SendOEFileDone === 1 ? { color: "var(--green)", fontSize: "30px" } : { fontSize: "30px" }} /> */}
                        {c?.SendOEFileDone === 1 ?
                            <svg width="19" height="13" viewBox="0 0 19 13" >
                                <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                            </svg> :
                            c?.OERequestStatusID === 3 ||
                                c?.RequestTypeName === "One Time" ||
                                c?.OERequestStatusID === 4 ||
                                Moment(todayDate).format('YYYY-MM-DD') >= c?.PlanYearStartDate ||
                                (new Date(c?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                <svg width="15" height="15" viewBox="0 0 15 15" >
                                    <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                </svg> :
                                <svg width="15" height="15" viewBox="0 0 15 15" >
                                    <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                </svg>
                        }
                    </CustomTooltip>
                )

        }
    ];


    React.useEffect(() => {

        if (oERequestsList?.length > 0 && groupsAndOEContactsList?.length > 0 && editIsolvedContact == true) {
            let newIsolvedContacts = {}

            oERequestsList.forEach(oeRequest => {
                let filterediSolvedContact = groupsAndOEContactsList.filter(s => s?.CognitoID === oeRequest?.IsolvedContactCognitoID)[0];

                if (oeRequest?.IsolvedContact !== "" && oeRequest?.IsolvedContact !== null) {
                    newIsolvedContacts = { ...newIsolvedContacts, [oeRequest?.Id]: filterediSolvedContact };
                }

            });
            setIsolvedContacts(newIsolvedContacts);
        }

    }, [editIsolvedContact === true, oERequestsList, groupsAndOEContactsList])

    const pushToColumnsBasedOnUserAction = () => {
        // let checkif43or20 = (userActions?.includes("43") || userActions?.includes("20"))

        {/* client (6) or super admin (7) */ }
        let CheckIfClientOrSuperAdmin = (userRoles?.includes("6") || userRoles?.includes("7"))

        let itemsToInsert = [
            {
                index: 0,
                value: {
                    name: oERequestsList?.length > 0 && <Checkbox
                        id={`oes-select-all`}
                        value={oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                            s?.OERequestStatusID !== 3 &&
                            s?.RequestTypeName !== "One Time" &&
                            s?.OERequestStatusID !== 4 &&
                            Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                            (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checked={oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                            s?.OERequestStatusID !== 3 &&
                            s?.RequestTypeName !== "One Time" &&
                            s?.OERequestStatusID !== 4 &&
                            Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                            (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45).length > 0 && selectedOEs.length === oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                                s?.OERequestStatusID !== 3 &&
                                s?.OERequestStatusID !== 4 &&
                                Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                                (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45).length
                        }
                        onChange={handleSelectAllOEsChange(oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                            s?.OERequestStatusID !== 3 &&
                            s?.RequestTypeName !== "One Time" &&
                            s?.OERequestStatusID !== 4 &&
                            Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                            (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45))}
                        onClick={handleSelectAllOEsClick(oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                            s?.OERequestStatusID !== 3 &&
                            s?.RequestTypeName !== "One Time" &&
                            s?.OERequestStatusID !== 4 &&
                            Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                            (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45))}
                        checkedIcon={<DoneOutlineIcon />}
                        color="default"
                        disabled={!(userActions?.includes("57") ||
                            userActions?.includes("20"))}
                    />,
                    doesNotHaveToolTip: true,
                    width: '60px',
                    sortable: false,
                    selector: oeRequest =>
                        <div>
                            <CustomTooltip TransitionComponent={Zoom} title={oeRequest?.SendOEFileDone === 1 ? `OE Data is Ready` : getTitleSendOEButton(oeRequest)}>
                                <Checkbox
                                    disabled={
                                        // !(userActions?.includes("57") ||
                                        // userActions?.includes("20") )||
                                        oeRequest?.SendOEFileDone === 1 ||
                                        oeRequest?.RequestTypeName === "One Time" ||
                                        oeRequest?.OERequestStatusID === 3 ||
                                        caches?.OERequestStatusID === 4 ||
                                        Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate ||
                                        (new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                                    }
                                    checkedIcon={<DoneOutlineIcon />}

                                    value={oeRequest}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checked={selectedOEs && selectedOEs.findIndex(item => item?.Id == oeRequest?.Id) >= 0}
                                    // onChange={handleOEsSelectionChange(oeRequest)}
                                    onClick={handleOEsSelectionClick(oeRequest)}
                                    color="default"
                                />
                            </CustomTooltip></div>

                },
                condition: isIsovedSuperAdminOrSuperAdmin()
            },
            {
                index: isIsovedSuperAdminOrSuperAdmin() ? 6 : 5,
                value: {
                    name: 'isolved Contact',
                    sortable: true,
                    width: '150px',
                    sortingkey: 'ISolvedContact',
                    id: 'ISolvedContact',
                    selector: (c, index) =>
                        <div className='d-flex py-1 align-items-center w-100'>
                            {c?.editIsolvedContact && (userActions?.includes("47") || userActions?.includes("20")) ? (
                                <>
                                    <Autocomplete
                                        options={groupsAndOEContactsList?.sort((s1, s2) => {
                                            return s2.IsEnabled - s1.IsEnabled
                                        })}
                                        autoHighlight
                                        groupBy={(option) => option.IsEnabled}
                                        getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                        onChange={(e, newValue) => {
                                            setIsolvedContacts({ ...isolvedContacts, [c?.Id]: newValue })
                                        }}
                                        value={isolvedContacts[c?.Id]}
                                        getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                        renderGroup={(params) => (
                                            <li key={params.key}>
                                                <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                                <GroupItems>{params.children}</GroupItems>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        className='flex-grow-1'
                                    />
                                    <Button
                                        style={{ width: 33, height: 33, minWidth: 'auto' }}
                                        className={`${c?.IsolvedContact !== null ? 'ml-1' : ''} p-1 d-flex align-items-center justify-content-center`}
                                        onClick={() => editIsolvedContactFn(c)} color="inherit">
                                        <svg width="15" height="21" viewBox="0 0 22 21" >
                                            <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                        </svg>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {c?.IsolvedContact !== null ? c?.IsolvedContact : ""}
                                    {(userActions?.includes("47") || userActions?.includes("20")) && (
                                        <Button
                                            style={{ width: 33, height: 33, minWidth: 'auto' }}
                                            className={`${c?.IsolvedContact !== null ? 'ml-auto' : ''} p-0`}
                                            onClick={() => {
                                                oERequestsList[index] = { ...oERequestsList[index], editIsolvedContact: true }
                                                setOERequestsList([...oERequestsList])
                                                setEditIsolvedContact(true)
                                            }} color="inherit">
                                            <svg width="20" height="20" viewBox="0 0 20 20">
                                                <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                            </svg>

                                        </Button>
                                    )}
                                </>
                            )}
                        </div>

                },
                condition: !userRoles?.includes("6")
            },
            {
                index: isIsovedSuperAdminOrSuperAdmin() ? 7 : 6,
                value: {
                    name: 'Expected Changes',
                    selector: c => <Truncate lines={3} ellipsis={<span>...
                        <button className="fs-13 btn-like-link" style={{ color: "#2c87f0" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleExpectedChanges(c?.Id);
                            }}>Read More</button></span>}>
                        {c?.OEChanges}
                    </Truncate>
                },
                condition: CheckIfClientOrSuperAdmin
            },

        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];


        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.splice(item.index, 0, item.value);
        });


        return finalColumnsArray;
    };


    return (
        <div id="oe-request-table" className='mt-3'>


            <GenericTable
                columns={[...pushToColumnsBasedOnUserAction()]}
                data={oERequestsList || []}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
            />

            <Pagination
                stateLimit={true}
                pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                onChange={(t, v) => {
                    if (quickFiltersClicked) {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    } else if (advancedFiltersClicked) {
                        if (t === 1) onSetPageSizeAdv(v);
                        else if (t === 2) onSetSelectedPageAdv(v);
                    }

                }}
                count={totalCount}
            />

        </div>
    )
}

export default HomeOERequestsTable
