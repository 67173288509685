import React from 'react';
import AnalystConnectivityRequestOEListTable from './AnalystConnectivityRequestOEListTable';
import TabsRow from './TabsRow';
import * as APIs from "../../../../Data/APIs";
import { FormControl, Divider, Paper, Button, Select, MenuItem, TextField } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { useLoader } from "../../../Layout/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useParams } from "react-router-dom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Chip from '@mui/material/Chip';
import OERequestFilterModalBody from './OERequestFilterModalBody';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useAuthentication } from "../../../../Data/Authentication";
import TruncatedChip from "../../../Layout/TruncatedChip";
import { styled } from '@mui/system';
import refreshSVG from "../../../../Icons/refresh.svg";
import cancelIconSVG from "../../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../../Icons/advancedFilter.svg";
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";



const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const AnalystConnectivityRequestOEList = () => {

    // style

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [oERequestsList, setOERequestsList] = React.useState([]);
    const [refreshClicked, setRefreshClicked] = React.useState(false);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    // normal filter 
    const { connectivityRequestIDURL } = useParams();
    const [oERequestIDFilter, setOERequestIDFilter] = React.useState("");
    const [selectedPlanYearStartFromDateFilter, setSelectedPlanYearStartFromDateFilter] = React.useState(null);
    const [selectedPlanYearStartToDateFilter, setSelectedPlanYearStartToDateFilter] = React.useState(null);
    const [selectedExpectedFromDateFilter, setSelectedExpectedFromDateFilter] = React.useState(null);
    const [selectedExpectedToDateFilter, setSelectedExpectedToDateFilter] = React.useState(null);
    const [selectedCreatedFromDateFilter, setSelectedCreatedFromDateFilter] = React.useState(null);
    const [selectedCreatedToDateFilter, setSelectedCreatedToDateFilter] = React.useState(null);
    const [statusFilter, setStatusFilter] = React.useState();
    const [phaseFilter, setPhaseFilter] = React.useState();
    const [oeTypeFilter, setOETypeFilter] = React.useState();
    const [assignedTo, setAssignedTo] = React.useState("");
    const [assignedToAutoValue, setAssignedToAutoValue] = React.useState("");
    const [iSolvedContact, setISolvedContact] = React.useState("");
    const [iSolvedContactAutoValue, setISolvedContactAutoValue] = React.useState("");
    const [updatedGS, setUpdatedGS] = React.useState("");
    const [selectedOEFileSubmissionFromDateFilter, setSelectedOEFileSubmissionFromDateFilter] = React.useState(null);
    const [selectedOEFileSubmissionToDateFilter, setSelectedOEFileSubmissionToDateFilter] = React.useState(null);
    const [requestType, setRequestType] = React.useState("");

    // advanced filter 
    const [oERequestIDAdvFilter, setOERequestIDAdvFilter] = React.useState("");
    const [selectedPlanYearStartFromDateAdvFilter, setSelectedPlanYearStartFromDateAdvFilter] = React.useState(null);
    const [selectedPlanYearStartToDateAdvFilter, setSelectedPlanYearStartToDateAdvFilter] = React.useState(null);
    const [selectedExpectedFromDateAdvFilter, setSelectedExpectedFromDateAdvFilter] = React.useState(null);
    const [selectedExpectedToDateAdvFilter, setSelectedExpectedToDateAdvFilter] = React.useState(null);
    const [selectedCreatedFromDateAdvFilter, setSelectedCreatedFromDateAdvFilter] = React.useState(null);
    const [selectedCreatedToDateAdvFilter, setSelectedCreatedToDateAdvFilter] = React.useState(null);
    const [statusAdvFilter, setStatusAdvFilter] = React.useState();
    const [phaseAdvFilter, setPhaseAdvFilter] = React.useState();
    const [oeTypeAdvFilter, setOETypeAdvFilter] = React.useState();
    const [assignedToAdvFilter, setAssignedToAdvFilter] = React.useState("");
    const [iSolvedContactAdvFilter, setISolvedContactAdvFilter] = React.useState("");
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [selectedAssignedToFilter, setSelectedAssignedToFilter] = React.useState([]);
    const [selectedStatusesFilter, setSelectedStatusesFilter] = React.useState([]);
    const [selectedPhasesFilter, setSelectedPhasesFilter] = React.useState([]);
    const [selectedOETypesFilter, setSelectedOETypesFilter] = React.useState([]);
    const [updatedGSAdvFilter, setUpdatedGSAdvFilter] = React.useState("");
    const [selectedISolvedContactFilter, setSelectedISolvedContactFilter] = React.useState([]);
    const [selectedOEFileSubmissionFromDateAdvFilter, setSelectedOEFileSubmissionFromDateAdvFilter] = React.useState(null);
    const [selectedOEFileSubmissionToDateAdvFilter, setSelectedOEFileSubmissionToDateAdvFilter] = React.useState(null);
    const [requestTypeAdvFilter, setRequestTypeAdvFilter] = React.useState([]);

    //  page size and selected and count 
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);



    // gated Data 
    const [statusesList, setStatusesList] = React.useState([]);
    const [phasesList, setPhasesList] = React.useState([]);
    const [contactsList, setContactsList] = React.useState([]);
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const [requestTypes, setRequestTypes] = React.useState([]);
    // get Data 
    React.useEffect(() => {
        APIs.getOERequestStatusesList().then(r => !(r?.data?.length) ? setStatusesList([]) : setStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getOERequestTypes().then((r) =>
            !r?.data?.length ? setRequestTypes([]) : setRequestTypes(r?.data)
        );
    }, []);

    React.useEffect(() => {
        APIs.getOERequestPhasesList().then(r => !(r?.data?.length) ? setPhasesList([]) : setPhasesList(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
            ConnectivityRequestID: connectivityRequestIDURL
        }
        APIs.getContactsList(contactsBody).then((r) => {
            setContactsList(r?.data ?? []);
        })
    }, [])

    React.useEffect(() => {
        setAssignedToAutoValue(`${assignedTo?.FirstName ?? ""} ${assignedTo?.LastName ?? ""}`);
    }, [assignedTo])

    React.useEffect(() => {
        setISolvedContactAutoValue(`${iSolvedContact?.FirstName ?? ""} ${iSolvedContact?.LastName ?? ""}`);
    }, [iSolvedContact])

    // handel get oe data
    const getOERequestsQuickData = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPage >= 1) {
            requestParameters.page = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.page = selectedPage;
        }
        if (statusFilter !== "" || statusFilter !== undefined)
            requestParameters.StatusIDsArray = (statusFilter === undefined || !statusFilter) ? "" : [statusFilter];
        requestParameters.ConnectivityRequestID = connectivityRequestIDURL;
        if (phaseFilter !== "" || phaseFilter !== undefined)
            requestParameters.PhaseIDsArray = (phaseFilter === undefined || !phaseFilter) ? "" : [phaseFilter];
        if (oERequestIDFilter !== "")
            requestParameters.OERequestID = parseInt(oERequestIDFilter);
        if (assignedTo !== "")
            requestParameters.AssignedToCognitoIDsArray = assignedTo === undefined ? "" : [assignedTo?.CognitoID];
        if (iSolvedContact !== "")
            requestParameters.IsolvedContactCognitoIDsArray = iSolvedContact === undefined ? "" : [iSolvedContact?.CognitoID];
        if (selectedPlanYearStartFromDateFilter !== null)
            requestParameters.PlanYearStartDateFrom = selectedPlanYearStartFromDateFilter ? Moment(selectedPlanYearStartFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedPlanYearStartToDateFilter !== null)
            requestParameters.PlanYearStartDateTo = selectedPlanYearStartToDateFilter ? Moment(selectedPlanYearStartToDateFilter).format('YYYY-MM-DD') : null;
        if (selectedExpectedFromDateFilter !== null)
            requestParameters.ClientDataExpectedDateFrom = selectedExpectedFromDateFilter ? Moment(selectedExpectedFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedExpectedToDateFilter !== null)
            requestParameters.ClientDataExpectedDateTo = selectedExpectedToDateFilter ? Moment(selectedExpectedToDateFilter).format('YYYY-MM-DD') : null;
        if (selectedCreatedFromDateFilter !== null)
            requestParameters.CreatedDateFrom = selectedCreatedFromDateFilter ? Moment(selectedCreatedFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedCreatedToDateFilter !== null)
            requestParameters.CreatedDateTo = selectedCreatedToDateFilter ? Moment(selectedCreatedToDateFilter).format('YYYY-MM-DD') : null;
        if (oeTypeFilter !== "")
            requestParameters.OETypesArray = (oeTypeFilter === undefined || !oeTypeFilter) ? "" : [oeTypeFilter];
        if (requestType)
            requestParameters.RequestTypesIDsArray = [parseInt(requestType)];
        if (updatedGS !== "")
            requestParameters.UpdatedGroupStructure = parseInt(updatedGS);
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;
        if (selectedOEFileSubmissionFromDateFilter !== null)
            requestParameters.OEFileSubmissionDateFrom = selectedOEFileSubmissionFromDateFilter ? Moment(selectedOEFileSubmissionFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedOEFileSubmissionToDateFilter !== null)
            requestParameters.OEFileSubmissionDateTo = selectedOEFileSubmissionToDateFilter ? Moment(selectedOEFileSubmissionToDateFilter).format('YYYY-MM-DD') : null;

        APIs.getOERequestsList(requestParameters).then((r) => (
            setOERequestsList(r?.data),
            closeLoader(),
            setRefreshClicked(false),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }


    // handel get oe data Advanced 
    const getOERequestsAdvancedData = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPageAdv >= 1) {
            requestParameters.page = selectedPageAdv;
            requestParameters.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            requestParameters.limit = pageSizeAdv;
            requestParameters.page = selectedPageAdv;
        }
        if (selectedStatusesFilter.length > 0)
            requestParameters.StatusIDsArray = selectedStatusesFilter?.map(s => s?.Id);
        requestParameters.ConnectivityRequestID = connectivityRequestIDURL;
        if (selectedPhasesFilter.length > 0)
            requestParameters.PhaseIDsArray = selectedPhasesFilter?.map(s => s?.Id);
        if (oERequestIDAdvFilter !== "")
            requestParameters.OERequestID = parseInt(oERequestIDAdvFilter);
        if (selectedAssignedToFilter.length > 0)
            requestParameters.AssignedToCognitoIDsArray = selectedAssignedToFilter?.map(s => s?.CognitoID);
        if (selectedISolvedContactFilter.length > 0)
            requestParameters.IsolvedContactCognitoIDsArray = selectedISolvedContactFilter?.map(s => s?.CognitoID);
        if (selectedPlanYearStartFromDateAdvFilter !== null)
            requestParameters.PlanYearStartDateFrom = selectedPlanYearStartFromDateAdvFilter ? Moment(selectedPlanYearStartFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedPlanYearStartToDateAdvFilter !== null)
            requestParameters.PlanYearStartDateTo = selectedPlanYearStartToDateAdvFilter ? Moment(selectedPlanYearStartToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedExpectedFromDateAdvFilter !== null)
            requestParameters.ClientDataExpectedDateFrom = selectedExpectedFromDateAdvFilter ? Moment(selectedExpectedFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedExpectedToDateAdvFilter !== null)
            requestParameters.ClientDataExpectedDateTo = selectedExpectedToDateAdvFilter ? Moment(selectedExpectedToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedCreatedFromDateAdvFilter !== null)
            requestParameters.CreatedDateFrom = selectedCreatedFromDateAdvFilter ? Moment(selectedCreatedFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedCreatedToDateAdvFilter !== null)
            requestParameters.CreatedDateTo = selectedCreatedToDateAdvFilter ? Moment(selectedCreatedToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedOETypesFilter.length > 0)
            requestParameters.OETypesArray = selectedOETypesFilter;
        if (requestTypeAdvFilter.length > 0)
            requestParameters.RequestTypesIDsArray = [requestTypeAdvFilter] ?? [];
        if (updatedGSAdvFilter !== "")
            requestParameters.UpdatedGroupStructure = parseInt(updatedGSAdvFilter);
        if (sortFieldAdvFilter !== "")
            requestParameters.sortField = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            requestParameters.sortOrder = sortOrderAdvFilter;
        if (selectedOEFileSubmissionFromDateAdvFilter !== null)
            requestParameters.OEFileSubmissionDateFrom = selectedOEFileSubmissionFromDateAdvFilter ? Moment(selectedOEFileSubmissionFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedOEFileSubmissionToDateAdvFilter !== null)
            requestParameters.OEFileSubmissionDateTo = selectedOEFileSubmissionToDateAdvFilter ? Moment(selectedOEFileSubmissionToDateAdvFilter).format('YYYY-MM-DD') : null;

        APIs.getOERequestsList(requestParameters).then((r) => (
            setOERequestsList(r?.data),
            setAdvancedFiltersAppliedClicked(false),
            closeLoader(),
            setRefreshClicked(false),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }
    React.useEffect(() => {
        if (quickFiltersClicked) getOERequestsQuickData();
    }, [selectedPage, pageSize, statusFilter, phaseFilter, requestType, oERequestIDFilter, assignedTo, iSolvedContact, selectedPlanYearStartFromDateFilter, selectedPlanYearStartToDateFilter, selectedExpectedFromDateFilter, selectedExpectedToDateFilter, selectedCreatedFromDateFilter, selectedCreatedToDateFilter, oeTypeFilter, updatedGS, sortFieldFilter, sortOrderFilter, selectedAssignedToFilter, selectedOEFileSubmissionFromDateFilter, selectedOEFileSubmissionToDateFilter, refreshClicked])

    React.useEffect(() => {
        getOERequestsQuickData();
    }, [quickFiltersClicked === true])
    React.useEffect(() => {
        getOERequestsAdvancedData();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getOERequestsAdvancedData();
    }, [advancedFiltersClicked === true])
    //    handle functions 
    const handleOERequestIDFilter = (e) => {
        setOERequestIDFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handlePlanYearStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedPlanYearStartFromDateFilter(date);
        }
        else {
            setSelectedPlanYearStartToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };



    const handleOEFileSubmissionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedOEFileSubmissionFromDateFilter(date);
        }
        else {
            setSelectedOEFileSubmissionToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleExpectedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedExpectedFromDateFilter(date);
        }
        else {
            setSelectedExpectedToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };
    const handleCreatedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCreatedFromDateFilter(date);
        }
        else {
            setSelectedCreatedToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleStatusFilter = (e) => {
        setStatusFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handlePhaseFilter = (e) => {
        setPhaseFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }
    const handleOETypeFilter = (e) => {
        setOETypeFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleUpdatedGSFilter = (e, newValue) => {
        setUpdatedGS(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleRequestTypeFilter = (e, newValue) => {
        setRequestType(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }


    const handleClearSearch = () => {
        setOERequestIDFilter("");
        setUpdatedGS("");
        setPhaseFilter(undefined);
        setStatusFilter(undefined);
        setRequestTypeAdvFilter("");
        setRequestType("");
        setOETypeFilter("");
        setAssignedTo("");
        setISolvedContact("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedCreatedFromDateFilter(null);
        setSelectedCreatedToDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedPage(1);
    }
    const handleClearSearchReset = () => {
        setOERequestIDFilter("");
        setUpdatedGS("");
        setPhaseFilter(undefined);
        setStatusFilter(undefined);
        setRequestTypeAdvFilter("");
        setRequestType("");
        setOETypeFilter("");
        setAssignedTo("");
        setISolvedContact("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedCreatedFromDateFilter(null);
        setSelectedCreatedToDateFilter(null);
        setOERequestIDAdvFilter("");
        setUpdatedGSAdvFilter("");
        setPhaseAdvFilter(undefined);
        setStatusAdvFilter(undefined);
        setOETypeAdvFilter("");
        setAssignedToAdvFilter("");
        setSelectedPlanYearStartFromDateAdvFilter(null);
        setSelectedPlanYearStartToDateAdvFilter(null);
        setSelectedExpectedFromDateAdvFilter(null);
        setSelectedOEFileSubmissionFromDateAdvFilter(null);
        setSelectedOEFileSubmissionToDateAdvFilter(null);
        setSelectedExpectedToDateAdvFilter(null);
        setSelectedCreatedFromDateAdvFilter(null);
        setSelectedCreatedToDateAdvFilter(null);
        setSelectedPage(1);
        setSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setSelectedStatusesFilter([]);
        setSelectedPhasesFilter([]);
        setSelectedAssignedToFilter([]);
        setSelectedOETypesFilter([]);
        setAdvancedFiltersClicked(false);
        setISolvedContactAdvFilter("");
        setSelectedISolvedContactFilter([]);
    }
    const handleClickOpenDialog = () => {
        setOERequestIDFilter("");
        setUpdatedGS("");
        setPhaseFilter(undefined);
        setStatusFilter(undefined);
        setRequestTypeAdvFilter("");
        setRequestType("");
        setOETypeFilter("");
        setAssignedTo("");
        setISolvedContact("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedCreatedFromDateFilter(null);
        setSelectedCreatedToDateFilter(null);
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    return (
        <div>
            <TabsRow />
            <Paper className="content-card-paper search-container">
                <div className="row alignvh-center">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <Button
                        className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                        onClick={handleClickOpenDialog}
                        style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1" >
                            <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                        </svg>
                        Advanced Filters
                    </Button>
                    <React.Fragment>
                        {oERequestIDFilter && <TruncatedChip body={`OE Request ID: ${oERequestIDFilter}`} />}
                        {oERequestIDAdvFilter && <TruncatedChip body={`OE Request ID: ${oERequestIDAdvFilter}`} />}

                        {statusFilter && <TruncatedChip body={`Status: ${statusesList?.filter(s => s.Id == statusFilter)[0].Name}`} />}
                        {statusAdvFilter && <TruncatedChip body={`Status: ${statusesList?.filter(s => s.Id == statusAdvFilter)[0].Name}`} />}
                        {selectedStatusesFilter && selectedStatusesFilter?.length > 0 && <TruncatedChip body={`Status: ${selectedStatusesFilter?.map(s => s.Name).join(" - ")}`} />}

                        {phaseFilter && <TruncatedChip body={`Phase: ${phasesList?.filter(s => s.Id == phaseFilter)[0].Name}`} />}
                        {phaseAdvFilter && <TruncatedChip body={`Phase: ${phasesList?.filter(s => s.Id == phaseAdvFilter)[0].Name}`} />}
                        {selectedPhasesFilter && selectedPhasesFilter?.length > 0 && <TruncatedChip body={`Phase: ${selectedPhasesFilter?.map(s => s.Name).join(" - ")}`} />}

                        {updatedGS && updatedGS !== "" && <TruncatedChip body={`Updated Group Structure: ${updatedGS === "1" ? "Yes" : updatedGS === "0" ? "No" : ""}`} />}
                        {updatedGSAdvFilter && updatedGSAdvFilter !== "" && <TruncatedChip body={`Updated Group Structure: ${updatedGSAdvFilter === "1" ? "Yes" : updatedGSAdvFilter === "0" ? "No" : ""}`} />}


                        {requestType && requestType !== "" && <TruncatedChip body={`Request Type: ${requestTypes?.filter(s => s.RequestTypeId == requestType)[0]?.RequestTypeName}`} />}
                        {requestTypeAdvFilter && requestTypeAdvFilter !== "" && <TruncatedChip body={`Request Type: ${requestTypes?.filter(s => s.RequestTypeId == requestTypeAdvFilter)[0]?.RequestTypeName}`} />}


                        {oeTypeFilter && <TruncatedChip body={`OE Type: ${oeTypeFilter}`} />}
                        {oeTypeAdvFilter && <TruncatedChip body={`OE Type: ${oeTypeAdvFilter}`} />}
                        {selectedOETypesFilter && selectedOETypesFilter?.length > 0 && <TruncatedChip body={`OE Type: ${selectedOETypesFilter?.map(s => s).join(" - ")}`} />}


                        {assignedTo && <TruncatedChip body={`Assigned To: ${assignedTo.FirstName} ${assignedTo.LastName}`} />}
                        {assignedToAdvFilter && <TruncatedChip body={`Assigned To: ${assignedToAdvFilter.FirstName} ${assignedToAdvFilter.LastName}`} />}
                        {selectedAssignedToFilter && selectedAssignedToFilter?.length > 0 && <TruncatedChip body={`Assigned To: ${selectedAssignedToFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}

                        {iSolvedContact && <TruncatedChip body={`isolved Contact: ${iSolvedContact.FirstName} ${iSolvedContact.LastName}`} />}
                        {iSolvedContactAdvFilter && <TruncatedChip body={`isolved Contact: ${iSolvedContactAdvFilter.FirstName} ${iSolvedContactAdvFilter.LastName}`} />}
                        {selectedISolvedContactFilter && selectedISolvedContactFilter?.length > 0 && <TruncatedChip body={`isolved Contact: ${selectedISolvedContactFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}

                        {quickFiltersClicked && selectedPlanYearStartFromDateFilter && selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedPlanYearStartToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedPlanYearStartFromDateFilter && !selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateFilter).format("MM/DD/YYYY")} `} />}
                        {quickFiltersClicked && !selectedPlanYearStartFromDateFilter && selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Years Start Date: To: ${Moment(selectedPlanYearStartToDateFilter).format("MM/DD/YYYY")}`} />}

                        {advancedFiltersClicked && selectedPlanYearStartFromDateAdvFilter && selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedPlanYearStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedPlanYearStartFromDateAdvFilter && !selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                        {advancedFiltersClicked && !selectedPlanYearStartFromDateAdvFilter && selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Years Start Date: To: ${Moment(selectedPlanYearStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                        {quickFiltersClicked && selectedExpectedFromDateFilter && selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedExpectedToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedExpectedFromDateFilter && !selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateFilter).format("MM/DD/YYYY")} `} />}
                        {quickFiltersClicked && !selectedExpectedFromDateFilter && selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: To: ${Moment(selectedExpectedToDateFilter).format("MM/DD/YYYY")}`} />}

                        {advancedFiltersClicked && selectedExpectedFromDateAdvFilter && selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedExpectedToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedExpectedFromDateAdvFilter && !selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                        {advancedFiltersClicked && !selectedExpectedFromDateAdvFilter && selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: To: ${Moment(selectedExpectedToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                        {quickFiltersClicked && selectedCreatedFromDateFilter && selectedCreatedToDateFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedCreatedToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedCreatedFromDateFilter && !selectedCreatedToDateFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateFilter).format("MM/DD/YYYY")} `} />}
                        {quickFiltersClicked && !selectedCreatedFromDateFilter && selectedCreatedToDateFilter && <TruncatedChip body={`Created Date: To: ${Moment(selectedCreatedToDateFilter).format("MM/DD/YYYY")}`} />}

                        {advancedFiltersClicked && selectedCreatedFromDateAdvFilter && selectedCreatedToDateAdvFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedCreatedToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedCreatedFromDateAdvFilter && !selectedCreatedToDateAdvFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                        {advancedFiltersClicked && !selectedCreatedFromDateAdvFilter && selectedCreatedToDateAdvFilter && <TruncatedChip body={`Created Date: To: ${Moment(selectedCreatedToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                        {quickFiltersClicked && selectedOEFileSubmissionFromDateFilter && selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedOEFileSubmissionToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedOEFileSubmissionFromDateFilter && !selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateFilter).format("MM/DD/YYYY")} `} />}
                        {quickFiltersClicked && !selectedOEFileSubmissionFromDateFilter && selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: To: ${Moment(selectedOEFileSubmissionToDateFilter).format("MM/DD/YYYY")}`} />}

                        {advancedFiltersClicked && selectedOEFileSubmissionFromDateAdvFilter && selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedOEFileSubmissionToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedOEFileSubmissionFromDateAdvFilter && !selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                        {advancedFiltersClicked && !selectedOEFileSubmissionFromDateAdvFilter && selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: To: ${Moment(selectedOEFileSubmissionToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                    </React.Fragment>
                </div>
                {quickFiltersClicked && (
                    <div className="row">
                        <div className="col-xl-3 mt-3">
                            <span>OE Request  ID:</span>
                            <br />
                            <input type="text" className="search-input w-100" value={oERequestIDFilter} onChange={handleOERequestIDFilter} />
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Updated Group Structure:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={updatedGS ?? ""}
                                    onChange={handleUpdatedGSFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </NativeSelect>
                            </FormControl>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Status:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={statusFilter}
                                    onChange={handleStatusFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    {statusesList?.map((p, pi) => (
                                        <option key={`statuses-list-${pi}`} value={p.Id}>{p.Name}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Phase:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={phaseFilter}
                                    onChange={handlePhaseFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    {phasesList?.map((p, pi) => (
                                        <option key={`phases-list-${pi}`} value={p.Id}>{p.Name}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Assigned To:</span>
                            <br />
                            <Autocomplete
                                options={contactsList?.sort((s1, s2) => {
                                    return s2.IsEnabled - s1.IsEnabled;
                                })}
                                groupBy={(option) => option.IsEnabled}
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                autoHighlight
                                className="search-dropdown w-100"
                                inputValue={assignedToAutoValue ?? ""}
                                getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                onChange={(e, newValue) => {
                                    setAssignedTo(newValue ?? "");
                                    // onAssignedToFilter(newValue ?? "");
                                    setSelectedPage(1);
                                    setPageSize(30);
                                }}
                                getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {`${option.FirstName} ${option.LastName}`}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={e => setAssignedToAutoValue(e.target.value)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>isolved Contact:</span>
                            <br />
                            <Autocomplete
                                options={groupsAndOEContactsList?.sort((s1, s2) => {
                                    return s2.IsEnabled - s1.IsEnabled;
                                })}
                                groupBy={(option) => option.IsEnabled}
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                autoHighlight
                                className="search-dropdown w-100"
                                inputValue={iSolvedContactAutoValue ?? ""}
                                getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                onChange={(e, newValue) => {
                                    setISolvedContact(newValue ?? "");
                                    setSelectedPage(1);
                                    setPageSize(30);
                                }}
                                getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {`${option.FirstName} ${option.LastName}`}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={e => setISolvedContactAutoValue(e.target.value)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Created Date:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedCreatedFromDateFilter}
                                        onChange={handleCreatedDateChange("fromDate")}
                                        placeholder='From Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedCreatedToDateFilter}
                                        onChange={handleCreatedDateChange("toDate")}
                                        placeholder='To Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Plan Year Start Date:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedPlanYearStartFromDateFilter}
                                        onChange={handlePlanYearStartDateChange("fromDate")}
                                        placeholder='From Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedPlanYearStartToDateFilter}
                                        onChange={handlePlanYearStartDateChange("toDate")}
                                        placeholder='To Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Client's Expected Data Ready Date:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedExpectedFromDateFilter}
                                        onChange={handleExpectedDateChange("fromDate")}
                                        placeholder='From Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedExpectedToDateFilter}
                                        onChange={handleExpectedDateChange("toDate")}
                                        placeholder='To Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>OE File Transmission Date:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedOEFileSubmissionFromDateFilter}
                                        onChange={handleOEFileSubmissionDateChange("fromDate")}
                                        placeholder='From Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedOEFileSubmissionToDateFilter}
                                        onChange={handleOEFileSubmissionDateChange("toDate")}
                                        placeholder='To Date'
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Request Type:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={requestType ?? ""}
                                    onChange={handleRequestTypeFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    {requestTypes?.map((p, pi) => (
                                        <option key={`oe-request-types-list-${pi}`} value={p.RequestTypeId}>{p.RequestTypeName}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </div>
                )}
                <div className="col-xl-12 mt-4" >
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>

                <AnalystConnectivityRequestOEListTable
                    advancedFiltersClicked={advancedFiltersClicked}
                    quickFiltersClicked={quickFiltersClicked}
                    oERequestsList={oERequestsList}
                    setOERequestsList={setOERequestsList}
                    selectedPage={selectedPage}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageSize={setPageSize}
                    setSelectedPage={setSelectedPage}
                    onSortOrderFilter={(s) => setSortOrderFilter(s)}
                    onSortFieldFilter={(s) => setSortFieldFilter(s)}
                    selectedPageAdv={selectedPageAdv}
                    setSelectedPageAdv={setPageSizeAdv}
                    pageSizeAdv={pageSizeAdv}
                    setPageSizeAdv={setPageSizeAdv}
                    onSortFieldAdvFilter={setSortFieldAdvFilter}
                    onSortOrderAdvFilter={setSortOrderAdvFilter}
                />
            </Paper>

            <OERequestFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setPageSizeAdv={setPageSizeAdv}
                setSelectedPageAdv={setSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                setOERequestIDFilterAdvFilter={setOERequestIDAdvFilter}
                setUpdatedGSAdvFilter={setUpdatedGSAdvFilter}
                setSelectedPlanYearStartFromDateFilterAdvFilter={setSelectedPlanYearStartFromDateAdvFilter}
                setSelectedPlanYearStartToDateFilterAdvFilter={setSelectedPlanYearStartToDateAdvFilter}
                setSelectedExpectedFromDateFilterAdvFilter={setSelectedExpectedFromDateAdvFilter}
                setSelectedExpectedToDateFilterAdvFilter={setSelectedExpectedToDateAdvFilter}
                setSelectedCreatedFromDateFilterAdvFilter={setSelectedCreatedFromDateAdvFilter}
                setSelectedCreatedToDateFilterAdvFilter={setSelectedCreatedToDateAdvFilter}
                setStatusFilterAdvFilter={setStatusAdvFilter}
                setPhaseFilterAdvFilter={setPhaseAdvFilter}
                setOETypeFilterAdvFilter={setOETypeAdvFilter}
                setAssignedToAdvFilter={setAssignedToAdvFilter}
                onSelectedAssignedToFilter={setSelectedAssignedToFilter}
                setSelectedStatusesFilter={setSelectedStatusesFilter}
                setSelectedPhasesFilter={setSelectedPhasesFilter}
                setSelectedOETypesFilter={setSelectedOETypesFilter}
                onCloseClearReset={handleClearSearchReset}
                setISolvedContactAdvFilter={setISolvedContactAdvFilter}
                onSelectedISolvedContactFilter={setSelectedISolvedContactFilter}
                selectedISolvedContactFilter={selectedISolvedContactFilter}
                setSelectedOEFileSubmissionFromDateFilterAdvFilter={setSelectedOEFileSubmissionFromDateAdvFilter}
                setSelectedOEFileSubmissionToDateFilterAdvFilter={setSelectedOEFileSubmissionToDateAdvFilter}
                requestTypeAdvFilter={requestTypeAdvFilter}
                setRequestTypeAdvFilter={setRequestTypeAdvFilter}
                requestTypes={requestTypes}
                setRequestTypes={setRequestTypes}
            />
        </div>
    )
}

export default AnalystConnectivityRequestOEList
