import React, { useState } from "react";
import { AccordionDetails, Divider, Paper } from "@material-ui/core";
import TabsTitles from "../../Layout/TabsTitles";
import Button from '@material-ui/core/Button';
import CancelRequestSVG from "../../../Images/cancelRequest.svg";
import EditRequestSVG from "../../../Images/editRequest.svg";
import dateTimeSVG from "../../../Images/dateTime.svg";
import ConnectivityRequests from "../ViewEditGroupRequest/ConnectivityRequests";
import BreadcrumbSteps from "../../Layout/BreadcrumbSteps";
import * as APIs from "../../../Data/APIs";
import { useParams } from "react-router-dom";
import Moment from 'moment';
import { useLoader } from "../../Layout/Loader"
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import { FailureModal } from "../../Modals/FailureModal";
import { useHistory } from "react-router-dom";
import EmptyActivityLogsSVG from "../../../Images/emptyActivityLogs.svg"
import Chip from '@material-ui/core/Chip';
import SelectSubgroups from "../CreateGroupRequest/SelectSubgroups";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Select } from "@material-ui/core";
import { useAuthentication } from "../../../Data/Authentication";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CancelGroupRequest from "./CancelGroupRequest";
import { parseISO } from 'date-fns';
import UncancelConnectivity from "./UncancelConnectivity";
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import ViewGroupReqestClientContacts from "./ViewGroupReqestClientContacts";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import plusIconSVG from "../../../Icons/plusIcon.svg";
import editRequestIcon from "../../../Icons/editIcon.svg";
import closeIconSVG from "../../../Icons/octagon-xmark.svg";
import Grid from '@mui/material/Grid';
import TruncatedChip from "../../Layout/TruncatedChip";
import plusIconWhiteSVG from "../../../Icons/PlusIconWhite.svg";

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '0 10px',
  color: "var(--grey2-secondary-color)",
  backgroundColor: "var(--grey1)",
  fontSize: "12px",
}));

function ViewGroupRequest() {

  const { authUser } = useAuthentication();
  const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
  const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
  const uncancelConnectivityModal = useModalDialog(0);
  const dialog = useModalDialog(0);
  const { groupRequestIDURL } = useParams();
  const [groupReq, setGroupReq] = useState();
  const routeHistory = useHistory();

  const [onEditRequest, setOnEditRequest] = useState(false);
  const [onEditSubgroups, setOnEditSubgroups] = useState(false);

  const [addContactClicked, setAddContactClicked] = React.useState(false);

  const [subgroups, setSubgroups] = React.useState([]);
  const [SubgroupsIDs, setSubgroupsIDs] = React.useState([]);
  const [selectedSubgroups, setSelectedSubgroups] = React.useState([]);
  const [checkedSubgroups, setCheckedSubgroups] = React.useState(false);
  const [selectedSubgroupsView, setSelectedSubgroupsView] = React.useState([]);
  const [checkedSubgroup, setCheckedSubgroup] = React.useState(false);
  const [selectAllSubgroupsChecked, setSelectAllSubgroupsChecked] = React.useState(false);

  const [subgroupSearchValue, setSubgroupSearchValue] = React.useState("");

  const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
  const [groupContact, setGroupContact] = React.useState("");
  const [OEContact, setOEContact] = React.useState("");
  const [migration, setMigration] = React.useState("");
  const [migrationStartDate, setMigrationStartDate] = React.useState(null);
  const [plannedFirstProductionDate, setPlannedFirstProductionDate] = React.useState(null);
  const [migrationPhase, setMigrationPhase] = React.useState("");
  const [migrationPhasesList, setMigrationPhasesList] = React.useState([]);

  const [groupSize, setGroupSize] = React.useState();
  const [expanded, setExpanded] = React.useState("clientContacts");
  const [contactNotes, setContactNotes] = React.useState("");

  const [groupEffDate, setGroupEffDate] = React.useState(null);

  const [requestSubmitted, setRequestSubmitted] = React.useState(false);
  const [requestSaved, setRequestSaved] = React.useState(false);
  const [partnerStatus, setPartnerStatus] = React.useState("");

  const [uncancelledConnectivities, setUncancelledConnectivities] = React.useState([]);

  const { openLoader, closeLoader } = useLoader();

  // validation 
  const [selectMigrationStarDateValidationForm, setSelectMigrationStarDateValidationForm] = React.useState("");
  const [selectPlannedFirstProductionDateValidationForm, setSelectPlannedFirstProductionDateValidationForm] = React.useState("");
  const [selectMigrationPhaseValidationForm, setSelectMigrationPhaseValidationForm] = React.useState("");
  const [selectMigrationValidationForm, setSelectMigrationValidationForm] = React.useState("");

  const cancelGroupModal = useModalDialog(0);

  React.useEffect(() => {
    if (!migrationStartDate || migrationStartDate === "Invalid date") setSelectMigrationStarDateValidationForm("Migration start date cannot be empty"); else setSelectMigrationStarDateValidationForm("");
  }, [migrationStartDate, selectMigrationStarDateValidationForm])
  React.useEffect(() => {
    if (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date") setSelectPlannedFirstProductionDateValidationForm("Planned first production date cannot be empty"); else setSelectPlannedFirstProductionDateValidationForm("");
  }, [plannedFirstProductionDate, selectPlannedFirstProductionDateValidationForm])

  React.useEffect(() => {
    if (!migrationPhase) setSelectMigrationPhaseValidationForm("Migration phase is missing, please select a phase from the list"); else setSelectMigrationPhaseValidationForm("");
  }, [migrationPhase, selectMigrationPhaseValidationForm])
  React.useEffect(() => {
    if (!migration || migration === null) setSelectMigrationValidationForm("Migration cannot be empty"); else setSelectMigrationValidationForm("");
  }, [migration, selectMigrationValidationForm, requestSubmitted])

  React.useEffect(() => {
    APIs.getPartnerStatus().then((r) => setPartnerStatus(r));
  }, [])


  function openModal(modalType, handleFunction, content) {
    switch (modalType) {
      case 0:
        return ConfirmModal(dialog, handleFunction, content);
      case 1:
        return SuccessModal(dialog, content);
      case 2:
        return FailureModal(dialog, content);
      default:
        break;
    }
  }
  const handleMigrationStartDateChange = (date) => {
    setMigrationStartDate(Moment(date).format('YYYY-MM-DD'));

  };
  const handlePlannedFirstProductionDateChange = (date) => {
    setPlannedFirstProductionDate(Moment(date).format('YYYY-MM-DD'));

  };

  // get Migration phases List  apis 
  React.useEffect(() => {
    APIs.getMigrationPhases().then(r => !(r?.data?.length) ? setMigrationPhasesList([]) : setMigrationPhasesList(r?.data));

  }, [])
  // Get group request data to the view group request page
  React.useEffect(() => {
    openLoader();
    APIs.getGroupRequestData(groupRequestIDURL).then((r) => {
      setGroupReq({
        groupRequest: r?.data?.GroupRequest,
        GroupRequestContacts: r?.data?.GroupRequestContacts,
        connectivities: r?.data?.connectivities,
      });
      setSelectedSubgroupsView(r?.data?.GroupRequest?.SubGroups);

    });
  }, []);

  // React.useEffect(() => {
  //     if (selectedSubgroupsView) closeLoader(); else openLoader();
  // }, [selectedSubgroupsView])

  React.useEffect(() => {
    if (groupReq?.groupRequest?.IsPEO === 1) closeLoader();
  }, [groupReq])

  React.useEffect(() => {
    openLoader();
    APIs.getAllGroupsAndOEContacts().then((r) => (
      !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
      closeLoader()
    ));
  }, [])


  React.useEffect(() => {
    if (groupReq?.groupRequest?.IsPEO === 1) closeLoader();
    else {
      if (onEditSubgroups) {
        openLoader();
        const singleGroupBody = {
          InstanceID: groupReq?.groupRequest?.GroupInstanceID,
          ClientID: groupReq?.groupRequest?.GroupClientID,
          ClientCode: groupReq?.groupRequest?.GroupNumber,
          editMode: true // True returns the sub groups
        }
        APIs.getSingleISolvedGroupDetails(singleGroupBody).then((r) => { setSubgroups(r?.data?.SubGroups); setSubgroupsIDs(r?.data?.SubGroups?.map(c => c?.Id)) });
      }
    }
  }, [onEditSubgroups]);

  React.useEffect(() => {
    const uncancelledConnectivitiesArray = groupReq?.connectivities?.filter(f => f.ConnectivityRequestStatusName === "Cancelled");
    setUncancelledConnectivities(uncancelledConnectivitiesArray);
  }, [JSON.stringify(groupReq?.connectivities)])

  React.useEffect(() => {
    if (groupReq?.groupRequest?.IsPEO === 1) closeLoader();
    else {
      if (subgroups)
        closeLoader();
      else openLoader();
    }
  }, [subgroups])

  const handleSubgroupChange = (value) => (e) => {
    setCheckedSubgroup(e.target.checked);
  }

  const handleSubgroupClick = (value) => (event) => {
    let selectedSubgroupArray = selectedSubgroups;
    if (!value || value === "") selectedSubgroupArray = [];
    else if (selectedSubgroupArray.indexOf(value) >= 0) {
      selectedSubgroupArray.splice(selectedSubgroupArray.indexOf(value), 1);
    }
    else selectedSubgroupArray.push(value);
    setSelectedSubgroups([...selectedSubgroupArray]);

    if (selectedSubgroups.length === subgroups.length) setSelectAllSubgroupsChecked(true); else setSelectAllSubgroupsChecked(false);
  }

  const handleSelectAllSubgroupChange = (value) => (e) => {
    setSelectAllSubgroupsChecked(e.target.checked);
  }

  const handleSelectAllSubgroupClick = (value) => (event) => {
    let selectedSubgroupArray = selectedSubgroups;
    if (!value || value === "") selectedSubgroupArray = [];
    else if (selectedSubgroupArray.indexOf(...value) >= 0) {
      selectedSubgroupArray.splice(selectedSubgroupArray.indexOf(...value));
    }
    else {
      selectedSubgroupArray.length = 0;
      selectedSubgroupArray.push(...value);
    }
    setSelectedSubgroups([...selectedSubgroupArray]);
  }

  const handleAddContactsButtonClick = (event) => {
    event.stopPropagation();
    setAddContactClicked(true);
  };

  React.useEffect(() => {
    let filteredSubgroups = subgroups?.filter(o1 => selectedSubgroupsView?.some(o2 => o1.ClientCode === o2.GroupNumber));
    setSelectedSubgroups(filteredSubgroups);
  }, [selectedSubgroupsView, subgroups, onEditSubgroups === true])

  React.useEffect(() => {
    if (subgroups?.length === selectedSubgroups?.length) setSelectAllSubgroupsChecked(true); else setSelectAllSubgroupsChecked(false);
  }, [selectedSubgroups, onEditSubgroups === true])


  const handleSubgroupSearchValueChange = (e) => {
    setSubgroupSearchValue(e.target.value);
  }

  const handleGroupSizeChange = (e) => {
    setGroupSize(e.target.value);
  }

  const handleContactNotesChange = (e) => {
    setContactNotes(e.target.value);
  }

  const handleGroupEffDateChange = (date) => {
    setGroupEffDate(Moment(date).format('YYYY-MM-DD'));
  };

  React.useEffect(() => {
    setGroupContact(groupReq?.groupRequest?.GroupContactCognitoID);
    setOEContact(groupReq?.groupRequest?.OEContactCognitoID);
    setMigration(groupReq?.groupRequest?.MigrationGroupRequest);
    setGroupSize(groupReq?.groupRequest?.GroupSize);
    setGroupEffDate(groupReq?.groupRequest?.GroupEffectiveDate);
    setMigrationStartDate((groupReq?.groupRequest?.MigrationStartDate !== "0000-00-00" && groupReq?.groupRequest?.MigrationStartDate !== null) ? groupReq?.groupRequest?.MigrationStartDate : null);
    setPlannedFirstProductionDate((groupReq?.groupRequest?.PlannedFirstProductionDate !== "0000-00-00" && groupReq?.groupRequest?.PlannedFirstProductionDate !== null) ? groupReq?.groupRequest?.PlannedFirstProductionDate : null);
    setMigrationPhase(groupReq?.groupRequest?.MigrationPhaseID);
    setContactNotes(groupReq?.groupRequest?.ContactNotes ?? "");
  }, [onEditRequest === true])


  // Edit group request
  const editGroupRequest = (e) => {
    e.preventDefault();
    if ((migration !== "No" && !migrationPhase) || (migration !== "No" && (!migrationStartDate || migrationStartDate === "Invalid date")) || (migration !== "No" && (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date"))) return;
    openLoader();
    let updateGroupRequest = {};
    if (requestSubmitted) {
      updateGroupRequest = {
        GroupID: groupReq?.groupRequest?.GroupID,
        GroupRequestStatusID: 1,
        SubGroups: selectedSubgroups,
        GroupContact: groupContact,
        OEContact: OEContact,
        MigrationGroupRequest: migration,
        GroupSize: groupSize,
        GroupEffectiveDate: groupEffDate,
        MigrationStartDate: migration !== "No" ? migrationStartDate : null,
        PlannedFirstProductionDate: migration !== "No" ? plannedFirstProductionDate : null,
        MigrationPhaseID: migration !== "No" ? migrationPhase : null,
        ContactNotes: contactNotes,
        GroupInstanceID: groupReq?.groupRequest?.GroupInstanceID

      }
    } else if (requestSaved) {
      if ((migration !== "No" && !migrationPhase) || (migration !== "No" && (!migrationStartDate || migrationStartDate === "Invalid date")) || (migration !== "No" && (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date"))) return;
      updateGroupRequest = {
        GroupID: groupReq?.groupRequest?.GroupID,
        GroupRequestStatusID: 4,
        SubGroups: selectedSubgroups,
        GroupContact: groupContact,
        OEContact: OEContact,
        MigrationGroupRequest: migration,
        GroupSize: groupSize,
        GroupEffectiveDate: groupEffDate,
        MigrationStartDate: migration !== "No" ? migrationStartDate : null,
        PlannedFirstProductionDate: migration !== "No" ? plannedFirstProductionDate : null,
        MigrationPhaseID: migration !== "No" ? migrationPhase : null,
        ContactNotes: contactNotes,
        GroupInstanceID: groupReq?.groupRequest?.GroupInstanceID

      }
    }

    APIs.editGroupRequestISolved(updateGroupRequest, groupRequestIDURL).then((r) => (
      window.location.reload()
    ));
  }

  const submitRequestFromDraft = (e) => {
    e.preventDefault();


    openLoader();
    const updateGroupRequest = {
      GroupID: groupReq?.groupRequest?.GroupID,
      GroupRequestStatusID: 1,
      SubGroups: selectedSubgroups,
      GroupContact: groupsAndOEContactsList?.filter(s => `${s.FirstName} ${s.LastName}` === groupReq?.groupRequest?.GroupContact)[0]?.CognitoID,
      OEContact: groupsAndOEContactsList?.filter(s => `${s.FirstName} ${s.LastName}` === groupReq?.groupRequest?.OEContact)[0]?.CognitoID,
      MigrationGroupRequest: groupReq?.groupRequest?.MigrationGroupRequest,
      GroupSize: groupReq?.groupRequest?.GroupSize,
      GroupEffectiveDate: groupReq?.groupRequest?.GroupEffectiveDate,
      MigrationStartDate: groupReq?.groupRequest?.MigrationStartDate,
      PlannedFirstProductionDate: groupReq?.groupRequest?.PlannedFirstProductionDate,
      MigrationPhaseID: groupReq?.groupRequest?.MigrationPhaseID,
      ContactNotes: groupReq?.groupRequest?.ContactNotes,
      GroupInstanceID: groupReq?.groupRequest?.GroupInstanceID
    }

    APIs.editGroupRequestISolved(updateGroupRequest, groupRequestIDURL).then((r) => (
      window.location.reload()
    ));
  }

  // Update Subgroups
  const updateSubgroups = (e) => {
    e.preventDefault();
    openLoader();
    const updateSubgroups = {
      GroupID: groupReq?.groupRequest?.GroupID,
      GroupRequestStatusID: groupReq?.groupRequest?.GroupRequestStatusID,
      SubGroups: selectedSubgroups,
      GroupContact: groupsAndOEContactsList?.filter(s => `${s.FirstName} ${s.LastName}` === groupReq?.groupRequest?.GroupContact)[0]?.CognitoID,
      OEContact: groupsAndOEContactsList?.filter(s => `${s.FirstName} ${s.LastName}` === groupReq?.groupRequest?.OEContact)[0]?.CognitoID,
      MigrationGroupRequest: groupReq?.groupRequest?.MigrationGroupRequest,
      GroupSize: groupReq?.groupRequest?.GroupSize,
      GroupEffectiveDate: groupReq?.groupRequest?.GroupEffectiveDate,
      MigrationStartDate: groupReq?.groupRequest?.MigrationStartDate,
      PlannedFirstProductionDate: groupReq?.groupRequest?.PlannedFirstProductionDate,
      MigrationPhaseID: groupReq?.groupRequest?.MigrationPhaseID,
      ContactNotes: groupReq?.groupRequest?.ContactNotes,
      GroupInstanceID: groupReq?.groupRequest?.GroupInstanceID
    }

    APIs.editGroupRequestISolved(updateSubgroups, groupRequestIDURL).then((r) => (
      window.location.reload()
    ));
  }

  React.useEffect(() => {
    if (groupReq) closeLoader();
    else openLoader();
  }, [groupReq])



  return (
    <div id="view-group-request">
      {groupReq && (
        <BreadcrumbSteps
          breadcrumbTitleCurrent={`${groupReq?.groupRequest?.Peo?.PeoName
            ? groupReq?.groupRequest?.Peo?.PeoName
            : groupReq?.groupRequest?.GroupName
            }`}
          isCurrent={true}
        />
      )}
      <div className="alignvh-center" style={{ justifyContent: "space-between" }}
      >
        <TabsTitles tabTitle={["View Group Request"]} />
        {((userActions?.includes("6") || userActions?.includes("20")) || userRoles?.includes("5")) && (
          <>
            <div

              style={{ position: "relative", justifyContent: "flex-end" }}
            >
              <CustomTooltip TransitionComponent={Zoom} title={(userRoles?.includes("5") && !partnerStatus) ? <div>Requests have been suspended</div> : ""}>
                <span>
                  <Button
                    className="add-request blue-outlined-btn ml-auto"
                    style={{ right: "0px" }}
                    href={`/GroupRequests/CreateGroupRequest`}
                    disabled={userRoles?.includes("5") && !partnerStatus}
                  >
                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt--1">
                      <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                    </svg> Group Request
                  </Button>
                </span>
              </CustomTooltip>
            </div>
          </>
        )}
      </div>
      <Paper elevation={0} className="p-5">
        <form onSubmit={editGroupRequest}>
          <div className="row w-100 alignvh-center">

            <div className="ml-auto">
              {(userActions?.includes("22") ||
                userActions?.includes("20")) && (
                  groupReq?.groupRequest?.IsPEO !== 1 && (
                    <span>
                      <Button
                        variant="contained"
                        className="blue-outlined-btn mr-2"
                        onClick={() => setOnEditSubgroups(!onEditSubgroups)}
                        disabled={
                          !groupReq ||
                          groupReq?.groupRequest?.GroupRequestStatusName ===
                          "Cancelled"
                        }
                      >
                        <svg width="20" height="24" viewBox="0 0 25 24">
                          <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                        </svg>
                        <label
                          style={{ cursor: "pointer" }}
                          className="ml-2 mb-0"
                        >
                          Edit Subgroups
                        </label>
                      </Button>
                    </span>
                  )
                )}
              {(userActions?.includes("9") ||
                userActions?.includes("11") ||
                userActions?.includes("20")) && (
                  <Button
                    variant="contained"
                    className="blue-outlined-btn mr-2"
                    onClick={() => {
                      setOnEditRequest(!onEditRequest);
                    }}
                    disabled={
                      !groupReq ||
                      groupReq?.groupRequest?.GroupRequestStatusName ===
                      "Cancelled"
                    }
                  >
                    <svg width="20" height="24" viewBox="0 0 25 24">
                      <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                    </svg>
                    <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                      Edit Request
                    </label>
                  </Button>
                )}
              {(userActions?.includes("6") || userActions?.includes("20")) &&
                groupReq?.groupRequest?.GroupRequestStatusID === 4 && (
                  <CustomTooltip
                    TransitionComponent={Zoom}
                    title={
                      groupReq?.connectivities?.any(
                        (s) =>
                          (s.ConnectivityRequestContacts.length === 0 ||
                            s.PlanTypes === null ||
                            s.ClientEffectiveDate == null) &&
                          s.ConnectivityRequestStatusID !== 5
                      )
                        ? `This request cannot be submitted as there are still missing mandatory fields in the Connectivity Requests`
                        : (userRoles?.includes("5") && !partnerStatus) ? `Requests have been suspended` : ``
                    }
                  >

                    <span>
                      <Button
                        variant="contained"
                        className="blue-btn mr-2"
                        onClick={submitRequestFromDraft}
                        disabled={
                          !groupReq ||
                          //  if there is CR with no connectivity request contacts and status is cancelled it will not disable the submit button only if there is another CRs with complete required fields
                          groupReq?.connectivities?.any(
                            (s) =>
                              (s.ConnectivityRequestContacts.length === 0 ||
                                s.PlanTypes === null ||
                                s.ClientEffectiveDate == null) &&
                              s.ConnectivityRequestStatusID !== 5
                          ) || (userRoles?.includes("5") && !partnerStatus)
                        }
                      >
                        {/* <svg viewBox="0 0 20 23" height="30" width="35">
                          <use
                            xlinkHref={`${EditRequestSVG}#editRequest`}
                          ></use>
                        </svg> */}
                        <label
                          style={{ cursor: "pointer" }}
                          className="ml-2 mb-0"
                        >
                          Submit Request
                        </label>
                      </Button>
                    </span>
                  </CustomTooltip>
                )}
              {(userActions?.includes("8") ||
                userActions?.includes("20")) && (
                  <Button
                    variant="contained"
                    className="blue-outlined-btn mr-2"
                    onClick={
                      groupReq?.groupRequest?.GroupRequestStatusName ===
                        "Cancelled"
                        ? () => {
                          uncancelConnectivityModal.openDialog("Connectivity Requests",
                            <UncancelConnectivity
                              uncancelledConnectivities={
                                uncancelledConnectivities
                              }
                              groupReq={groupReq}
                              connectivities={groupReq?.connectivities}
                              openLoader={openLoader}
                              closeLoader={closeLoader}
                            />, []
                          );
                        }
                        : () => {
                          cancelGroupModal.openDialog("Cancel Group Request",
                            <CancelGroupRequest
                              groupReq={groupReq}
                              selectedSubgroups={selectedSubgroups}
                              groupsAndOEContactsList={
                                groupsAndOEContactsList
                              }
                              groupRequestIDURL={groupRequestIDURL}
                              openLoader={openLoader}
                            />, []
                          );
                        }
                    }
                    disabled={
                      userActions?.includes("20")
                        ? !groupReq
                        : !groupReq ||
                        groupReq?.groupRequest?.GroupRequestStatusName ===
                        "Cancelled"
                    }
                  >
                    <svg width="22" height="20" viewBox="0 0 24 24">
                      <use xlinkHref={`${closeIconSVG}#octagon-xmark`}></use>
                    </svg>
                    <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                      {userActions?.includes("20")
                        ? groupReq?.groupRequest?.GroupRequestStatusName ===
                          "Cancelled"
                          ? `Uncancel Request`
                          : `Cancel Request`
                        : `Cancel Request`}
                    </label>
                  </Button>
                )}
            </div>
          </div>
          <Accordion defaultExpanded>
            <AccordionSummary
              aria-controls="panel2-content"
              id="panel-header-cr-config"
              onClick={(event) => {
                event.stopPropagation();
              }}
              style={{ pointerEvents: 'none' }}
            >
              <span>Group Request Information</span>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {/* <Divider className="mt-3 mb-5" /> */}
                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">Instance URL</h6>
                  <div className="textfield-div">
                    {groupReq?.groupRequest?.GroupInstanceURL}
                  </div>
                </Grid>

                {groupReq?.groupRequest?.IsPEO !== 1 && (
                  <Grid item xs={12} sm={12} md={6}>

                    <h6 className="form-title">Client Name</h6>


                    <div className="textfield-div">
                      {groupReq?.groupRequest?.GroupName}

                    </div>
                  </Grid>
                )}
                {groupReq?.groupRequest?.IsPEO !== 1 && (
                  <Grid item xs={12} sm={12} md={6}>
                    <h6 className="form-title">Client Number</h6>


                    <div className="textfield-div">
                      {groupReq?.groupRequest?.GroupNumber}
                    </div>

                  </Grid>
                )}

                {groupReq?.groupRequest?.IsPEO === 1 && (
                  <Grid item xs={12} sm={12} md={6}>
                    <h6 className="form-title">PEO Name</h6>

                    <div className="textfield-div">
                      {groupReq?.groupRequest?.Peo?.PeoName}
                    </div>

                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">
                    {groupReq?.groupRequest?.IsPEO !== 1
                      ? "Client Size"
                      : "PEO Size"}
                  </h6>

                  {onEditRequest ? (
                    <input
                      type="number"
                      className="text-input w-100"
                      value={groupSize}
                      onChange={handleGroupSizeChange}
                    />
                  ) : (
                    <div className="textfield-div">
                      {groupReq?.groupRequest?.GroupSize}
                    </div>
                  )}
                </Grid>





                {/* <div className="col-xl-2 mt-3 alignvh-center">
                            <h6 className="form-title">Client Effective Date</h6>
                        </div>
                        <div className="col-xl-10 mt-3">
                            {onEditRequest ? (
                                <div className="text-input w-100" style={{ padding: "0 10px" }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className="w-100"
                                            autoOk={true}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="frequency-date"
                                            value={groupEffDate === null ? groupEffDate : parseISO(groupEffDate)}
                                            onChange={handleGroupEffDateChange}
                                            keyboardIcon={<svg viewBox="0 0 20 18" height="20">
                                                <use xlinkHref={`${dateTimeSVG}#dateTimeSVG`}></use>
                                            </svg>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            ) : (
                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.GroupEffectiveDate ? Moment(groupReq?.groupRequest?.GroupEffectiveDate).format('MM/DD/YYYY') : ""}
                                </div>
                            )}
                        </div> */}

                {/* {subgroups?.length > 0 && ( */}
                <Grid item xs={12} sm={12} md={6}>

                  <h6 className="form-title">Subgroups</h6>

                  {onEditSubgroups ? (
                    <>
                      <div>
                        {subgroups?.length > 0 ? (
                          <>
                            <div className=" pr-0 pl-0">
                              <input
                                type="text"
                                className="text-input w-100"
                                placeholder="Search subgroups here..."
                                value={subgroupSearchValue}
                                onChange={handleSubgroupSearchValueChange}
                              />
                            </div>
                            <div id="select-subgroups-plans" className="row plans-list-div container-div p-4 checkbox-list">
                              {/* <Accordion key={`subgroups-select-all`}>
                                <AccordionSummary
                                  aria-label="Expand"
                                  aria-controls="subgroups-select"
                                  className="accordion-checkboxes select-carrier-accordion"
                                > */}
                              <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                  <Checkbox
                                    id={`subgroup-option-select-all`}
                                    value={subgroups}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checked={
                                      selectAllSubgroupsChecked === true &&
                                      selectedSubgroups.length ===
                                      subgroups?.filter((s) =>
                                        subgroupSearchValue
                                          ? s.ClientName?.toLowerCase().includes(
                                            subgroupSearchValue.toLowerCase()
                                          )
                                          : s
                                      ).length
                                    }
                                    onChange={handleSelectAllSubgroupChange(
                                      subgroups
                                    )}
                                    onClick={handleSelectAllSubgroupClick(
                                      subgroups?.filter((s) =>
                                        subgroupSearchValue
                                          ? s.ClientName?.toLowerCase().includes(
                                            subgroupSearchValue.toLowerCase()
                                          )
                                          : s
                                      )
                                    )}
                                    checkedIcon={<DoneOutlineIcon />}
                                    color="default"
                                  />
                                }
                                label="Select All"
                              />
                              <br />
                              {/* </AccordionSummary>
                              </Accordion> */}
                              {subgroups
                                ?.filter((s) =>
                                  subgroupSearchValue
                                    ? s.ClientName?.toLowerCase().includes(
                                      subgroupSearchValue.toLowerCase()
                                    )
                                    : s
                                )
                                .map((c, ci) => (
                                  // <Accordion key={`subgroups-${ci}`}>
                                  //   <AccordionSummary
                                  //     aria-label="Expand"
                                  //     aria-controls="subgroups-select"
                                  //     className="accordion-checkboxes select-carrier-accordion"
                                  //   >
                                  <React.Fragment key={`subgroup-type-${ci}`}>
                                    <FormControlLabel
                                      aria-label="Acknowledge"
                                      onClick={(event) => event.stopPropagation()}
                                      onFocus={(event) => event.stopPropagation()}
                                      control={
                                        <Checkbox
                                          id={`subgroup-option-${ci}`}
                                          value={c}
                                          icon={<CheckBoxOutlineBlankIcon />}
                                          checked={
                                            selectedSubgroups &&
                                            selectedSubgroups.indexOf(c) >= 0
                                          }
                                          onChange={handleSubgroupChange(c)}
                                          onClick={handleSubgroupClick(c)}
                                          checkedIcon={<DoneOutlineIcon />}
                                          color="default"
                                        />

                                      }
                                      label={c?.ClientName}
                                    />
                                    <br />
                                  </React.Fragment>

                                ))}

                            </div>
                            <div className="counter-external-div alignvh-center">
                              <div style={{ padding: "8px 8px 5px 8px" }}>
                                {subgroups
                                  ?.filter((s) => selectedSubgroups.indexOf(s) >= 0)
                                  .map((s, si) => (
                                    <TruncatedChip
                                      style={{
                                        marginBottom: "3px",
                                        marginRight: "1px",
                                      }}
                                      key={`selected-carrier-chip-${si}`}
                                      body={s?.ClientName}
                                    />
                                  ))}
                              </div>
                              <div className="text-right ml-auto alignvh-center mr-2 pt-1 pb-1">
                                <div
                                  style={{ whiteSpace: "nowrap" }}
                                  className="counter-div"
                                >
                                  {selectedSubgroups?.length > 1
                                    ? `${selectedSubgroups?.length} Subgroups Selected`
                                    : `${selectedSubgroups?.length} Subgroup Selected`}
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: "end", marginTop: "15px" }}>
                              <Button
                                onClick={updateSubgroups}
                                variant="contained"
                                className="blue-btn"
                              >
                                <label
                                  style={{ cursor: "pointer" }}
                                  className="mb-0"
                                >
                                  Submit
                                </label>
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="textfield-div">
                            {
                              (!(groupReq?.groupRequest?.IsPEO) && subgroups?.length === 0) ? (
                                <p>There are currently no subgroups for this group</p>
                              ) : ("")
                            }
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="textfield-div checkbox-list">
                      {groupReq?.groupRequest?.SubGroups?.map((s, si) => (
                        <div
                          key={`subgroups-${si}`}
                        >{`${s.GroupName} (${s?.GroupNumber})`}</div>
                      ))}
                    </div>
                  )}
                </Grid>


                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">OE Contact</h6>


                  {onEditRequest ? (
                    <FormControl
                      label="Select OE contact"
                      focused={false}
                      variant="outlined"
                      className="w-100"
                    >
                      <Select
                        className="text-dropdown"
                        value={OEContact ?? ""}
                        onChange={(e) => setOEContact(e.target.value)}
                      >
                        <MenuItem value="">None</MenuItem>
                        <ListSubheader
                          className="p-0"
                          onClickCapture={(e) => e.stopPropagation()}
                        >
                          <GroupHeader>Enabled</GroupHeader>
                        </ListSubheader>
                        {groupsAndOEContactsList
                          ?.filter((s) => s.IsEnabled === 1)
                          .map((c, ci) => (
                            <MenuItem
                              key={`users-list-enabled-${ci}`}
                              value={c.CognitoID}
                            >{`${c.FirstName} ${c.LastName}`}</MenuItem>
                          ))}
                        <ListSubheader
                          className="p-0"
                          onClickCapture={(e) => e.stopPropagation()}
                        >
                          <GroupHeader>Disabled</GroupHeader>
                        </ListSubheader>
                        {groupsAndOEContactsList
                          ?.filter((s) => s.IsEnabled !== 1)
                          .map((c, ci) => (
                            <MenuItem
                              key={`users-list-disabled-${ci}`}
                              value={c.CognitoID}
                            >{`${c.FirstName} ${c.LastName}`}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="textfield-div">
                      {groupReq?.groupRequest?.OEContact}
                    </div>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">Migration</h6>


                  {onEditRequest &&
                    groupReq?.groupRequest?.GroupRequestStatusName === "Draft" ? (
                    <FormControl
                      label="Select Migration"
                      focused={false}
                      variant="outlined"
                      className="w-100"
                    >
                      <Select
                        className="text-dropdown"
                        value={migration ?? ""}
                        onChange={(e) => setMigration(e.target.value)}
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="eBenefits Network">
                          eBenefits Network
                        </MenuItem>
                        <MenuItem value="Everything Benefits">
                          Everything Benefits
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="textfield-div">
                      {groupReq?.groupRequest?.MigrationGroupRequest}
                    </div>
                  )}
                  <div
                    className="col-xl-2 alignvh-center"
                    hidden={!requestSubmitted}
                  ></div>
                  <div
                    className="col-xl-10 mt-2 text-danger"
                    hidden={!requestSubmitted}
                  >
                    {selectMigrationValidationForm}
                  </div>
                </Grid>

                {/* Migration Added Field*/}
                {((onEditRequest &&
                  (migration === "eBenefits Network" ||
                    migration === "Everything Benefits")) ||
                  (!onEditRequest &&
                    (groupReq?.groupRequest?.MigrationGroupRequest ===
                      "Everything Benefits" ||
                      groupReq?.groupRequest?.MigrationGroupRequest ===
                      "eBenefits Network"))) && (
                    <Grid item xs={12} sm={12} md={6}>

                      <h6 className="form-title">Migration Start Date</h6>


                      {onEditRequest ? (
                        <div
                          className="text-input w-100"
                          style={{ padding: "0px 8px 0px 12px" }}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="w-100"
                              autoOk={true}
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="migrationStart-date"
                              value={
                                migrationStartDate === null
                                  ? migrationStartDate
                                  : parseISO(migrationStartDate)
                              }
                              onChange={handleMigrationStartDateChange}
                              keyboardIcon={
                                <svg width="14" height="16" viewBox="0 0 14 16">
                                  <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                </svg>
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      ) : (
                        <div className="textfield-div">
                          {groupReq?.groupRequest?.MigrationStartDate
                            ? Moment(
                              groupReq?.groupRequest?.MigrationStartDate
                            ).format("MM/DD/YYYY")
                            : null}
                          <div className="ml-auto">
                            <svg width="14" height="16" viewBox="0 0 14 16">
                              <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                            </svg>
                          </div>
                        </div>
                      )}

                      <div
                        className="col-xl-2 alignvh-center"
                        hidden={!requestSubmitted & !requestSaved}
                      ></div>
                      <div
                        className="col-xl-10 mt-2 text-danger"
                        hidden={!requestSubmitted & !requestSaved}
                      >
                        {selectMigrationStarDateValidationForm}
                      </div>
                    </Grid>
                  )}


                {((onEditRequest &&
                  (migration === "eBenefits Network" ||
                    migration === "Everything Benefits")) ||
                  (!onEditRequest &&
                    (groupReq?.groupRequest?.MigrationGroupRequest ===
                      "Everything Benefits" ||
                      groupReq?.groupRequest?.MigrationGroupRequest ===
                      "eBenefits Network"))) && (
                    <Grid item xs={12} sm={12} md={6}>
                      <h6 className="form-title">
                        Planned First Production Date
                      </h6>


                      {onEditRequest ? (
                        <div
                          className="text-input w-100"
                          style={{ padding: "0px 8px 0px 12px" }}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="w-100"
                              autoOk={true}
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="migrationPlannedStart-date"
                              value={
                                plannedFirstProductionDate === null
                                  ? plannedFirstProductionDate
                                  : parseISO(plannedFirstProductionDate)
                              }
                              onChange={handlePlannedFirstProductionDateChange}
                              keyboardIcon={
                                <svg width="14" height="16" viewBox="0 0 14 16">
                                  <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                </svg>
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      ) : (
                        <div className="textfield-div">
                          {groupReq?.groupRequest?.PlannedFirstProductionDate
                            ? Moment(
                              groupReq?.groupRequest?.PlannedFirstProductionDate
                            ).format("MM/DD/YYYY")
                            : null}
                          <div className="ml-auto">
                            <svg width="14" height="16" viewBox="0 0 14 16">
                              <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                            </svg>
                          </div>
                        </div>
                      )}
                      <div
                        className="col-xl-2 alignvh-center"
                        hidden={!requestSubmitted && !requestSaved}
                      ></div>
                      <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectPlannedFirstProductionDateValidationForm}</div>
                    </Grid>
                  )}



                {((onEditRequest &&
                  (migration === "eBenefits Network" ||
                    migration === "Everything Benefits")) ||
                  (!onEditRequest &&
                    (groupReq?.groupRequest?.MigrationGroupRequest ===
                      "Everything Benefits" ||
                      groupReq?.groupRequest?.MigrationGroupRequest ===
                      "eBenefits Network"))) && (
                    <Grid item xs={12} sm={12} md={6}>
                      <h6 className="form-title">Migration Phase</h6>


                      {onEditRequest ? (
                        <FormControl
                          label="Select migration phase"
                          focused={false}
                          variant="outlined"
                          className="w-100"
                        >
                          <Select
                            className="text-dropdown"
                            value={migrationPhase ?? ""}
                            onChange={(e) => setMigrationPhase(e.target.value)}
                          >
                            {migrationPhasesList &&
                              migrationPhasesList?.map((c, ci) => (
                                <MenuItem
                                  key={`migration-phase-${ci}`}
                                  value={c.Id}
                                >
                                  {c.Name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <div className="textfield-div">
                          {groupReq?.groupRequest?.MigrationPhaseName}
                        </div>
                      )}
                      <div
                        className="col-xl-2 alignvh-center"
                        hidden={!requestSubmitted && !requestSaved}
                      ></div>
                      <div
                        className="col-xl-10 mt-2 text-danger"
                        hidden={!requestSubmitted && !requestSaved}
                      >
                        {selectMigrationPhaseValidationForm}
                      </div>
                    </Grid>


                  )}


                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">Status</h6>


                  <div className="textfield-div">
                    {groupReq?.groupRequest?.GroupRequestStatusName}
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">Phase</h6>


                  <div className="textfield-div">
                    {groupReq?.groupRequest?.PhaseName}
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">Submitted Date</h6>

                  <div className="textfield-div">
                    {groupReq?.groupRequest?.Created
                      ? Moment(groupReq?.groupRequest?.Created).format(
                        "MM/DD/YYYY"
                      )
                      : null}
                    <div className="ml-auto">
                      <svg width="14" height="16" viewBox="0 0 14 16">
                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                      </svg>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <h6 className="form-title">Created By</h6>


                  <div className="textfield-div">
                    {groupReq?.groupRequest?.CreatedBy}
                  </div>

                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Client Contacts */}
          <Accordion
            className="connectivity-req-acc w-100 mt-5"
            expanded={expanded === `clientContacts`}
          >
            <AccordionSummary
              onClick={() =>
                expanded === `clientContacts`
                  ? setExpanded("")
                  : setExpanded("clientContacts")
              }

              // className="pl-0 pr-0"
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="expand-connectivity-requests"
            >
              <span className="ml-2">Client Contacts</span>
              <div
                className="add-record-btn ml-auto"
                onClick={handleAddContactsButtonClick}
              >
                <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                  <use xlinkHref={`${plusIconWhiteSVG}#PlusIconWhite`}></use>
                </svg> ADD
              </div>
            </AccordionSummary>


            <AccordionDetails className="d-flex flex-column w-100">
              {/* <Divider className="mb-5" /> */}
              <ViewGroupReqestClientContacts
                groupReq={groupReq}
                setGroupReq={setGroupReq}
                addContactClicked={addContactClicked}
                setAddContactClicked={setAddContactClicked}
              />

              <div
                className="row w-100 alignvh-center"
                style={{ marginTop: "30px" }}
              >
                <h6 className="form-title ml-3">Client Contact Notes</h6>
                <div className="row w-100 alignvh-center">
                  {onEditRequest ? (
                    <textarea
                      type="text"
                      className="action-item-textarea w-100 ml-3 mr-3 mb-3"
                      placeholder="Write Contact Notes here..."
                      rows={5}
                      value={contactNotes}
                      onChange={handleContactNotesChange}
                    />
                  ) : (
                    <textarea
                      type="text"
                      className="action-item-textarea textfield-div ml-3 mr-3 mb-3"
                      rows={5}
                      disabled
                      value={groupReq?.groupRequest?.ContactNotes}
                    // onChange={handleContactNotesChange}
                    />
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* //Client Contacts */}

          <div className="mt-5 w-100">
            <ConnectivityRequests
              groupReq={groupReq}
              setGroupReq={setGroupReq}
              setSelectedSubgroupsView={setSelectedSubgroupsView}
              onEditRequest={onEditRequest}
              setOnEditRequest={setOnEditRequest}
              groupRequestID={groupRequestIDURL}
              connectivities={groupReq?.connectivities}
            />
          </div>

          <div hidden>
            {/* Activity Logs */}
            <div className="row mt-5 w-100 alignvh-center">
              <h5 className="black1 fw-700 mt-5">Activity Logs</h5>
            </div>
            <Divider className="mt-3 mb-5" />
            {/* For Empty Activity Logs */}
            <div
              style={{
                background: "rgba(237, 234, 234, 0.4)",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <svg viewBox="0 0 190 183" width="250px" height="250px">
                <use
                  xlinkHref={`${EmptyActivityLogsSVG}#emptyActivityLogs`}
                ></use>
              </svg>
            </div>
          </div>
          {onEditRequest && (
            <div className="w-100 mt-5 text-right">
              {groupReq?.groupRequest?.GroupRequestStatusID === 4 && (
                <Button
                  type="submit"
                  variant="contained"
                  className="blue-btn mr-3"
                  onClick={() => {
                    setRequestSaved(true);
                    setRequestSubmitted(false);
                  }}
                >
                  Save Draft
                </Button>
              )}
              <CustomTooltip
                TransitionComponent={Zoom}
                title={
                  groupReq?.connectivities?.any(
                    (s) =>
                      (s.ConnectivityRequestContacts.length === 0 ||
                        s.PlanTypes === null ||
                        s.ClientEffectiveDate == null) &&
                      s.ConnectivityRequestStatusID !== 5
                  )
                    ? `This request cannot be submitted as there are still missing mandatory fields in the Connectivity Requests`
                    : (userRoles?.includes("5") && !partnerStatus) ? `Requests have been suspended` : ``
                }
              >
                <span>
                  <Button
                    type="submit"
                    variant="contained"
                    className="blue-btn"
                    disabled={
                      !groupReq ||
                      //  if there is CR with no connectivity request contacts and status is cancelled it will not disable the submit button only if there is another CRs with complete required fields
                      groupReq?.connectivities?.any(
                        (s) =>
                          (s.ConnectivityRequestContacts.length === 0 ||
                            s.PlanTypes === null ||
                            s.ClientEffectiveDate == null) &&
                          s.ConnectivityRequestStatusID !== 5
                      ) || (userRoles?.includes("5") && !partnerStatus)
                    }
                    onClick={() => {
                      setRequestSubmitted(true);
                      setRequestSaved(false);
                    }}
                  >
                    Submit Request
                  </Button>
                </span>
              </CustomTooltip>
            </div>
          )}

        </form>
      </Paper>
    </div >
  );
}

export default ViewGroupRequest;
