import React from "react"
import { useParams } from "react-router-dom"
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps"
import * as APIs from "../../../../Data/APIs"
import TabsTitles from "../../../Layout/TabsTitles"
import { Zoom, FormControl, MenuItem, Divider, Paper, Button, Select, TextField, createStyles, makeStyles, } from "@material-ui/core"
import EditRequestSVG from "../../../../Images/editRequest.svg"
import dateTimeSVG from "../../../../Images/dateTime.svg"
import Moment from "moment"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import Autocomplete from "@material-ui/lab/Autocomplete"
import BackupIcon from "@material-ui/icons/Backup"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { useLoader } from "../../../Layout/Loader"
import AnalystConnectivityRequestAIComments from "./AnalystConnectivityRequestAIComments"
import { useAuthentication } from "../../../../Data/Authentication"
import { DropzoneArea } from "material-ui-dropzone"
import MuiAlert from "@mui/material/Alert"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt"
import CloseActionModal from "./CloseActionModal"
import { useModalDialog } from "../../../Layout/ModalDialogCustomized"
import { CustomTooltip } from "../../../Layout/Tooltip";
import { parseISO } from 'date-fns';
import UploadedFileAI from "./UploadedFileAI"
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { FailureModal } from "../../../Modals/FailureModal"
import SwapHorizSharpIcon from '@mui/icons-material/SwapHorizSharp';
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import editRequestIcon from "../../../../Icons/editIcon.svg";
import goToIcon from "../../../../Icons/right-to-bracket.svg";
import arrowUpIcon from "../../../../Icons/arrow-up.svg";
import reassignIcon from "../../../../Icons/right-left.svg";
import closeIconSVG from "../../../../Icons/octagon-xmark.svg";
import { Grid } from "@mui/material"


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})
const todayDate = new Date()
todayDate.setHours(0, 0, 0, 0)
const useStyles = makeStyles(theme => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
    marginTop: 10,
    padding: 6,
    backgroundColor: "#E5E5E5",
    borderColor: "#E5E5E5",
  },
}));

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: "var(--grey2-secondary-color)",
  backgroundColor: "var(--grey1)",
  fontSize: "12px",
}));

const GroupHeaderSelect = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '0 10px',
  color: "var(--grey2-secondary-color)",
  backgroundColor: "var(--grey1)",
  fontSize: "12px",
}));

const GroupItems = styled('ul')({
  padding: 0,
});

function AnalystConnectivityRequestViewActionItem() {
  const { authUser } = useAuthentication()
  const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",")
  const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

  const classes = useStyles();
  const { openLoader, closeLoader } = useLoader()
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  const dialog = useModalDialog(0)
  const [, forceUpdate] = React.useReducer(x => !x, false)

  const { actionItemIDURL } = useParams()
  const { connectivityRequestIDURL } = useParams()

  const [actionItem, setActionItem] = React.useState()
  const [onEditAI, setOnEditAI] = React.useState(false)
  const [responsiblePartiesList, setResponsiblePartiesList] = React.useState([])
  const [responsibleParty, setResponsibleParty] = React.useState("")
  const [contactsList, setContactsList] = React.useState([])
  const [contactsAnalystsList, setContactsAnalystsList] = React.useState([])
  const [contact, setContact] = React.useState("")
  const [actionItemText, setActionItemText] = React.useState("")
  const [actionItemDescription, setActionItemDescription] = React.useState("")
  const [fileSingleAttached, setFileSingleAttached] = React.useState("")

  const [dueDate, setDueDate] = React.useState(null)
  const [statusesList, setStatusesList] = React.useState([])
  const [status, setStatus] = React.useState("")
  const [completionDate, setCompletionDate] = React.useState(null)
  const [aiComments, setAIComments] = React.useState([])
  const [requestSubmitted, setRequestSubmitted] = React.useState(false)
  const [actionItemClosureReason, setActionItemClosureReason] = React.useState("")
  const [statusDialogSubmit, setStatusDialogSubmit] = React.useState(false)
  const [expanded, setExpanded] = React.useState(true)
  const [showMoreExpanded, setShowMoreExpanded] = React.useState(false)

  const [fileAttachedList, setFileAttachedList] = React.useState([]);
  const [uploadedFileAttachedList, setUploadedFileAttachedList] = React.useState([]);
  const [tempGUID, setTempGUID] = React.useState("");
  const [primaryAnalyst, setPrimaryAnalyst] = React.useState("");
  const [isolvedRepresentative, setIsolvedRepresentative] = React.useState("");
  const [usersList, setUsersList] = React.useState([]);
  const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
  // Validations
  const [selectResponsiblePartyValidation, setSelectResponsiblePartyValidation] = React.useState("")
  const [selectContactValidation, setSelectContactValidation] = React.useState("")
  const [selectActionItemTextValidation, setSelectActionItemTextValidation] = React.useState("")
  const [selectActionItemDescriptionValidation, setSelectActionItemDescriptionValidation] = React.useState("")
  const [selectDueDateValidation, setSelectDueDateValidation] = React.useState("")
  const [selectStatusValidation, setSelectStatusValidation] = React.useState("")
  const [selectPrimaryAnalystValidation, setSelectPrimaryAnalystValidation] = React.useState("");
  const [selectIsolvedRepValidation, setSelectIsolvedRepValidation] = React.useState("");
  const [selectCompletionDateValidation, setSelectCompletionDateValidation] = React.useState("")
  const [limitedTitleCharMsg, setLimitedTitleCharMsg] = React.useState("")
  const [elapsedTimeDifferenceWarning, setElapsedTimeDifferenceWarning] = React.useState("")
  const [selectClosureReasonValidation, setSelectClosureReasonValidation] = React.useState("")
  const [connectivityReq, setConnectivityReq] = React.useState();
  const fileInputRef = React.useRef();


  const isSuperAdminOrAnalyst = () => {
    return userRoles?.includes("1") || userRoles?.includes("7")
  }



  React.useEffect(() => {
    openLoader();
    APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => { setConnectivityReq(r?.data); }).finally(() => closeLoader());
  }, [])

  React.useEffect(() => {
    if (!responsibleParty) setSelectResponsiblePartyValidation("Responsible party is missing, please select a responsible party from the list"); else setSelectResponsiblePartyValidation("")
  }, [responsibleParty, selectResponsiblePartyValidation])

  React.useEffect(() => {
    if (!contact) setSelectContactValidation("Contact name is missing, please select a contact from the list"); else setSelectContactValidation("")
  }, [contact, selectContactValidation])

  React.useEffect(() => {
    if (!actionItemText?.replaceAll(" ", "")) setSelectActionItemTextValidation("Action item title is missing, please describe briefly your case"); else setSelectActionItemTextValidation("")
  }, [actionItemText, selectActionItemTextValidation])

  React.useEffect(() => {
    if (!actionItemDescription?.replaceAll(" ", "").replaceAll("\n", "")) setSelectActionItemDescriptionValidation("Action item description is missing, please describe your case"); else setSelectActionItemDescriptionValidation("")
  }, [actionItemDescription, selectActionItemDescriptionValidation])

  React.useEffect(() => {
    if (!dueDate || dueDate === "Invalid date") setSelectDueDateValidation("Due date cannot be empty"); else setSelectDueDateValidation("")
  }, [dueDate, selectDueDateValidation])

  React.useEffect(() => {
    if (!status) setSelectStatusValidation("Action item status is not set, please select a status from the list"); else setSelectStatusValidation("")
  }, [status, selectStatusValidation])
  // responsibleParty = 1 (iSolved) , 3 (Analyst)
  React.useEffect(() => {
    if (!primaryAnalyst &&
      ((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
        || (responsibleParty === 3 && !(isSuperAdminOrAnalyst())))
    )
      setSelectPrimaryAnalystValidation("Primary Analyst is not set , Please select Primary Analyst From the List");
    else setSelectPrimaryAnalystValidation("");
  }, [primaryAnalyst, responsibleParty, selectPrimaryAnalystValidation])

  React.useEffect(() => {
    if (!isolvedRepresentative &&
      ((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
        || (responsibleParty === 3 && !(isSuperAdminOrAnalyst())))
    )
      setSelectIsolvedRepValidation("ISOLVED Representative is not set , Please select ISOLVED Representative From the List");
    else setSelectIsolvedRepValidation("");
  }, [isolvedRepresentative, responsibleParty, selectIsolvedRepValidation])

  React.useEffect(() => {
    if (status === 2 && (!completionDate || completionDate === "Invalid date")) setSelectCompletionDateValidation("Completion date cannot be empty if the action item is closed"); else setSelectCompletionDateValidation("")
  }, [status, completionDate, selectCompletionDateValidation])

  React.useEffect(() => {
    if (status === 2 && !actionItemClosureReason?.replaceAll(" ", "").replaceAll("\n", "")) setSelectClosureReasonValidation("Closure Reason cannot be empty if the action item is closed"); else setSelectClosureReasonValidation("")
  }, [status, actionItemClosureReason, selectClosureReasonValidation])

  React.useEffect(() => {
    openLoader();
    APIs.getAllUsers().then((r) => (setUsersList(r?.data)));
  }, [])


  React.useEffect(() => {
    openLoader();
    APIs.getAllGroupsAndOEContacts().then((r) => (
      !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
      closeLoader()
    ));
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getActionItemDetails(actionItemIDURL)
      .then(r => {
        setActionItem(r?.data)
        setAIComments(r?.data?.Comments ?? [])
        forceUpdate()
      })
      .finally(() => closeLoader())
  }, [])



  React.useEffect(() => {
    APIs.getResponsiblePartiesList().then(r => !(r?.data?.length) ? setResponsiblePartiesList([]) : setResponsiblePartiesList(r?.data))
  }, [])

  React.useEffect(() => {
    APIs.getActionItemStatusesList().then(r => !(r?.data?.length) ? setStatusesList([]) : setStatusesList(r?.data))
  }, [])

  React.useEffect(() => {
    const contactsBody = {
      ResponsiblePartyID: responsibleParty,
      ConnectivityRequestID: actionItem?.ConnectivityRequestID,
    }
    APIs.getContactsList(contactsBody).then(r => {
      !(r?.data?.length) ? setContactsList([]) : setContactsList(r?.data);
    })
  }, [responsibleParty])

  React.useEffect(() => {
    const contactsBody = {
      ResponsiblePartyID: 3,
      ConnectivityRequestID: actionItem?.ConnectivityRequestID,
    }
    APIs.getContactsList(contactsBody).then(r => {
      !(r?.data?.length) ? setContactsAnalystsList([]) : setContactsAnalystsList(r?.data);
    })
  }, [])

  const handleLimitedTitleCharRestriction = e => {
    if (e.target.value.length > 59 && e.key !== "Backspace") {
      e.preventDefault()
      setLimitedTitleCharMsg(`Action item should not be longer than 60 characters`)
    } else {
      setLimitedTitleCharMsg("")
    }
  }




  const handleFileAttachedChangeDrag = async (files) => {
    if (files.length === 0) return;
    if (files.any(s => s?.size === 0)) {
      if (files.length === 1) {
        FailureModal(dialog, {
          title: 'Failure!',
          body: 'File is empty!'
        });
      } else {
        FailureModal(dialog, {
          title: 'Failure!',
          body: 'One or more files are empty!'
        });
      }
    }
    else {
      await encodeFileAttachedBase64(files);
    }
  }

  const handelDeleteFile = (fileObj) => {
    let fileAttachedListArray = uploadedFileAttachedList;
    fileAttachedListArray.splice(fileAttachedListArray.findIndex((element) => {
      return element === fileObj;
    }), 1);
    setUploadedFileAttachedList([...fileAttachedListArray]);
  }

  const handelDeleteSingleFile = () => {
    openLoader();
    let deleteAttachmentAIBody = {
      ActionItemID: actionItem?.Id,
    }

    APIs.deleteActionItemAttachment(deleteAttachmentAIBody)
      .then(r => {
        window.location.reload();
      })
      .finally(t => closeLoader())
  }

  const encodeFileAttachedBase64 = async (files) => {
    files.map((file, fi) => {
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var Base64 = reader.result?.split(',')[1];
          addCurrentFilesInArray(file, Base64,);

        };
        reader.onerror = (error) => {
          console.log("error: ", error);
        };
      }
    });

  };

  const addCurrentFilesInArray = (file, fileBase64,) => {
    if (file === undefined) return;
    openLoader();
    let UploadedFilesArray = uploadedFileAttachedList;
    let newFile = {
      Type: "ActionItem",
      Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
      Name: file?.name,
    }
    let newFileUploadAPI = {
      Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
      Value: fileBase64,
    }
    APIs.addActionItemAttachment(newFileUploadAPI).then(r => {
      newFile.GUID = r.GUID;
      setTempGUID(r?.GUID);

    });
    UploadedFilesArray.push(newFile);
    setUploadedFileAttachedList([...UploadedFilesArray]);
  }

  const handleDueDateChange = date => {
    setDueDate(Moment(date).format("YYYY-MM-DD"))
  }

  const handleCompletionDateChange = date => {
    setCompletionDate(Moment(date).format("YYYY-MM-DD"))
  }

  React.useEffect(() => {
    if (status === 2 && (actionItem?.Status === 1 || actionItem?.Status === 3) && onEditAI) {
      setCompletionDate(Moment(todayDate).format("YYYY-MM-DD"));
    }
  }, [status])

  React.useEffect(() => {
    setResponsibleParty(actionItem?.ResponsiblePartyID)
    setActionItemText(actionItem?.ActionItemText)
    setActionItemDescription(actionItem?.Description)
    setDueDate(actionItem?.DueDate)
    setStatus(actionItem?.Status)
    setCompletionDate(actionItem?.CompletionDate)
    setActionItemClosureReason(actionItem?.CloseReason)
    setFileAttachedList(actionItem?.Attachments)
    setIsolvedRepresentative(actionItem?.IsolvedRepresentativeCognitoID);
    setPrimaryAnalyst(actionItem?.PrimaryAnalystCognitoID);
    if (responsibleParty === 2) {
      let filteredCarrierContact = contactsList.filter(s => s?.Id === actionItem?.CarrierContactID)[0]
      setContact(filteredCarrierContact)
    } else {
      let filteredContact = contactsList.filter(s => s?.Id === actionItem?.ContactID)[0]
      setContact(filteredContact)
    }
  }, [onEditAI === true, actionItem])

  React.useEffect(() => {
    if (responsibleParty === 2) {
      let filteredCarrierContact = contactsList.filter(s => s?.Id === actionItem?.CarrierContactID)[0]
      setContact(filteredCarrierContact)
    } else {
      let filteredContact = contactsList.filter(s => s?.Id === actionItem?.ContactID)[0]
      setContact(filteredContact)
    }
  }, [contactsList])

  React.useEffect(() => {
    let dueDateFormatted = Moment(actionItem?.DueDate).format("YYYY-MM-DD")
    let startDateFormatted = Moment(actionItem?.Created).format("YYYY-MM-DD")
    const daysDiff = Math.ceil(Math.abs(new Date(dueDateFormatted) - new Date(startDateFormatted)) / (1000 * 60 * 60 * 24))
    if (daysDiff < actionItem?.ElapsedTime) setElapsedTimeDifferenceWarning("Days Exceeded, need urgent action!")
    else if (daysDiff === actionItem?.ElapsedTime) setElapsedTimeDifferenceWarning("At risk!")
    else setElapsedTimeDifferenceWarning("")
  }, [actionItem])

  const editActionItem = e => {
    e.preventDefault()
    if (selectResponsiblePartyValidation || selectContactValidation || selectActionItemTextValidation || selectActionItemDescriptionValidation || selectDueDateValidation || selectStatusValidation || selectCompletionDateValidation || selectClosureReasonValidation || selectPrimaryAnalystValidation || selectIsolvedRepValidation) return
    editActionItemFunc()
  }

  React.useEffect(() => {
    if (statusDialogSubmit === true) {
      editActionItemFunc()
    }
  }, [statusDialogSubmit === true])

  function openModal(modalType, handleFunction, content) {
    switch (modalType) {
      case 0:
        return ConfirmModal(dialog, handleFunction, content);
      default:
        break;
    }
  }
  const handleEscalateAI = () => {
    openModal(0, addEscalateComment, {
      title: 'Please Confirm',
      question: `Are you sure you want to escalate this action item?`,
    })
  }
  const editActionItemFunc = () => {
    openLoader()
    let editAIBody = {}
    editAIBody = {
      ActionItemID: actionItem?.Id,
      Status: status,
      CompletionDate: (status === 1 || status === 3) ? null : Moment(completionDate).format("YYYY-MM-DD"),
      CloseReason: (status === 1 || status === 3) ? "" : actionItemClosureReason,
      NewAttachments: uploadedFileAttachedList?.length > 0 ? uploadedFileAttachedList : null,
      ResponsiblePartyID: responsibleParty,
      ActionItemText: actionItemText,
      Description: actionItemDescription.replaceAll("\n", "<br/>"),
      DueDate: dueDate,
      IsolvedRepresentative: isolvedRepresentative,
      PrimaryAnalyst: primaryAnalyst
    }

    if (responsibleParty === 2) {
      editAIBody.CarrierContactID = contact?.Id;
    } else {
      editAIBody.ContactID = contact?.Id;
    }


    APIs.editActionItem(editAIBody)
      .then(r => {
        window.location.reload();
      })
      .finally(t => closeLoader())
  }

  const reAssignActionItemFunc = () => {
    openLoader()
    let reassignBody = {
      ActionItemID: actionItem?.Id,
      // 1 iSolved 3 analy
      ResponsiblePartyID: actionItem?.ResponsiblePartyID == 3 ? 1 : 3,
      AssignedTo: actionItem?.ResponsiblePartyID == 3 ? actionItem?.IsolvedRepresentativeCognitoID : actionItem?.PrimaryAnalystCognitoID
    }
    APIs.reAssignActionItem(reassignBody)
      .then(r => {
        window.location.reload();
      })
      .finally(t => closeLoader())

  }

  React.useEffect(() => {
    if (uploadedFileAttachedList?.any(s => !s.GUID)) {
      openLoader();
    }
    else closeLoader();
  }, [tempGUID])


  const addEscalateComment = () => {
    openLoader();
    const addCRCommentBody = {
      ActionItemID: actionItem?.Id,
      Comment: "Hi,<br/>Kindly note this Action Item needs to be escalated.<br/>Thank you",
      FileName: null,
      Extension: null,
      CommentSeverity: "Escalated",
      Value: null
    }

    APIs.addActionItemComments(addCRCommentBody).then((r) => {
      APIs.getActionItemDetails(actionItem?.Id).then(d => {
        setActionItem(d?.data);
        setAIComments(d?.data?.Comments ?? []);
        forceUpdate()
        closeLoader();
      });
    })
  }

  return (
    <div id="view-action-item">

      {actionItem && connectivityReq && (
        <BreadcrumbSteps
          breadcrumbTitle={`Connectivity Request "${connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName} - ${connectivityReq?.CarrierName === "Third Party" ? connectivityReq?.CarrierName + " (" + connectivityReq?.ThirdPartyName + ") " : connectivityReq?.CarrierName} "`}
          isCurrent={false}
          breadcrumbTitleCurrent={<><a href={`/ActionItems/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`}>Action Items</a>  {`/  Action Item #${actionItemIDURL}`} </>}
          breadcrumbLink={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`}
        />
      )}

      <TabsTitles tabTitle={["View Action Item"]} />

      <Paper elevation={0} className="p-5">

        <div className="w-100 alignvh-center d-flex flex-column">
          {(userActions?.includes("40") || userActions?.includes("20")) && (
            <div className="ml-auto">
              <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => setOnEditAI(!onEditAI)} disabled={!actionItem || contactsList?.length <= 0 || responsiblePartiesList?.length <= 0}>
                <svg width="20" height="24" viewBox="0 0 25 24">
                  <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                </svg>
                <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                  Edit Action Item
                </label>
              </Button>
              <CustomTooltip TransitionComponent={Zoom} title={actionItem?.Status === 2 ? "Action item is already closed" : ""}>
                <span>
                  <Button disabled={!actionItem || contactsList?.length <= 0 || responsiblePartiesList?.length <= 0 || actionItem?.Status === 2} variant="contained" className="blue-btn mr-2"
                    onClick={() => {
                      setCompletionDate(todayDate);
                      dialog.openDialog("Close Action Item",
                        <CloseActionModal actionItem={actionItem} contact={contact} onsetCompletionDate={s => setCompletionDate(s)} onsetActionItemClosureReason={s => setActionItemClosureReason(s)} onSetStatus={s => setStatus(s)} onsetStatusDialogSubmit={s => setStatusDialogSubmit(s)} />, []
                      )
                    }}
                  >
                    <svg width="22" height="24" viewBox="0 0 24 24">
                      <use xlinkHref={`${closeIconSVG}#octagon-xmark`}></use>
                    </svg>
                    <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                      Close Action Item
                    </label>
                  </Button>
                </span>
              </CustomTooltip>

              <CustomTooltip TransitionComponent={Zoom} title={actionItem?.ActionItemSeverity !== null ? "Action item is already Escalated" : ""}>
                <span className="escalated">
                  <Button disabled={!actionItem || actionItem?.ActionItemSeverity !== null} variant="contained" className={actionItem?.ActionItemSeverity !== null ? 'escalated-btn' : 'blue-btn'}
                    onClick={handleEscalateAI}
                  >
                    {/* <ArrowUpwardIcon /> */}
                    <svg width="15" height="21" viewBox="0 0 19 21">
                      <use xlinkHref={`${arrowUpIcon}#arrow-up`}></use>
                    </svg>
                    <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                      {actionItem?.ActionItemSeverity !== null ? 'Escalated' : 'Escalate Action Item'}
                    </label>
                  </Button>
                </span>
              </CustomTooltip>
            </div>
          )}
          <div className="ml-auto mt-4" style={{ position: "relative", justifyContent: "flex-end" }}>
            <Button disabled={!actionItem || onEditAI || !actionItem?.PrimaryAnalystName || !actionItem?.IsolvedRepresentativeName || actionItem?.Status === 2} variant="contained" className="blue-btn" style={{ right: "0px" }} onClick={reAssignActionItemFunc}>
              {/* <SwapHorizSharpIcon /> */}
              <svg width="18" height="24" viewBox="0 0 25 24">
                <use xlinkHref={`${reassignIcon}#right-left`}></use>
              </svg>
              <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                Re-assign
              </label>
            </Button>
          </div>
        </div>
        <form onSubmit={editActionItem}>

          <div className="newUi-collapse mt-3">
            <Accordion className="" expanded={expanded} id="action-item-information">
              <AccordionSummary
                onClick={() => setExpanded(!expanded)}
                className="pl-0 pr-0"
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-oe"
              >
                <span>Action Item Information</span>
              </AccordionSummary>
              <AccordionDetails>
                {/* ========================================== */}

                <div hidden className="row" style={{ position: "relative", justifyContent: "flex-end" }}>
                  <div className="col-xl-6  alignvh-center">
                    <div className="col-xl-6 pr-2  alignvh-center">
                      <div className="w-100 mt-3">
                        <h6 className="form-title">Primary Analyst</h6>
                        {((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                          || (responsibleParty === 3 && !(isSuperAdminOrAnalyst()))) && <span className="text-danger  ml-2">*</span>}

                        <div className=" mt-3">
                          {(onEditAI) ? (
                            <FormControl label="Select assigned to" focused={false} variant="outlined" className="w-100">
                              <Select
                                className="text-dropdown"
                                value={primaryAnalyst ?? ""}
                                onChange={e => setPrimaryAnalyst(e.target.value)}
                              >
                                <MenuItem value="">None</MenuItem>
                                {contactsAnalystsList?.map((c, ci) => (
                                  <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="textfield-div">
                              {actionItem?.PrimaryAnalystName}
                            </div>
                          )}
                          <div className="col-xl-2 mt-2 alignvh-center" hidden={!requestSubmitted}></div>
                          <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>
                            {selectPrimaryAnalystValidation}
                          </div>


                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6  alignvh-center">
                      <div className="w-100 mt-3">
                        <h6 className="form-title">isolved Representative</h6>
                        {((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                          || (responsibleParty === 3 && !(isSuperAdminOrAnalyst()))) && <span className="text-danger  ml-2">*</span>}
                        <div className="col-xl-12 mt-3">
                          {onEditAI ? (
                            <FormControl label="Select isolved representative" focused={false} variant="outlined" className="w-100">
                              <Select
                                className="text-dropdown"
                                value={isolvedRepresentative ?? ""}
                                onChange={e => setIsolvedRepresentative(e.target.value)}
                              >
                                <MenuItem value="">None</MenuItem>
                                {groupsAndOEContactsList?.map((c, ci) => (
                                  <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="textfield-div">
                              {actionItem?.IsolvedRepresentativeName}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectIsolvedRepValidation}</div>
                      </div>
                    </div>
                  </div>
                </div>


                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {/* Responsible Party */}
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="d-flex">
                      <h6 className="form-title">Responsible Party</h6>
                      <span className="text-danger  ml-2">*</span>
                    </div>
                    {onEditAI ? (
                      <FormControl label="Select responsible-parties-list" focused={false} variant="outlined" className="w-100">
                        <Select className="text-dropdown" value={responsibleParty ?? ""} onChange={e => setResponsibleParty(e.target.value)}>
                          {responsiblePartiesList &&
                            responsiblePartiesList?.map((c, ci) => (
                              <MenuItem key={`responsible-parties-list-${ci}`} value={c?.Id}>
                                {c?.Name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <div className="textfield-div">{actionItem?.ResponsibleParty}</div>
                    )}
                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                      {selectResponsiblePartyValidation}
                    </div>
                  </Grid>
                  {/* Contact Name */}
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="d-flex">
                      <h6 className="form-title">Contact Name</h6>
                      <span className="text-danger  ml-2">*</span>
                    </div>
                    {onEditAI ? (
                      responsibleParty === 2 ? (
                        <Autocomplete
                          options={contactsList}
                          autoHighlight
                          getOptionLabel={option => `${option.FirstName} ${option.LastName}`}
                          onChange={(e, newValue) => {
                            setContact(newValue)
                          }}
                          value={contact ?? {
                            Email: "",
                            FirstName: "",
                            Id: null,
                            LastName: "",
                          }}
                          getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                          renderOption={option => <React.Fragment>{`${option.FirstName} ${option.LastName}`}</React.Fragment>}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          options={contactsList?.sort((s1, s2) => {
                            return s2.IsEnabled - s1.IsEnabled;
                          })}
                          groupBy={(option) => option.IsEnabled}
                          renderGroup={(params) => (
                            <li key={params.key}>
                              <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                          autoHighlight
                          getOptionLabel={option => `${option.FirstName} ${option.LastName}`}
                          onChange={(e, newValue) => {
                            setContact(newValue)
                          }}
                          value={contact ?? {
                            Email: "",
                            FirstName: "",
                            Id: null,
                            LastName: "",
                          }}
                          getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                          renderOption={option => <React.Fragment>{`${option.FirstName} ${option.LastName}`}</React.Fragment>}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      )
                    ) : (
                      <div className="textfield-div">{actionItem?.ResponsiblePartyID === 2 ? actionItem?.CarrierContactFullName : actionItem?.ContactFullName}</div>
                    )}
                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                      {selectContactValidation}
                    </div>
                  </Grid>
                  {/* Contact Email */}
                  <Grid item xs={12} sm={12} md={6}>
                    <h6 className="form-title">Contact Email</h6>
                    {onEditAI ? <div className="textfield-div">{contact?.Email}</div> : <div className="textfield-div">{actionItem?.ResponsiblePartyID === 2 ? actionItem?.CarrierContactEmail : actionItem?.ContactEmail}</div>}
                  </Grid>
                  {/* Action Item Title */}
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="d-flex">
                      <h6 className="form-title">Action Item Title</h6>
                      <span className="text-danger ml-2">*</span>
                    </div>

                    {onEditAI ? (
                      <input
                        type="text"
                        className="text-input w-100"
                        value={actionItemText?.substring(0, 60)}
                        onKeyDown={handleLimitedTitleCharRestriction}
                        onChange={e => {
                          setActionItemText(e.target.value)
                        }}
                      />
                    ) : (
                      <div className="textfield-div">{actionItem?.ActionItemText}</div>
                    )}

                    <div className="mt-2 text-danger">{limitedTitleCharMsg}</div>
                    <div className="mt-2 text-danger">{selectActionItemTextValidation}</div>

                  </Grid>
                  {/* Description */}
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="d-flex">
                      <h6 className="form-title">Description</h6>
                      <span className="text-danger  ml-2">*</span>
                    </div>
                    {onEditAI ? (
                      <textarea
                        type="text"
                        className="action-item-textarea w-100"
                        value={actionItemDescription?.replaceAll("<br/>", "\n")}
                        onChange={e => {
                          setActionItemDescription(e.target.value)
                        }}
                        rows={4}
                      />
                    ) : (
                      <div className="textfield-div">
                        <span style={{ whiteSpace: "break-spaces" }}>{actionItem?.Description?.replaceAll("<br/>", "\n")}</span>
                      </div>
                    )}

                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                      {selectActionItemDescriptionValidation}
                    </div>
                  </Grid>
                </Grid>
                <div className="row mt-3">
                  <Accordion className="exchangeAccordionEdit col-12 ">
                    <AccordionSummary
                      style={{ padding: 0 }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="remove-border-and-bg"
                      onClick={() => {
                        setShowMoreExpanded(!showMoreExpanded)
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          margin: "auto",
                          color: "var(--blue-main-color)",
                          fontWeight: "600",
                          textDecoration: "underline",
                        }}
                      >
                        {showMoreExpanded === false ? "Show more " : "Show less"}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        {/* Connectivity Request ID */}
                        <Grid item xs={12} sm={12} md={6}>
                          <h6 className="form-title">Connectivity Request ID</h6>
                          <div className="textfield-div">
                            {connectivityRequestIDURL}
                            <a href={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`} className="ml-auto alignvh-center color blue-main-color">
                              {/* <ExitToAppIcon /> */}
                              <svg width="20" height="21" viewBox="0 0 25 21">
                                <use xlinkHref={`${goToIcon}#right-to-bracket`}></use>
                              </svg>

                              <span className="ml-1 fw-500">Go</span>
                            </a>
                          </div>
                        </Grid>
                        {/* Start Date */}
                        <Grid item xs={12} sm={12} md={6}>
                          <h6 className="form-title">Start Date</h6>
                          <div className="textfield-div">
                            {actionItem?.Created ? Moment(actionItem?.Created).format("MM/DD/YYYY") : null}
                            <div className="ml-auto">
                              <svg width="14" height="16" viewBox="0 0 14 16">
                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                              </svg>
                            </div>
                          </div>
                        </Grid>
                        {/* Due Date */}
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-flex">
                            <h6 className="form-title">Due Date</h6>
                            <span className="text-danger  ml-2">*</span>
                          </div>
                          {onEditAI ? (
                            <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  className="w-100"
                                  autoOk={true}
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="frequency-date"
                                  value={dueDate === null ? dueDate : parseISO(dueDate)}
                                  onChange={handleDueDateChange}
                                  // minDate={todayDate.setDate(todayDate.getDate())}
                                  keyboardIcon={
                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                      <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                    </svg>
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          ) : (
                            <div className="textfield-div">
                              {actionItem?.DueDate ? Moment(actionItem?.DueDate).format("MM/DD/YYYY") : null}
                              <div className="ml-auto">
                                <svg width="14" height="16" viewBox="0 0 14 16">
                                  <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                </svg>
                              </div>
                            </div>
                          )}
                          <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                            {selectDueDateValidation}
                          </div>

                        </Grid>
                        {/* Elapsed Time */}
                        <Grid item xs={12} sm={12} md={6}>
                          <h6 className="form-title">Elapsed Time</h6>
                          <div className="textfield-div d-flex">
                            {actionItem?.ElapsedTime}
                            {actionItem?.Status !== 2 && <span className="ml-auto text-danger fs-16 fw-500">{elapsedTimeDifferenceWarning}</span>}
                          </div>
                        </Grid>
                        {/* Last Modified By */}
                        <Grid item xs={12} sm={12} md={6}>
                          <h6 className="form-title">Last Modified By</h6>
                          <div className="textfield-div">{actionItem?.LastUpdatedBy}</div>
                        </Grid>
                        {/* Last Modified Date */}
                        <Grid item xs={12} sm={12} md={6}>
                          <h6 className="form-title">Last Modified Date</h6>
                          <div className="textfield-div">
                            {actionItem?.Updated ? Moment(actionItem?.Updated).format("MM/DD/YYYY") : null}
                            <div className="ml-auto">
                              <svg width="14" height="16" viewBox="0 0 14 16">
                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                              </svg>
                            </div>
                          </div>
                        </Grid>
                        {/* Requested By */}
                        <Grid item xs={12} sm={12} md={6}>
                          <h6 className="form-title">Requested By</h6>
                          <div className="textfield-div">{actionItem?.RequestedBy}</div>
                        </Grid>
                        {/* Status */}
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-flex">
                            <h6 className="form-title">Status</h6>
                            <span className="text-danger ml-2">*</span>
                          </div>
                          {onEditAI ? (
                            <FormControl label="Select statuses list" focused={false} variant="outlined" className="w-100">
                              <Select className="text-dropdown" value={status ?? ""} onChange={e => setStatus(e.target.value)}>
                                {statusesList && statusesList?.map((c, ci) => (
                                  <MenuItem key={`statuses-list-${ci}`} value={c?.Id}>
                                    {c?.Name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="textfield-div">{actionItem?.ActionItemStatus}</div>
                          )}
                          <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                            {selectStatusValidation}
                          </div>
                        </Grid>
                        {/* Primary Analyst */}
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-flex">
                            <h6 className="form-title">Primary Analyst</h6>
                            {((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                              || (responsibleParty === 3 && !(isSuperAdminOrAnalyst()))) && <span className="text-danger ml-2">*</span>}
                          </div>
                          {(onEditAI) ? (
                            <FormControl label="Select assigned to" focused={false} variant="outlined" className="w-100">
                              <Select
                                className="text-dropdown"
                                value={primaryAnalyst ?? ""}
                                onChange={e => setPrimaryAnalyst(e.target.value)}
                              >
                                <MenuItem value="">None</MenuItem>
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeaderSelect>Enabled</GroupHeaderSelect></ListSubheader>
                                {contactsAnalystsList && contactsAnalystsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                  <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeaderSelect>Disabled</GroupHeaderSelect></ListSubheader>
                                {contactsAnalystsList && contactsAnalystsList?.filter(s => s.IsEnabled !== 1)?.map((c, ci) => (
                                  <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="textfield-div">
                              {actionItem?.PrimaryAnalystName}
                            </div>
                          )}
                          <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                            {selectPrimaryAnalystValidation}
                          </div>
                        </Grid>
                        {/* isolved Representative */}
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-flex">
                            <h6 className="form-title">isolved Representative</h6>
                            {((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                              || (responsibleParty === 3 && !(isSuperAdminOrAnalyst()))) && <span className="text-danger  ml-2">*</span>}
                          </div>
                          {onEditAI ? (
                            <FormControl label="Select isolved representative" focused={false} variant="outlined" className="w-100">
                              <Select
                                className="text-dropdown"
                                value={isolvedRepresentative ?? ""}
                                onChange={e => setIsolvedRepresentative(e.target.value)}
                              >
                                <MenuItem value="">None</MenuItem>
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeaderSelect>Enabled</GroupHeaderSelect></ListSubheader>
                                {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                  <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeaderSelect>Disbled</GroupHeaderSelect></ListSubheader>
                                {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled !== 1)?.map((c, ci) => (
                                  <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="textfield-div">
                              {actionItem?.IsolvedRepresentativeName}
                            </div>
                          )}
                          <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectIsolvedRepValidation}</div>

                        </Grid>
                        {/* Closure Reason */}
                        {actionItem?.Status === 2 && !onEditAI && (
                          <>
                            <Grid item xs={12} sm={12} md={6}>
                              <div className="d-flex">
                                <h6 className="form-title">Closure Reason</h6>
                                <span className="text-danger  ml-2">*</span>
                              </div>
                              <div className="textfield-div">
                                <span style={{ whiteSpace: "break-spaces" }}>{actionItem?.CloseReason}</span>
                              </div>
                            </Grid>
                          </>
                        )}
                        {/* Closure Reason */}
                        {status === 2 && onEditAI && (
                          <>
                            <Grid item xs={12} sm={12} md={6}>
                              <div className="d-flex">
                                <h6 className="form-title">Closure Reason</h6>
                                <span className="text-danger  ml-2">*</span>
                              </div>
                              <textarea
                                type="text"
                                className="action-item-textarea w-100"
                                value={actionItemClosureReason}
                                onChange={e => {
                                  setActionItemClosureReason(e.target.value)
                                }}
                                rows={4}
                              />
                              <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                                {selectClosureReasonValidation}
                              </div>
                            </Grid>
                          </>
                        )}

                        {actionItem?.Status === 2 && !onEditAI && (
                          <>
                            <Grid item xs={12} sm={12} md={6}>
                              <div className="d-flex">
                                <h6 className="form-title">Completion Date</h6>
                                <span className="text-danger  ml-2">*</span>
                              </div>
                              <div className="textfield-div">
                                {actionItem?.CompletionDate ? Moment(actionItem?.CompletionDate).format("MM/DD/YYYY") : null}
                                <div className="ml-auto">
                                  <svg width="14" height="16" viewBox="0 0 14 16">
                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                  </svg>
                                </div>
                              </div>
                            </Grid>
                          </>
                        )}

                        {status === 2 && onEditAI && (
                          <>
                            <Grid item xs={12} sm={12} md={6}>
                              <div className="d-flex">
                                <h6 className="form-title">Completion Date</h6>
                                <span className="text-danger  ml-2">*</span>
                              </div>
                              <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    className="w-100"
                                    autoOk={true}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="frequency-date"
                                    value={completionDate === null ? completionDate : parseISO(completionDate)}
                                    onChange={handleCompletionDateChange}
                                    keyboardIcon={
                                      <svg width="14" height="16" viewBox="0 0 14 16">
                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                      </svg>
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </div>

                              <div className="mt-3 text-danger" hidden={!requestSubmitted}>
                                {selectCompletionDateValidation}
                              </div>
                            </Grid>
                          </>
                        )}
                        {/* Attachment */}
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-flex">
                            <h6 className="form-title">Attachment</h6>
                          </div>
                          <div className="mt-3 container-div p-3" style={{ position: "relative" }}>
                            {onEditAI &&
                              <DropzoneArea
                                maxFileSize={20 * 1024 * 1024}
                                // add max size 20mb                       
                                dropzoneParagraphClass={"MuiDropzoneArea-text-updated-gray"}
                                filesLimit={1000}
                                dropzoneText={"Add Attachment"}
                                dropzoneClass={["containerClassDrag", "mb-3 "]}
                                onDrop={(files) => handleFileAttachedChangeDrag(files)}
                                showPreviewsInDropzone={false}
                                showAlerts={false}
                              />
                            }
                            {(fileAttachedList?.length <= 0 && uploadedFileAttachedList?.length <= 0 && (actionItem?.Attachment === "." || actionItem?.Attachment === null)) ? (
                              <div className="forms-list text-center mt-3 w-100">
                                <h6 className="color grey2">No attachment were added</h6>
                              </div>
                            ) : (
                              <div className="forms-list">
                                <div className="row table-header">
                                  <div className="col-9 table-cell first-cell">Attachment</div>

                                  <div className="col-3 table-cell">Actions</div>
                                </div>
                                <div className="row table-row ">
                                  {actionItem?.Attachment !== "." && actionItem?.Attachment !== null &&
                                    <>
                                      <div className="col-9 table-cell first-cell">
                                        {actionItem?.Attachment}
                                      </div>
                                      <div className="col-3 table-cell alignvh-center last-cell" style={{ justifyContent: "center" }}>
                                        <a href={actionItem?.AttachmentUrl}>
                                          <GetAppIcon color="action" style={{ fontSize: "30px", cursor: "pointer" }} />
                                        </a>
                                        <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={handelDeleteSingleFile} style={{ cursor: "pointer" }}>
                                          <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                        </svg>
                                      </div>
                                    </>
                                  }
                                  {fileAttachedList && fileAttachedList?.map((f, fi) => (
                                    <div className="row w-100" key={`files-attached-list-${fi}`}>
                                      <UploadedFileAI uploadedFile={f} key={`fileAttached-${fi}`} actionItem={actionItem} contact={contact} />
                                    </div>
                                  ))}


                                  {uploadedFileAttachedList && uploadedFileAttachedList?.map((f, fi) => (
                                    <div className="row w-100" key={`uploaded-files-attachments-${fi}`}>
                                      <div className="col-9 table-cell first-cell">
                                        {f?.Name}
                                      </div>

                                      <div className="col-3 table-cell ">
                                        {/* <DeleteForeverIcon className=" mx-auto" color="action" style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => handelDeleteFile(f)} /> */}
                                        <svg width="21" height="24" viewBox="0 0 21 24" className="mx-auto" onClick={() => { handelDeleteFile(f) }} style={{ cursor: "pointer" }}>
                                          <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                        </svg>
                                      </div>
                                    </div>
                                  )
                                  )}
                                </div>
                              </div>
                            )}

                          </div>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {onEditAI && (
                  <div className="w-100 mt-5 text-right">
                    <Button
                      type="submit"
                      variant="contained"
                      className="blue-btn"
                      onClick={() => {
                        setRequestSubmitted(true)
                      }}
                    >
                      Submit Action Item
                    </Button>
                  </div>
                )}
                {/* ///========================================= */}
              </AccordionDetails>
            </Accordion>
          </div>


          <AnalystConnectivityRequestAIComments actionItem={actionItem} setActionItem={setActionItem} aiComments={aiComments} setAIComments={setAIComments} parentForceUpdate={forceUpdate} />



        </form>
      </Paper>


    </div>
  )
}

export default AnalystConnectivityRequestViewActionItem
