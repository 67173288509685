import React from "react";
import * as APIs from "../../Data/APIs";
import Moment from "moment";
import { useAuthentication } from "../../Data/Authentication";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { useLoader } from "../Layout/Loader";
import ViewOERequestsList from "./ViewOERequestsList";
import OERequestsStatistics from "./OERequestsStatistics";
const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0);
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0);
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function OERequestsDashboard() {
  // style

  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 4,
      padding: "14px 12px",
    },
  }))(InputBase);
  const { authUser } = useAuthentication();
  const userActions = authUser?.signInUserSession?.idToken?.payload[
    "userActions"
  ]
    ?.slice(0, -1)
    .split(",");
  const tableRef = React.useRef();
  const { openLoader, closeLoader } = useLoader();
  const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
  const [oERequestsList, setOERequestsList] = React.useState([]);
  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [advancedFiltersClicked, setAdvancedFiltersClicked] =
    React.useState(false);
  const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] =
    React.useState(false);
  const [valueStatus, setValueStatus] = React.useState(0);

  // normal filter

  const [connectivityRequestIDFilter, setConnectivityRequestIDFilter] =
    React.useState("");
  const [oERequestIDFilter, setOERequestIDFilter] = React.useState("");
  const [
    selectedPlanYearStartFromDateFilter,
    setSelectedPlanYearStartFromDateFilter,
  ] = React.useState(null);
  const [
    selectedPlanYearStartToDateFilter,
    setSelectedPlanYearStartToDateFilter,
  ] = React.useState(null);
  const [planYearStartFromDateFilter, setPlanYearStartFromDateFilter] =
    React.useState(null);
  const [planYearStartToDateFilter, setPlanYearStartToDateFilter] =
    React.useState(null);
  const [selectedExpectedFromDateFilter, setSelectedExpectedFromDateFilter] =
    React.useState(null);
  const [selectedExpectedToDateFilter, setSelectedExpectedToDateFilter] =
    React.useState(null);
  const [selectedCreatedFromDateFilter, setSelectedCreatedFromDateFilter] =
    React.useState(null);
  const [selectedCreatedToDateFilter, setSelectedCreatedToDateFilter] =
    React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState();
  const [phaseFilter, setPhaseFilter] = React.useState();
  const [oeTypeFilter, setOETypeFilter] = React.useState();
  const [assignedTo, setAssignedTo] = React.useState("");
  const [assignedToAutoValue, setAssignedToAutoValue] = React.useState("");
  const [iSolvedContact, setISolvedContact] = React.useState("");
  const [iSolvedContactAutoValue, setISolvedContactAutoValue] =
    React.useState("");
  const [updatedGS, setUpdatedGS] = React.useState("");
  const [draft, setDraft] = React.useState("");
  const [clientsList, setClientsList] = React.useState([]);
  const [peosList, setPEOsList] = React.useState([]);
  const [carriersList, setCarriersList] = React.useState([]);
  const [clientName, setClientName] = React.useState();
  const [clientNameAutoValue, setClientNameAutoValue] = React.useState("");
  const [peoName, setPEOName] = React.useState();
  const [peoNameAutoValue, setPEONameAutoValue] = React.useState("");
  const [carName, setCarName] = React.useState();
  const [carNameAutoValue, setCarNameAutoValue] = React.useState("");
  const [clientSelectedFilter, setClientSelectedFilter] = React.useState("");
  const [peoSelectedFilter, setPEOSelectedFilter] = React.useState("");
  const [carrierSelectedFilter, setCarrierSelectedFilter] = React.useState("");
  const [selectedOEFileSubmissionFromDateFilter, setSelectedOEFileSubmissionFromDateFilter] = React.useState(null);
  const [selectedOEFileSubmissionToDateFilter, setSelectedOEFileSubmissionToDateFilter] = React.useState(null);
  const [requestType, setRequestType] = React.useState();

  // advanced filter
  const [connectivityRequestIDAdvFilter, setConnectivityRequestIDAdvFilter] =
    React.useState("");
  const [oERequestIDAdvFilter, setOERequestIDAdvFilter] = React.useState("");
  const [
    selectedPlanYearStartFromDateAdvFilter,
    setSelectedPlanYearStartFromDateAdvFilter,
  ] = React.useState(null);
  const [
    selectedPlanYearStartToDateAdvFilter,
    setSelectedPlanYearStartToDateAdvFilter,
  ] = React.useState(null);
  const [
    selectedExpectedFromDateAdvFilter,
    setSelectedExpectedFromDateAdvFilter,
  ] = React.useState(null);
  const [selectedExpectedToDateAdvFilter, setSelectedExpectedToDateAdvFilter] =
    React.useState(null);
  const [
    selectedCreatedFromDateAdvFilter,
    setSelectedCreatedFromDateAdvFilter,
  ] = React.useState(null);
  const [selectedCreatedToDateAdvFilter, setSelectedCreatedToDateAdvFilter] =
    React.useState(null);
  const [statusAdvFilter, setStatusAdvFilter] = React.useState();
  const [phaseAdvFilter, setPhaseAdvFilter] = React.useState();
  const [oeTypeAdvFilter, setOETypeAdvFilter] = React.useState();
  const [assignedToAdvFilter, setAssignedToAdvFilter] = React.useState("");
  const [iSolvedContactAdvFilter, setISolvedContactAdvFilter] =
    React.useState("");
  const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
  const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");
  const [sortOrderFilter, setSortOrderFilter] = React.useState("");
  const [sortFieldFilter, setSortFieldFilter] = React.useState("");
  const [selectedAssignedToFilter, setSelectedAssignedToFilter] =
    React.useState([]);
  const [selectedISolvedContactFilter, setSelectedISolvedContactFilter] =
    React.useState([]);
  const [selectedStatusesFilter, setSelectedStatusesFilter] = React.useState(
    []
  );
  const [selectedPhasesFilter, setSelectedPhasesFilter] = React.useState([]);
  const [selectedOETypesFilter, setSelectedOETypesFilter] = React.useState([]);
  const [updatedGSAdvFilter, setUpdatedGSAdvFilter] = React.useState("");
  const [draftAdvFilter, setDraftAdvFilter] = React.useState("");
  const [selectedClientsFilter, setSelectedClientsFilter] = React.useState([]);
  const [selectedPEOsFilter, setSelectedPEOsFilter] = React.useState([]);
  const [selectedCarriersFilter, setSelectedCarriersFilter] = React.useState(
    []
  );
  const [
    selectedOEFileSubmissionFromDateAdvFilter,
    setSelectedOEFileSubmissionFromDateAdvFilter,
  ] = React.useState(null);
  const [
    selectedOEFileSubmissionToDateAdvFilter,
    setSelectedOEFileSubmissionToDateAdvFilter,
  ] = React.useState(null);
  const [requestTypeAdvFilter, setRequestTypeAdvFilter] = React.useState([]);
  //  page size and selected and count
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(30);
  const [totalCount, setTotalCount] = React.useState(0);
  const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
  const [pageSizeAdv, setPageSizeAdv] = React.useState(30);

  const [statuses, setStatuses] = React.useState([]);

  // gated Data
  const [statusesList, setStatusesList] = React.useState([]);
  const [phasesList, setPhasesList] = React.useState([]);
  const [requestTypes, setRequestTypes] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState(
    []
  );
  const [contactsList, setContactsList] = React.useState([]);

  // static
  const [dashboardOEStatus, setDashboardOEStatus] = React.useState([]);
  const [dashboardOEPhases, setDashboardOEPhases] = React.useState([]);
  const [onPhaseDashboardChange, setOnPhaseDashboardChange] =
    React.useState(false);
  const [onStatusDashboardChange, setOnStatusDashboardChange] =
    React.useState(false);
  const [statusesFilterCard, setStatusesFilterCard] = React.useState([]);
  const [filterCard, setFilterCard] = React.useState(false);

  // get Data
  React.useEffect(() => {
    APIs.getOERequestStatusesList().then((r) =>
      !r?.data?.length ? setStatusesList([]) : setStatusesList(r?.data)
    );
  }, []);

  React.useEffect(() => {
    APIs.getOERequestPhasesList().then((r) =>
      !r?.data?.length ? setPhasesList([]) : setPhasesList(r?.data)
    );
  }, []);

  React.useEffect(() => {
    APIs.getOERequestTypes().then((r) =>
      !r?.data?.length ? setRequestTypes([]) : setRequestTypes(r?.data)
    );
  }, []);

  React.useEffect(() => {
    openLoader();
    APIs.getAllGroupsAndOEContacts().then(
      (r) => (
        !r?.data?.length
          ? setGroupsAndOEContactsList([])
          : setGroupsAndOEContactsList(r?.data),
        closeLoader()
      )
    );
  }, []);

  React.useEffect(() => {
    const contactsBody = {
      ResponsiblePartyID: 3,
    };
    APIs.getContactsList(contactsBody).then((r) => {
      setContactsList(r?.data ?? []);
    });
  }, []);

  React.useEffect(() => {
    openLoader();
    APIs.getAllUsers().then((r) => (setUsersList(r?.data), closeLoader()));
  }, []);

  // handel get oe data
  const getOERequestsQuickData = () => {
    openLoader();
    const requestParameters = {};
    if (selectedPage >= 1) {
      requestParameters.page = selectedPage;
      requestParameters.limit = pageSize;
    }
    if (pageSize >= 10) {
      requestParameters.limit = pageSize;
      requestParameters.page = selectedPage;
    }
    if (statusFilter !== "" || statusFilter !== undefined)
      requestParameters.StatusIDsArray =
        statusFilter === undefined || !statusFilter ? "" : [statusFilter];
    if (connectivityRequestIDFilter !== "")
      requestParameters.ConnectivityRequestID = connectivityRequestIDFilter;
    if (phaseFilter !== "" || phaseFilter !== undefined)
      requestParameters.PhaseIDsArray =
        phaseFilter === undefined || !phaseFilter ? "" : [phaseFilter];
    if (oERequestIDFilter !== "")
      requestParameters.OERequestID = parseInt(oERequestIDFilter);
    if (assignedTo !== "")
      requestParameters.AssignedToCognitoIDsArray =
        assignedTo === undefined ? "" : [assignedTo?.CognitoID];
    if (iSolvedContact !== "")
      requestParameters.IsolvedContactCognitoIDsArray =
        iSolvedContact === undefined ? "" : [iSolvedContact?.CognitoID];
    if (selectedPlanYearStartFromDateFilter !== null)
      requestParameters.PlanYearStartDateFrom =
        selectedPlanYearStartFromDateFilter
          ? Moment(selectedPlanYearStartFromDateFilter).format("YYYY-MM-DD")
          : null;
    if (selectedPlanYearStartToDateFilter !== null)
      requestParameters.PlanYearStartDateTo = selectedPlanYearStartToDateFilter
        ? Moment(selectedPlanYearStartToDateFilter).format("YYYY-MM-DD")
        : null;
    if (selectedExpectedFromDateFilter !== null)
      requestParameters.ClientDataExpectedDateFrom =
        selectedExpectedFromDateFilter
          ? Moment(selectedExpectedFromDateFilter).format("YYYY-MM-DD")
          : null;
    if (selectedExpectedToDateFilter !== null)
      requestParameters.ClientDataExpectedDateTo = selectedExpectedToDateFilter
        ? Moment(selectedExpectedToDateFilter).format("YYYY-MM-DD")
        : null;
    if (selectedCreatedFromDateFilter !== null)
      requestParameters.CreatedDateFrom = selectedCreatedFromDateFilter
        ? Moment(selectedCreatedFromDateFilter).format("YYYY-MM-DD")
        : null;
    if (selectedCreatedToDateFilter !== null)
      requestParameters.CreatedDateTo = selectedCreatedToDateFilter
        ? Moment(selectedCreatedToDateFilter).format("YYYY-MM-DD")
        : null;
    if (oeTypeFilter !== "")
      requestParameters.OETypesArray =
        oeTypeFilter === undefined || !oeTypeFilter ? "" : [oeTypeFilter];
    if (updatedGS !== "")
      requestParameters.UpdatedGroupStructure = parseInt(updatedGS);
    if (requestType)
      requestParameters.RequestTypesIDsArray = [parseInt(requestType)];
    if (draft !== "") requestParameters.IsDraftOERequest = parseInt(draft);
    if (sortFieldFilter !== "") requestParameters.sortField = sortFieldFilter;
    if (statusesFilterCard.length > 0)
      requestParameters.StatusIDsArray = statusesFilterCard;
    if (sortOrderFilter !== "") requestParameters.sortOrder = sortOrderFilter;
    if (clientSelectedFilter !== "")
      requestParameters.GroupIDsArray = [clientSelectedFilter.GroupID];
    if (peoSelectedFilter !== "")
      requestParameters.PeoIDsArray = [peoSelectedFilter.PeoId];
    if (carrierSelectedFilter !== "")
      requestParameters.CarrierIDsArray = [carrierSelectedFilter.CarrierID];
    if (selectedOEFileSubmissionFromDateFilter !== null)
      requestParameters.OEFileSubmissionDateFrom =
        selectedOEFileSubmissionFromDateFilter
          ? Moment(selectedOEFileSubmissionFromDateFilter).format("YYYY-MM-DD")
          : null;
    if (selectedOEFileSubmissionToDateFilter !== null)
      requestParameters.OEFileSubmissionDateTo =
        selectedOEFileSubmissionToDateFilter
          ? Moment(selectedOEFileSubmissionToDateFilter).format("YYYY-MM-DD")
          : null;

    APIs.getOERequestsList(requestParameters).then(
      (r) => (
        !(r?.data?.length) ? setOERequestsList([]) : setOERequestsList(r?.data),
        setRefreshClicked(false),
        setFilterCard(false),
        closeLoader(),
        setTotalCount(r?.totalCount ?? 0)
      )
    );
  };

  // handel get oe data Advanced
  const getOERequestsAdvancedData = () => {
    openLoader();
    const requestParameters = {};
    if (selectedPageAdv >= 1) {
      requestParameters.page = selectedPageAdv;
      requestParameters.limit = pageSizeAdv;
    }
    if (pageSizeAdv >= 10) {
      requestParameters.limit = pageSizeAdv;
      requestParameters.page = selectedPageAdv;
    }
    if (selectedStatusesFilter.length > 0)
      requestParameters.StatusIDsArray = selectedStatusesFilter?.map(
        (s) => s?.Id
      );

    if (selectedPhasesFilter.length > 0)
      requestParameters.PhaseIDsArray = selectedPhasesFilter?.map((s) => s?.Id);
    if (connectivityRequestIDAdvFilter !== "")
      requestParameters.ConnectivityRequestID = connectivityRequestIDAdvFilter;

    if (oERequestIDAdvFilter !== "")
      requestParameters.OERequestID = parseInt(oERequestIDAdvFilter);

    if (selectedAssignedToFilter.length > 0)
      requestParameters.AssignedToCognitoIDsArray =
        selectedAssignedToFilter?.map((s) => s?.CognitoID);
    if (selectedISolvedContactFilter.length > 0)
      requestParameters.IsolvedContactCognitoIDsArray =
        selectedISolvedContactFilter?.map((s) => s?.CognitoID);
    if (selectedPlanYearStartFromDateAdvFilter !== null)
      requestParameters.PlanYearStartDateFrom =
        selectedPlanYearStartFromDateAdvFilter
          ? Moment(selectedPlanYearStartFromDateAdvFilter).format("YYYY-MM-DD")
          : null;
    if (selectedPlanYearStartToDateAdvFilter !== null)
      requestParameters.PlanYearStartDateTo =
        selectedPlanYearStartToDateAdvFilter
          ? Moment(selectedPlanYearStartToDateAdvFilter).format("YYYY-MM-DD")
          : null;
    if (selectedExpectedFromDateAdvFilter !== null)
      requestParameters.ClientDataExpectedDateFrom =
        selectedExpectedFromDateAdvFilter
          ? Moment(selectedExpectedFromDateAdvFilter).format("YYYY-MM-DD")
          : null;
    if (selectedExpectedToDateAdvFilter !== null)
      requestParameters.ClientDataExpectedDateTo =
        selectedExpectedToDateAdvFilter
          ? Moment(selectedExpectedToDateAdvFilter).format("YYYY-MM-DD")
          : null;
    if (selectedCreatedFromDateAdvFilter !== null)
      requestParameters.CreatedDateFrom = selectedCreatedFromDateAdvFilter
        ? Moment(selectedCreatedFromDateAdvFilter).format("YYYY-MM-DD")
        : null;
    if (selectedCreatedToDateAdvFilter !== null)
      requestParameters.CreatedDateTo = selectedCreatedToDateAdvFilter
        ? Moment(selectedCreatedToDateAdvFilter).format("YYYY-MM-DD")
        : null;
    if (selectedOETypesFilter.length > 0)
      requestParameters.OETypesArray = selectedOETypesFilter;
    if (updatedGSAdvFilter !== "")
      requestParameters.UpdatedGroupStructure = parseInt(updatedGSAdvFilter);
    if (requestTypeAdvFilter.length > 0)
      requestParameters.RequestTypesIDsArray = [requestTypeAdvFilter] ?? [];
    if (draftAdvFilter !== "")
      requestParameters.IsDraftOERequest = parseInt(draftAdvFilter);
    if (sortFieldAdvFilter !== "")
      requestParameters.sortField = sortFieldAdvFilter;
    if (sortOrderAdvFilter !== "")
      requestParameters.sortOrder = sortOrderAdvFilter;
    if (selectedClientsFilter.length > 0)
      requestParameters.GroupIDsArray = selectedClientsFilter?.map(
        (s) => s?.GroupID
      );
    if (selectedPEOsFilter.length > 0)
      requestParameters.PeoIDsArray = selectedPEOsFilter?.map((s) => s?.PeoId);
    if (selectedCarriersFilter.length > 0)
      requestParameters.CarrierIDsArray = selectedCarriersFilter?.map(
        (s) => s?.CarrierID
      );
    if (selectedOEFileSubmissionFromDateAdvFilter !== null)
      requestParameters.OEFileSubmissionDateFrom =
        selectedOEFileSubmissionFromDateAdvFilter
          ? Moment(selectedOEFileSubmissionFromDateAdvFilter).format(
            "YYYY-MM-DD"
          )
          : null;
    if (selectedOEFileSubmissionToDateAdvFilter !== null)
      requestParameters.OEFileSubmissionDateTo =
        selectedOEFileSubmissionToDateAdvFilter
          ? Moment(selectedOEFileSubmissionToDateAdvFilter).format("YYYY-MM-DD")
          : null;

    APIs.getOERequestsList(requestParameters).then(
      (r) => (
        !(r?.data?.length) ? setOERequestsList([]) : setOERequestsList(r?.data),
        setAdvancedFiltersAppliedClicked(false),
        closeLoader(),
        setRefreshClicked(false),
        setTotalCount(r?.totalCount ?? 0)
      )
    );
  };
  React.useEffect(() => {
    openLoader();
    APIs.getGroupsListByName({ GroupName: clientName ?? "" }).then((r) =>
      !r?.data?.length ? setClientsList([]) : setClientsList(r?.data)
    );
    APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) =>
      !r?.data?.length ? setPEOsList([]) : setPEOsList(r?.data)
    );
    APIs.getCarriersListByName({ CarrierName: carName ?? "" }).then((r) =>
      !r?.data?.length ? setCarriersList([]) : setCarriersList(r?.data)
    );
  }, []);

  React.useEffect(() => {
    if (quickFiltersClicked) getOERequestsQuickData();
  }, [
    selectedPage,
    pageSize,
    statusFilter,
    phaseFilter,
    oERequestIDFilter,
    updatedGS,
    draft,
    requestType,
    assignedTo,
    iSolvedContact,
    selectedPlanYearStartFromDateFilter,
    selectedPlanYearStartToDateFilter,
    selectedExpectedFromDateFilter,
    selectedExpectedToDateFilter,
    selectedCreatedFromDateFilter,
    selectedCreatedToDateFilter,
    oeTypeFilter,
    sortFieldFilter,
    sortOrderFilter,
    connectivityRequestIDFilter,
    clientName,
    peoName,
    carName,
    selectedOEFileSubmissionFromDateFilter,
    selectedOEFileSubmissionToDateFilter,
    refreshClicked,
  ]);

  React.useEffect(() => {
    getOERequestsQuickData();
  }, [quickFiltersClicked === true]);
  React.useEffect(() => {
    getOERequestsAdvancedData();
  }, [
    selectedPageAdv,
    pageSizeAdv,
    sortFieldAdvFilter,
    sortOrderAdvFilter,
    advancedFiltersAppliedClicked,
  ]);

  React.useEffect(() => {
    getOERequestsAdvancedData();
  }, [advancedFiltersClicked === true]);

  const getDashboardStatuseAndPhases = () => {
    openLoader();
    const requestParameters = {
      PlanYearStartDateFrom: planYearStartFromDateFilter
        ? Moment(planYearStartFromDateFilter).format("YYYY-MM-DD")
        : null,
      PlanYearStartDateTo: planYearStartToDateFilter
        ? Moment(planYearStartToDateFilter).format("YYYY-MM-DD")
        : null,
    };

    APIs.getOERequestsDashboardStats(requestParameters).then(
      (r) => (
        setDashboardOEStatus(r?.data?.StatusPercentage),
        setDashboardOEPhases(r?.data?.phasesPercentage),
        closeLoader()
      )
    );
  };

  React.useEffect(() => {
    getDashboardStatuseAndPhases();
  }, [refreshClicked]);
  React.useEffect(() => {
    getDashboardStatuseAndPhases();
  }, [planYearStartFromDateFilter, planYearStartToDateFilter]);
  return (
    <>
      <OERequestsStatistics
        dashboardOEStatus={dashboardOEStatus}
        dashboardOEPhases={dashboardOEPhases}
        onPhaseFilter={(s) => setPhaseFilter(s)}
        onStatusFilter={(s) => setStatusFilter(s)}
        tableRef={tableRef}
        onSetOnPhaseDashboardChange={(s) => setOnPhaseDashboardChange(s)}
        onSetOnStatusDashboardChange={(s) => setOnStatusDashboardChange(s)}
        onSetCreatedFromDateFilter={(d) => setSelectedCreatedFromDateFilter(d)}
        onSetCreatedToDateFilter={(d) => setSelectedCreatedToDateFilter(d)}
        onSetStatusesFilterCard={(s) => setStatusesFilterCard(s)}
        setQuickFiltersClicked={setQuickFiltersClicked}
        onSetFilterCard={(s) => setFilterCard(s)}
        planYearStartFromDateFilter={planYearStartFromDateFilter}
        setPlanYearStartFromDateFilter={setPlanYearStartFromDateFilter}
        planYearStartToDateFilter={planYearStartToDateFilter}
        setPlanYearStartToDateFilter={setPlanYearStartToDateFilter}
      />
      <ViewOERequestsList
        oERequestsList={oERequestsList}
        setOERequestsList={setOERequestsList}
        refreshClicked={refreshClicked}
        setRefreshClicked={setRefreshClicked}
        quickFiltersClicked={quickFiltersClicked}
        setQuickFiltersClicked={setQuickFiltersClicked}
        advancedFiltersClicked={advancedFiltersClicked}
        setAdvancedFiltersClicked={setAdvancedFiltersClicked}
        advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
        setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
        connectivityRequestIDFilter={connectivityRequestIDFilter}
        setConnectivityRequestIDFilter={setConnectivityRequestIDFilter}
        oERequestIDFilter={oERequestIDFilter}
        setOERequestIDFilter={setOERequestIDFilter}
        selectedPlanYearStartFromDateFilter={selectedPlanYearStartFromDateFilter}
        setSelectedPlanYearStartFromDateFilter={setSelectedPlanYearStartFromDateFilter}
        selectedPlanYearStartToDateFilter={selectedPlanYearStartToDateFilter}
        setSelectedPlanYearStartToDateFilter={setSelectedPlanYearStartToDateFilter}
        selectedExpectedFromDateFilter={selectedExpectedFromDateFilter}
        setSelectedExpectedFromDateFilter={setSelectedExpectedFromDateFilter}
        selectedExpectedToDateFilter={selectedExpectedToDateFilter}
        setSelectedExpectedToDateFilter={setSelectedExpectedToDateFilter}
        selectedCreatedFromDateFilter={selectedCreatedFromDateFilter}
        setSelectedCreatedFromDateFilter={setSelectedCreatedFromDateFilter}
        selectedCreatedToDateFilter={selectedCreatedToDateFilter}
        setSelectedCreatedToDateFilter={setSelectedCreatedToDateFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        phaseFilter={phaseFilter}
        setPhaseFilter={setPhaseFilter}
        oeTypeFilter={oeTypeFilter}
        setOETypeFilter={setOETypeFilter}
        assignedTo={assignedTo}
        setAssignedTo={setAssignedTo}
        assignedToAutoValue={assignedToAutoValue}
        setAssignedToAutoValue={setAssignedToAutoValue}
        iSolvedContact={iSolvedContact}
        setISolvedContact={setISolvedContact}
        iSolvedContactAutoValue={iSolvedContactAutoValue}
        setISolvedContactAutoValue={setISolvedContactAutoValue}
        updatedGS={updatedGS}
        setUpdatedGS={setUpdatedGS}
        draft={draft}
        setDraft={setDraft}
        requestType={requestType}
        setRequestType={setRequestType}
        clientsList={clientsList}
        setClientsList={setClientsList}
        peosList={peosList}
        setPEOsList={setPEOsList}
        carriersList={carriersList}
        setCarriersList={setCarriersList}
        clientName={clientName}
        setClientName={setClientName}
        clientNameAutoValue={clientNameAutoValue}
        setClientNameAutoValue={setClientNameAutoValue}
        peoName={peoName}
        setPEOName={setPEOName}
        peoNameAutoValue={peoNameAutoValue}
        setPEONameAutoValue={setPEONameAutoValue}
        carName={carName}
        setCarName={setCarName}
        carNameAutoValue={carNameAutoValue}
        setCarNameAutoValue={setCarNameAutoValue}
        clientSelectedFilter={clientSelectedFilter}
        setClientSelectedFilter={setClientSelectedFilter}
        peoSelectedFilter={peoSelectedFilter}
        setPEOSelectedFilter={setPEOSelectedFilter}
        carrierSelectedFilter={carrierSelectedFilter}
        setCarrierSelectedFilter={setCarrierSelectedFilter}
        selectedOEFileSubmissionFromDateFilter={selectedOEFileSubmissionFromDateFilter}
        setSelectedOEFileSubmissionFromDateFilter={setSelectedOEFileSubmissionFromDateFilter}
        selectedOEFileSubmissionToDateFilter={selectedOEFileSubmissionToDateFilter}
        setSelectedOEFileSubmissionToDateFilter={setSelectedOEFileSubmissionToDateFilter}
        connectivityRequestIDAdvFilter={connectivityRequestIDAdvFilter}
        setConnectivityRequestIDAdvFilter={setConnectivityRequestIDAdvFilter}
        oERequestIDAdvFilter={oERequestIDAdvFilter}
        setOERequestIDAdvFilter={setOERequestIDAdvFilter}
        selectedPlanYearStartFromDateAdvFilter={selectedPlanYearStartFromDateAdvFilter}
        setSelectedPlanYearStartFromDateAdvFilter={setSelectedPlanYearStartFromDateAdvFilter}
        selectedPlanYearStartToDateAdvFilter={selectedPlanYearStartToDateAdvFilter}
        setSelectedPlanYearStartToDateAdvFilter={setSelectedPlanYearStartToDateAdvFilter}
        selectedExpectedFromDateAdvFilter={selectedExpectedFromDateAdvFilter}
        setSelectedExpectedFromDateAdvFilter={setSelectedExpectedFromDateAdvFilter}
        selectedExpectedToDateAdvFilter={selectedExpectedToDateAdvFilter}
        setSelectedExpectedToDateAdvFilter={setSelectedExpectedToDateAdvFilter}
        selectedCreatedFromDateAdvFilter={selectedCreatedFromDateAdvFilter}
        setSelectedCreatedFromDateAdvFilter={setSelectedCreatedFromDateAdvFilter}
        selectedCreatedToDateAdvFilter={selectedCreatedToDateAdvFilter}
        setSelectedCreatedToDateAdvFilter={setSelectedCreatedToDateAdvFilter}
        statusAdvFilter={statusAdvFilter}
        setStatusAdvFilter={setStatusAdvFilter}
        phaseAdvFilter={phaseAdvFilter}
        setPhaseAdvFilter={setPhaseAdvFilter}
        oeTypeAdvFilter={oeTypeAdvFilter}
        setOETypeAdvFilter={setOETypeAdvFilter}
        assignedToAdvFilter={assignedToAdvFilter}
        setAssignedToAdvFilter={setAssignedToAdvFilter}
        iSolvedContactAdvFilter={iSolvedContactAdvFilter}
        setISolvedContactAdvFilter={setISolvedContactAdvFilter}
        sortFieldAdvFilter={sortFieldAdvFilter}
        setSortFieldAdvFilter={setSortFieldAdvFilter}
        sortOrderAdvFilter={sortOrderAdvFilter}
        setSortOrderAdvFilter={setSortOrderAdvFilter}
        sortOrderFilter={sortOrderFilter}
        setSortOrderFilter={setSortOrderFilter}
        sortFieldFilter={sortFieldFilter}
        setSortFieldFilter={setSortFieldFilter}
        selectedAssignedToFilter={selectedAssignedToFilter}
        setSelectedAssignedToFilter={setSelectedAssignedToFilter}
        selectedISolvedContactFilter={selectedISolvedContactFilter}
        setSelectedISolvedContactFilter={setSelectedISolvedContactFilter}
        selectedStatusesFilter={selectedStatusesFilter}
        setSelectedStatusesFilter={setSelectedStatusesFilter}
        selectedPhasesFilter={selectedPhasesFilter}
        setSelectedPhasesFilter={setSelectedPhasesFilter}
        selectedOETypesFilter={selectedOETypesFilter}
        setSelectedOETypesFilter={setSelectedOETypesFilter}
        updatedGSAdvFilter={updatedGSAdvFilter}
        setUpdatedGSAdvFilter={setUpdatedGSAdvFilter}
        draftAdvFilter={draftAdvFilter}
        setDraftAdvFilter={setDraftAdvFilter}
        requestTypeAdvFilter={requestTypeAdvFilter}
        setRequestTypeAdvFilter={setRequestTypeAdvFilter}
        selectedClientsFilter={selectedClientsFilter}
        setSelectedClientsFilter={setSelectedClientsFilter}
        selectedPEOsFilter={selectedPEOsFilter}
        setSelectedPEOsFilter={setSelectedPEOsFilter}
        selectedCarriersFilter={selectedCarriersFilter}
        setSelectedCarriersFilter={setSelectedCarriersFilter}
        selectedOEFileSubmissionFromDateAdvFilter={selectedOEFileSubmissionFromDateAdvFilter}
        setSelectedOEFileSubmissionFromDateAdvFilter={setSelectedOEFileSubmissionFromDateAdvFilter}
        selectedOEFileSubmissionToDateAdvFilter={selectedOEFileSubmissionToDateAdvFilter}
        setSelectedOEFileSubmissionToDateAdvFilter={setSelectedOEFileSubmissionToDateAdvFilter}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
        selectedPageAdv={selectedPageAdv}
        setSelectedPageAdv={setSelectedPageAdv}
        pageSizeAdv={pageSizeAdv}
        setPageSizeAdv={setPageSizeAdv}
        statuses={statuses}
        setStatuses={setStatuses}
        statusesList={statusesList}
        setStatusesList={setStatusesList}
        phasesList={phasesList}
        setPhasesList={setPhasesList}
        requestTypes={requestTypes}
        setRequestTypes={setRequestTypes}
        usersList={usersList}
        setUsersList={setUsersList}
        groupsAndOEContactsList={groupsAndOEContactsList}
        setGroupsAndOEContactsList={setGroupsAndOEContactsList}
        contactsList={contactsList}
        setContactsList={setContactsList}
        valueStatus={valueStatus}
        setValueStatus={setValueStatus}
        tableRef={tableRef}
        onPhaseDashboardChange={onPhaseDashboardChange}
        onStatusDashboardChange={onStatusDashboardChange}
        onSetOnPhaseDashboardChange={(s) => setOnPhaseDashboardChange(s)}
        onSetOnStatusDashboardChange={(s) => setOnStatusDashboardChange(s)}
        onSetStatusesFilterCard={(s) => setStatusesFilterCard(s)}
        statusesFilterCard={statusesFilterCard}
        onsetFilterCard={(s) => setFilterCard(s)}
        planYearStartFromDateFilter={planYearStartFromDateFilter}
        setPlanYearStartFromDateFilter={setPlanYearStartFromDateFilter}
        planYearStartToDateFilter={planYearStartToDateFilter}
        setPlanYearStartToDateFilter={setPlanYearStartToDateFilter}
      />
    </>
  );
}

export default OERequestsDashboard;
