import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";
import editRequestIcon from "../../../../Icons/editIcon.svg";
import CarrierTabsTechnicalInfoGenericFTPSingleItem from "./CarrierTabsTechnicalInfoGenericFTPSingleItem";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import { useParams } from "react-router-dom";
import { useAuthentication } from "../../../../Data/Authentication";

const singleFTPValidation = Yup.object().shape({
  CarrierGenericFTPTypeId: Yup.string().trim().required("This Field is Required"),
  CarrierGenericFTPProtocolTypeId: Yup.string().trim().required("This Field is Required"),
  Site: Yup.string().trim().required("This Field is Required"),
  Port: Yup.string().trim().required("This Field is Required"),
  Username: Yup.string().trim().required("This Field is Required"),
  CarrierSSHKeyId: Yup.string().when("CarrierGenericFTPProtocolTypeId", ([CarrierGenericFTPProtocolTypeId], schema) => {
    if (CarrierGenericFTPProtocolTypeId == "4") return Yup.string().required("This Field is Required");
    return schema.nullable();
  }),
  Password: Yup.string().when("CarrierGenericFTPProtocolTypeId", ([CarrierGenericFTPProtocolTypeId], schema) => {
    if (CarrierGenericFTPProtocolTypeId != "4") return Yup.string().required("This Field is Required");
    return schema.nullable();
  }),
  Directory: Yup.string().trim().required("This Field is Required")
});

const validationSchema = Yup.object().shape({
  CarrierGenericFTPs: Yup.array().of(singleFTPValidation)
});

const addOrEditObjectToPush = {
  CarrierGenericFTPId: "", // in case of edit or delete
  CarrierGenericFTPTypeId: "",
  CarrierGenericFTPProtocolTypeId: "",
  PhoneNumber: "",
  Site: "",
  Port: "",
  Username: "",
  Password: "",
  CarrierSSHKeyId: "",
  Directory: ""
};

const CarrierTabsTechnicalInfoGenericFTP = ({ carrierDetails, carrierGenericFTPTypes, carrierGenericFTPProtocolsTypes, carrierSSHKeys, getSingleCarrierHandler, ...props }) => {
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
  const { TechnicalInfo } = carrierDetails || {};
  const { CarrierGenericFTPs } = TechnicalInfo || {};
  const [isEditableForm, setIsEditableForm] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const { openLoader, closeLoader } = useLoader();
  const { carrierIDURL } = useParams();

  useEffect(() => {
    setInitialValues({ CarrierGenericFTPs: [...(CarrierGenericFTPs || [])]});
  }, [CarrierGenericFTPs]);

  const reformatArrayOfGenericFTPsList = (carrierGenericFTPsList) => {
    const ArrayOfCarrierGenericFTPsListToBeSent = [];
    carrierGenericFTPsList.forEach(element => {
      const currentElement = {
        CarrierID: carrierIDURL,
        CarrierGenericFTPTypeId: element.CarrierGenericFTPTypeId,
        CarrierGenericFTPProtocolTypeId: element.CarrierGenericFTPProtocolTypeId,
        Site: element.Site,
        Port: element.Port,
        Username: element.Username,
        Directory: element.Directory
      }
      if (element.CarrierGenericFTPId) currentElement.CarrierGenericFTPId = element.CarrierGenericFTPId;
      if (element.CarrierGenericFTPProtocolTypeId == "4") {
        currentElement.CarrierSSHKeyId = element.CarrierSSHKeyId;
      } else {
        currentElement.Password = element.Password;
      }
      ArrayOfCarrierGenericFTPsListToBeSent.push(currentElement)
    });
    return ArrayOfCarrierGenericFTPsListToBeSent;
  }

  const handleAddSubmit = async (values, { setSubmitting }) => { 
   const ArrayOfCarrierGenericFTPsListToBeSent = reformatArrayOfGenericFTPsList(values?.CarrierGenericFTPs);
   openLoader()
   APIs.updateCarrierGenericFTP({ftpList : ArrayOfCarrierGenericFTPsListToBeSent})
   .then((result) => {
    getSingleCarrierHandler();
   }).catch((err) => {
    console.log('Error while Editting Carrier Generic FTP', err);
   })
   .finally(() =>{
     closeLoader();
     setIsEditableForm(false);
    });
  };

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={handleAddSubmit}>
        {({ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }) => (
          <Form className="flex-grow-1">
            <Accordion defaultExpanded className="mt-5">
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel-header-cr-config"
                onClick={(event) => {
                  event.stopPropagation();
                }}
                style={{ pointerEvents: "none" }}
              >
                <span>Generic FTP</span>
                {/* super admin and analyst */}
                {(userRoles.includes('1') || userRoles.includes('7')) &&
                  <div className="ml-auto d-flex" style={{ columnGap: 10 }}>
                    {isEditableForm && (
                      <div
                        className="align-items-center blue-btn btn d-flex justify-content-center"
                        style={{ pointerEvents: "auto", borderRadius: 4 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setFieldValue("CarrierGenericFTPs", [...values.CarrierGenericFTPs, { ...JSON.parse(JSON.stringify({ ...addOrEditObjectToPush })) }]);
                        }}
                      >
                        <div className="my-auto">
                          <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                          </svg>
                          ADD
                        </div>
                      </div>
                    )}
                    {!isEditableForm &&
                      <Button variant="contained"
                        className="blue-outlined-btn mr-2 ml-auto"
                        style={{ pointerEvents: "auto" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEditableForm(true);
                        }}>
                        <svg width="20" height="24" viewBox="0 0 25 24">
                          <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                        </svg>
                        <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit </label>
                      </Button>
                    }
                  </div>
                }
              </AccordionSummary>
              <AccordionDetails>
                {CarrierGenericFTPs?.length <= 0 && (
                  <div className="no-data-alert">
                    <h6>No Generic FTP Added!</h6>
                  </div>
                )}

                {values?.CarrierGenericFTPs?.map((el, eli) => (
                  <React.Fragment key={eli}>
                    <CarrierTabsTechnicalInfoGenericFTPSingleItem
                      carrierGenericFTPTypes={carrierGenericFTPTypes}
                      carrierGenericFTPProtocolsTypes={carrierGenericFTPProtocolsTypes}
                      carrierSSHKeys={carrierSSHKeys}
                      el={el}
                      index={eli}
                      isEditableForm={isEditableForm}
                      setIsEditableForm={setIsEditableForm}
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                      getSingleCarrierHandler={getSingleCarrierHandler}
                      CarrierGenericFTPs={CarrierGenericFTPs}
                    />
                  </React.Fragment>
                ))}
              </AccordionDetails>
            </Accordion>

            {isEditableForm && (
              <div className="w-100 text-right mt-5">
                <Button
                  type="button"
                  onClick={() => {
                    resetForm();
                    setIsEditableForm(false);
                  }}
                  variant="contained"
                  className="blue-outlined-btn mr-2"
                >
                  Cancel
                </Button>

                <Button type="submit" variant="contained" className="blue-btn">
                  Submit
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CarrierTabsTechnicalInfoGenericFTP;
