import React, { useEffect, useState } from 'react'
import AgreementDocumentTableRow from './AgreementDocumentTableRow';
import * as APIs from '../../../../../Data/APIs'
import { error } from 'jquery';
import { useLoader } from '../../../../Layout/Loader';
import { Zoom } from '@material-ui/core';
import { CustomTooltip } from '../../../../Layout/Tooltip';
import { emptyAgreement } from '../CarrierTabsLegalInfo';


const AgreementDocumentsTable = ({ attachments, formikObject,addNewDocumentClicked, setAddNewDocumentClicked, carrierDetails, getSingleCarrierHandler, ...props }) => {
    const { openLoader, closeLoader } = useLoader()
    const [legalDocumentTypes, setLegalDocumentTypes] = useState([])
   
    useEffect(() => {
        openLoader()
        APIs.getCarrierLegalDocumentTypes().then(res => {
            setLegalDocumentTypes(res?.data)
        }).catch(error => {
            console.log('Getting Carrier Legal Document Types error :', error)
        }).finally(() => closeLoader())
    }, [])

    return (
        <>
            <div className="row text-center table-header">
                <div className="col-3 table-cell first-cell">Agreement Document Type</div>
                <div className="col-3 table-cell">Attach Agreement Document</div>
                <div className="col-3 table-cell">
                    <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom}
                        title='This is the date the agreement goes into effect'>
                        <div className='mx-auto'>Agreement Document Signed Date</div>
                    </CustomTooltip>
                </div>
                <div className="col-2 table-cell">
                    <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom}
                        title='The signor is the individual who signed the agreement'>
                        <div className='mx-auto'>Agreement Signor</div>
                    </CustomTooltip>
                </div>
                <div className="col-1 table-cell">Actions</div>
            </div>

            {attachments?.length > 0 ? (
                attachments?.map((a, ai) => (
                    <React.Fragment key={ai}>
                   
                        <AgreementDocumentTableRow agreementDocument={a} index={ai} legalDocumentTypes={legalDocumentTypes}  carrierDetails={carrierDetails} addNewDocumentClicked = {addNewDocumentClicked} setAddNewDocumentClicked={setAddNewDocumentClicked} getSingleCarrierHandler={getSingleCarrierHandler}
                        />
                    </React.Fragment>
                ))
            ) : (
                !addNewDocumentClicked ? (
                <>
                    <div className="no-data-alert">
                        <h6>No Agreement Documents Added!</h6>
                    </div>
                </>
                ):
                <></>
            )}
        {addNewDocumentClicked && (
            <AgreementDocumentTableRow agreementDocument={{...emptyAgreement}} index={0} legalDocumentTypes={legalDocumentTypes} currentMode="add" carrierDetails={carrierDetails} addNewDocumentClicked={addNewDocumentClicked} setAddNewDocumentClicked={setAddNewDocumentClicked} getSingleCarrierHandler={getSingleCarrierHandler} />
    )}
        </>
    )
}

export default AgreementDocumentsTable
