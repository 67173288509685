import React from "react";
import { Divider, Button, Zoom, } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import { useAuthentication } from "../../../../Data/Authentication";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { CustomTooltip } from "../../../Layout/Tooltip";
import { useLoader } from "../../../Layout/Loader";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Moment from 'moment';
import MomentTZ from 'moment-timezone';
import { FailureModal } from "../../../Modals/FailureModal";
import plusIconSVG from "../../../../Icons/plusIcon.svg";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import { AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"


export function toESTTime(timeString, format = undefined) {
    const localTime = MomentTZ.utc(timeString).toDate();
    return MomentTZ(localTime).tz("America/New_York").format(format ?? "YYYY-MM-DD HH:mm:ss")
}

function AnalystConnectivityRequestAIComments({ actionItem, setActionItem, aiComments, setAIComments, parentForceUpdate, ...props }) {

    const dialog = useModalDialog(0);
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const { openLoader, closeLoader } = useLoader();

    const [commentText, setCommentText] = React.useState("");
    const [fileAttached, setFileAttached] = React.useState();
    const [fileAttachedBase64, setFileAttachedBase64] = React.useState();
    const [commentTextEdit, setCommentTextEdit] = React.useState("");
    const [fileAttachedEdit, setFileAttachedEdit] = React.useState();
    const [fileAttachedBase64Edit, setFileAttachedBase64Edit] = React.useState();
    const [isEditClicked, setIsEditClicked] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState(-1);
    const [expanded, setExpanded] = React.useState(true)

    const fileInputRef = React.useRef();
    const fileInputEditRef = React.useRef();

    const _colorOptions = {
        loggedInUser: "var(--orange)",
    };

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    const handleCommentTextChange = (e) => {
        setCommentText(e.target.value);
    }

    const handleCommentTextEditChange = (e) => {
        setCommentTextEdit(e.target.value);
    }

    const encodeFileAttachedBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFileAttachedBase64(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };

    const encodeFileAttachedBase64Edit = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFileAttachedBase64Edit(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };

    const handleFileAttachedChange = (e) => {
        if (e.target.files[0]?.size === 0) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'File is empty!'
            });
        }
        else {
            setFileAttached(e.target.files[0]);
            encodeFileAttachedBase64(e.target.files[0]);
        }
    }

    const handleFileAttachedEditChange = (e) => {
        if (e.target.files[0]?.size === 0) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'File is empty!'
            });
        }
        else {
            setFileAttachedEdit(e.target.files[0]);
            encodeFileAttachedBase64Edit(e.target.files[0]);
        }
    }

    const addComment = () => {
        openLoader();
        const addCRCommentBody = {
            ActionItemID: actionItem?.Id,
            Comment: commentText.replaceAll("\n", "<br/>") ?? "",
            FileName: fileAttached?.name?.substring(0, fileAttached?.name.lastIndexOf('.')) ?? "",
            Extension: fileAttached?.name?.substring(fileAttached?.name.lastIndexOf('.') + 1, fileAttached?.name.length) ?? "",
            Value: fileAttachedBase64 ?? ""
        }

        APIs.addActionItemComments(addCRCommentBody).then((r) => {
            APIs.getActionItemDetails(actionItem?.Id).then(d => {
                setAIComments(d?.data?.Comments ?? []);
                parentForceUpdate();
                closeLoader();
            });
            setCommentText("");
            setFileAttached("");
            setFileAttachedBase64("");
        })
    }

    const editComment = () => {
        openLoader();
        const editCRCommentBody = {
            Id: aiComments[editIndex]?.Id,
            Comment: commentTextEdit.replaceAll("\n", "<br/>") ?? "",
            FileName: fileAttachedEdit?.name?.substring(0, fileAttachedEdit?.name.lastIndexOf('.')) ?? "",
            Extension: fileAttachedEdit?.name?.substring(fileAttachedEdit?.name.lastIndexOf('.') + 1, fileAttachedEdit?.name.length) ?? "",
            Value: fileAttachedBase64Edit ?? "",
            NoAttachmentUpdate: !fileAttachedEdit ? true : false,
        }

        APIs.editActionItemComments(editCRCommentBody).then((r) => {
            APIs.getActionItemDetails(actionItem?.Id).then(d => {
                setAIComments(d?.data?.Comments ?? []);
                parentForceUpdate();
                closeLoader();
            });
            setIsEditClicked(false);
            setCommentTextEdit("");
            setFileAttachedEdit("");
            setFileAttachedBase64Edit("");
        })
    }

    React.useEffect(() => {
        setCommentTextEdit(aiComments[editIndex]?.Comment);
        setFileAttachedEdit("");
        setFileAttachedBase64Edit("");
    }, [editIndex, isEditClicked === true])

    return (
        <div id="action-items-comments" className="communication-thread mt-3 newUi-collapse">
            <Accordion className="" expanded={expanded} id="action-item-information">
                <AccordionSummary
                    onClick={() => setExpanded(!expanded)}
                    className="pl-0 pr-0"
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="expand-oe"
                >
                    <span>Action Item Comments</span>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="alignvh-center" style={{ display: "inline-grid", justifyItems: "center" }}>
                        <label className="fw-700 color black5 fs-18 mb-0">{aiComments?.length}</label>
                        <label className="color grey">Comments</label>
                    </div>
                    <div className="row comments-modal-footer alignvh-center">
                        <div className="userInfo alignvh-center col-1 pl-5 pr-2" style={{ justifyContent: "flex-end" }}>
                            <span className="user" style={{ backgroundColor: _colorOptions.loggedInUser }}>{`${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                        </div>
                        <div className="col-11 pl-2 pr-5">
                            <textarea value={commentText} onChange={handleCommentTextChange} placeholder="Please add your comment here..." className="public-key-textarea" rows={3} />
                        </div>
                        <input ref={fileInputRef} hidden type="file" className="text-input w-100" style={{ paddingRight: "50px" }} value={fileAttached ? fileAttached?.File?.name : ""} onChange={handleFileAttachedChange} />
                        {fileAttached && (
                            <>
                                <div className="col-1"></div>
                                <div className="col-11 color grey2 mt-2 mb-2 alignvh-center">
                                    <AttachFileIcon style={{ transform: "rotate(30deg)", marginRight: "10px" }} />
                                    <span>{fileAttached.name}</span>
                                    {/* <DeleteForeverIcon className="ml-3" style={{ cursor: "pointer" }} onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }} /> */}
                                    <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }} style={{ cursor: "pointer" }}>
                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                    </svg>
                                </div>
                            </>
                        )}
                        <div className="col-1"></div>
                        <a onClick={() => fileInputRef.current.click()} className="col-11 color blue-main-theme fs-18 mt-2 mb-2" style={{ cursor: "pointer", pointerEvents: fileAttached && `none` }}>
                            <svg width="22" height="22" viewBox="0 0 22 22" className="mt--2">
                                <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                            </svg>   Attach File
                        </a>
                        <div className="col-1"></div>
                        <div className="col-11 text-right">
                            <Button disabled={!fileAttached && commentText === ""} onClick={addComment} className="blue-btn" style={{ marginLeft: "-10px" }}>
                                Post Comment
                            </Button>
                        </div>
                    </div>
                    <div className="cr-comments-div">
                        <div className="mt-3 mb-4">
                            {aiComments.length > 0 ? (
                                aiComments.map((c, ci) => (
                                    <div className="mt-2" key={`cr-comments-${ci}`} style={{ background: "var(--white1)", padding: "15px 22px 10px" }}>
                                        <div className="userInfo alignvh-center row">
                                            <div>
                                                <CustomTooltip TransitionComponent={Zoom} title={<div><span>{c?.CreatedBy}</span><br /><span>{c?.CreatedByEmail}</span></div>}>
                                                    <span className="user" style={{ backgroundColor: _colorOptions.loggedInUser }}>{c?.CreatedBy.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                                                </CustomTooltip>
                                            </div>
                                            <div className="ml-2">
                                                <span className="fw-700">{c?.CreatedBy}</span>
                                                <br />
                                                <span style={{ color: "var(--black3)" }}>{Moment(toESTTime(c?.Created)).format('MM/DD/YYYY, HH:mm:ss')}</span>
                                            </div>
                                            {(c?.Attachment !== "-" && c?.AttachmentUrl !== null && c?.Attachment !== null) && (
                                                <div className="ml-auto comment-attachment-div">
                                                    <a href={c?.AttachmentUrl}>{c?.Attachment}</a>
                                                    {((userActions?.includes("20") && authUser?.attributes["sub"] !== c.CreatedBy) || (authUser?.attributes["sub"] === c.CreatedByCognitoID)) && c?.CreatedBy !== "System Admin" && (
                                                        <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={() => {

                                                            if (c?.Comment === null || c?.Comment?.replaceAll(" ", "").replace(/(<([^>]+)>)/ig, '').replaceAll("\n", "") === "") {
                                                                openModal(0, () => {
                                                                    openLoader(); setIsEditClicked(false); APIs.deleteActionItemComments({ CommentID: c.Id }).then((r) => {
                                                                        APIs.getActionItemDetails(actionItem?.Id).then(d => {

                                                                            setAIComments(d?.data?.Comments ?? []);
                                                                            parentForceUpdate();
                                                                            closeLoader();
                                                                        });
                                                                    });
                                                                }, {
                                                                    title: 'Please Confirm',
                                                                    question: `Kindly be noted that deleting the attachment will delete the whole comment, are you sure you want to continue?`,
                                                                });
                                                            }
                                                            else {

                                                                openLoader();
                                                                setEditIndex(ci);
                                                                const deleteAICommentBody = {
                                                                    Id: aiComments[ci]?.Id,
                                                                    Comment: aiComments[ci]?.Comment,
                                                                    FileName: null,
                                                                    Extension: null,
                                                                    Value: null,
                                                                    NoAttachmentUpdate: false,
                                                                }

                                                                APIs.editActionItemComments(deleteAICommentBody).then((r) => {
                                                                    APIs.getActionItemDetails(actionItem?.Id).then((d) => {
                                                                        setAIComments(d?.data?.Comments ?? []);
                                                                        parentForceUpdate();
                                                                        closeLoader();
                                                                    })
                                                                })
                                                            }
                                                        }
                                                        } style={{ cursor: "pointer" }}>
                                                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                        </svg>
                                                        // <DeleteForeverIcon className="ml-3" style={{ cursor: "pointer", color: "red" }} onClick={() => {
                                                    )}


                                                </div>
                                            )}
                                        </div>
                                        <div className="row" style={{ margin: "40px 50px" }}>
                                            {isEditClicked && ci === editIndex ? (
                                                <>
                                                    <textarea value={commentTextEdit?.replaceAll("<br/>", "\n")} onChange={handleCommentTextEditChange} placeholder="Please add your comment here..." className="public-key-textarea" rows={3} />
                                                    <input ref={fileInputEditRef} hidden type="file" className="text-input w-100" style={{ paddingRight: "50px" }} value={fileAttachedEdit ? fileAttachedEdit?.File?.name : ""} onChange={handleFileAttachedEditChange} />
                                                    {fileAttachedEdit && (
                                                        <>
                                                            <div className="col-12 color grey2 mt-2 mb-2 alignvh-center">
                                                                <AttachFileIcon style={{ transform: "rotate(30deg)", marginRight: "10px" }} />
                                                                <span>{fileAttachedEdit.name}</span>
                                                                {/* <DeleteForeverIcon className="ml-3" style={{ cursor: "pointer" }} onClick={(e) => { setFileAttachedEdit(""); setFileAttachedBase64Edit(""); }} /> */}
                                                                <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={(e) => { setFileAttachedEdit(""); setFileAttachedBase64Edit(""); }} style={{ cursor: "pointer" }}>
                                                                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                                </svg>
                                                            </div>
                                                            {(c?.Attachment !== "-" && c?.Attachment !== null) && (
                                                                <div>
                                                                    <span className="text-danger mb-2 ml-2">Kindly be noted that the currently attached file will replace the previous existing file.</span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    <a onClick={() => fileInputEditRef.current.click()} className="col-12 color blue-main-theme fs-18 mt-2 mb-2" style={{ cursor: "pointer", pointerEvents: fileAttachedEdit && `none` }}>
                                                        <svg width="22" height="22" viewBox="0 0 22 22" className="mt--2">
                                                            <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                                                        </svg> Attach File
                                                    </a>
                                                    <div className="ml-auto">
                                                        <Button onClick={() => setIsEditClicked(false)} className="blue-outlined-btn mr-2">
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={editComment} className="blue-btn">
                                                            Save Comment
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : (
                                                <div>{c?.Comment?.replaceAll("<br/>", "\n")}</div>
                                            )}
                                        </div>
                                        {((userActions?.includes("20") && authUser?.attributes["sub"] !== c.CreatedBy) || (authUser?.attributes["sub"] === c.CreatedByCognitoID)) && c?.CreatedBy !== "System Admin" && (
                                            <div className="row mt-3 cr-comments-actions">
                                                <span onClick={(e) => { setIsEditClicked(true); setEditIndex(ci); }}>Edit</span>
                                                <span onClick={(e) => {
                                                    openModal(0, () => {
                                                        openLoader(); setIsEditClicked(false); APIs.deleteActionItemComments({ CommentID: c.Id }).then((r) => {
                                                            APIs.getActionItemDetails(actionItem?.Id).then(d => {

                                                                setAIComments(d?.data?.Comments ?? []);
                                                                parentForceUpdate();
                                                                closeLoader();
                                                            });
                                                        });
                                                    }, {
                                                        title: 'Please Confirm',
                                                        question: `Are you sure you want to delete this comment?`,
                                                    });
                                                }}>Delete</span>
                                            </div>
                                        )}
                                    </div>
                                )).reverse()
                            ) : (
                                <div className="no-data-alert">
                                    <h4>Start your conversation here...</h4>
                                </div>
                            )}
                        </div>
                    </div>
                </AccordionDetails>

            </Accordion>


        </div>
    )
}

export default AnalystConnectivityRequestAIComments;