import React from 'react'
import * as APIs from '../../../../Data/APIs';
import { useAuthentication } from '../../../../Data/Authentication';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AgreementDocumentsTable from './AgreementDocuments/AgreementDocumentsTable';
import GenericFormikInput from '../../../GenericFormikInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import editRequestIcon from "../../../../Icons/editIcon.svg";
import { Button } from "@material-ui/core";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";
import { useLoader } from '../../../Layout/Loader';
import { helperInfoIcon, convertBoolToYesNo } from '../../../../_helper';

const validationSchema = Yup.object().shape({
    CarrierName: Yup.string().required('This Field is Required'),
    LegalDocumentRequired: Yup.string().required('This Field is Required'),
});

export const emptyAgreement = {
    CarrierLegalDocumentTypeId: '',
    AttachmentName: '',
    LegalDocumentSignDate: '',
    LegalDocumentSignor: '',
}

const CarrierTabsLegalInfo = ({ carrierDetails, getSingleCarrierHandler, onEdit, onSetEdit, ...props }) => {
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const { openLoader, closeLoader } = useLoader();
    //   const [onEdit, setOnEdit] = React.useState(false);
    const [expanded, setExpanded] = React.useState("carrierLegalInfoTab");
    const [addNewDocumentClicked, setAddNewDocumentClicked] = React.useState(false);

    const { LegalInfo } = carrierDetails || {}
    const [initialValues, setInitialValues] = React.useState({
        CarrierName: carrierDetails?.CarrierName,
        LegalDocumentRequired: "" + (carrierDetails?.LegalInfo?.LegalDocumentRequired),
        LegalAttachments: LegalInfo?.Attachments,

    })

    const addLegalDocumentHandler = (e, values, setFieldValue) => {
        e.stopPropagation();
        setAddNewDocumentClicked(true)
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        openLoader();
        const editCarrierLegalInfoBody = {
            CarrierID: carrierDetails?.CarrierID,
            LegalDocumentRequired: +(values?.LegalDocumentRequired),
        };
        try {
            await APIs.editCarrierLegalInfo(editCarrierLegalInfoBody);
            await getSingleCarrierHandler();
        } finally {
            closeLoader();
            setSubmitting(false);
        }
    };

    const handleCancel = (resetForm) => {
        resetForm();
        onSetEdit(false);
    };
    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }) =>
                (
                    <Form className='d-flex flex-column'>
                        <Accordion defaultExpanded expanded={expanded === 'carrierLegalInfoTab'}>
                            <AccordionSummary
                                aria-controls="panel2-content"
                                id="panel-header-cr-config"
                            >
                                <span>Legal Information</span>
                                {(userRoles?.includes("7") // Super Admin
                                    // || userRoles?.includes("2")  //iSolved Super Admin (this line needs to be uncommented next sprint)
                                ) && (

                                        <Button variant="contained" className="blue-outlined-btn mr-2 ml-auto" onClick={() => {
                                            if (onEdit) {
                                                handleCancel(resetForm);
                                            } else {
                                                onSetEdit(true);
                                            }
                                        }} >
                                            <svg width="20" height="24" viewBox="0 0 25 24">
                                                <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                            </svg>
                                            <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                                        </Button>
                                    )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Carrier Name'}
                                            name={'CarrierName'}
                                            type={'text'}
                                            isRequired={true}
                                            isReadOnly={true}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.CarrierName
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Carrier Requires BAA or Legal Document'}
                                            name={'LegalDocumentRequired'}
                                            moreInfo='Does the carrier require a BAA or legal document?'
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: [{ optionNameKey: "Yes", optionValueKey: "1" }, { optionNameKey: "No", optionValueKey: "0" }],
                                                    nameKey: 'optionNameKey',
                                                    valueKey: 'optionValueKey',

                                                }}
                                            isRequired={true}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: convertBoolToYesNo(LegalInfo?.LegalDocumentRequired)
                                            }}
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {onEdit && (
                            <div className="w-100 text-right mt-5">
                                <Button type="button" variant="contained" className="blue-outlined-btn mr-2" onClick={() => handleCancel(resetForm)}>Cancel</Button>
                                <Button type="submit" variant="contained" className="blue-btn" >Submit</Button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
            {/* Agreement Documents */}
            {/* super admin , analyst isolved superadmin and admin direct */}
            {(userRoles.includes("1") || userRoles.includes("7") || userRoles.includes("8") || userRoles.includes("3")) &&
                <Accordion defaultExpanded className='mt-5'>
                    <AccordionSummary
                        aria-controls="panel2-content"
                        id="panel-header-cr-config"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        style={{ pointerEvents: 'none' }}
                    >
                        <span>Agreement Documents</span>
                        <div className="add-record-btn ml-auto"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => { addLegalDocumentHandler(e) }
                            }
                        >
                            <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                            </svg>
                            ADD
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className='p-0-imp'>
                        <AgreementDocumentsTable
                            attachments={LegalInfo?.Attachments}
                            addNewDocumentClicked={addNewDocumentClicked}
                            setAddNewDocumentClicked={setAddNewDocumentClicked}
                            carrierDetails={carrierDetails}
                            getSingleCarrierHandler={getSingleCarrierHandler}
                        />
                    </AccordionDetails>
                </Accordion>
            }
        </React.Fragment>
    )
}

export default CarrierTabsLegalInfo
