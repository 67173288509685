import React, { useEffect, useState } from 'react';
import BreadcrumbSteps from '../Layout/BreadcrumbSteps';
import TabsTitles from '../Layout/TabsTitles';
import { Button, Paper } from '@material-ui/core';
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as APIs from "../../Data/APIs";
import CarrierTabsCarrierContactTable from './CarrierTabs/GenralInfoTab/CarrierTabsCarrierContact/CarrierTabsCarrierContactTable';
import RelationshipContactsTable from './CarrierTabs/GenralInfoTab/RelationshipContacts/RelationshipContactsTable';
import plusIconSVG from "../../Icons/PlusIconWhite.svg";
import { emailRegex } from '../../_helper';
import { useLoader } from '../Layout/Loader';
import { debounce } from "lodash";
import GenericFormikInput from '../GenericFormikInput';
import { AlertModal } from '../Modals/AlertModal';
import { useHistory } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  CarrierName: Yup.string().trim().required('This Field is Required'),
  CarrierNumber: Yup.string().trim().required('This Field is Required'),

  CarrierRelationshipContacts: Yup.array()
    .of(
      Yup.object().shape({
        FirstName: Yup.string().required('This Field is Required'),
        LastName: Yup.string().required('This Field is Required'),
        Email: Yup.string().matches(emailRegex, 'Invalid Email Address').required('This Field is Required'),
      })
    ),

  CarrierContacts: Yup.array()
    .of(
      Yup.object().shape({
        FirstName: Yup.string().required('This Field is Required'),
        LastName: Yup.string().required('This Field is Required'),
        Email: Yup.string().matches(emailRegex, 'Invalid Email Address').required('This Field is Required'),
        CarrierContactCategoryID: Yup.string().required('This Field is Required'),
        CarrierContactTypeID: Yup.string().required('This Field is Required'),
      })
    )
    .min(1, 'Please Add At Least One Carrier Contact'),
});

const emptyRelationshipContact = {
  FirstName: '',
  LastName: '',
  JobTitle: '',
  Email: '',
  PhoneNumber: '',
  Notes: '',
}

const emptyCarrierContact = {
  FirstName: '',
  LastName: '',
  Email: '',
  PhoneNumber: '',
  CarrierContactCategoryID: '',
  CarrierContactTypeID: '',
  DisplayOnCRs: false,
}

const CreateCarrier = ({ ...props }) => {
  const [initialValues, setInitialValues] = useState({
    CarrierName: '',
    CarrierNumber: '', //Carrier Code
    CarrierFederalTaxID: '',

    CarrierRelationshipContacts: [],
    CarrierContacts: [],

    CarrierContactNotes: '',
    CarrierGeneralNotes: '',
  });
  const [duplicateType, setDuplicateType] = useState("");
  const [duplicateCarriersList, setDuplicateCarriersList] = useState([]);
  const [similarCarriersList, setSimilarCarriersList] = useState([]);
  const [continueWithWarning, setContinueWithWarning] = useState(false);
  const dialog = useModalDialog(0);
  const { openLoader, closeLoader } = useLoader();  
  const routeHistory = useHistory();


  const openAlertModal = () => {
    setContinueWithWarning(false);

    AlertModal(
      dialog,
      // on confirm handler
      () => {
        if (duplicateType == "warning") setContinueWithWarning(true)
       },
      // content
      {
        title: 'Alert!',
        info:
          <div>
            {duplicateType == "error" ?
              <>
                This carrier name already exists, please make sure you are adding a new carrier
                <div className="text-left fs-12">
                  {duplicateCarriersList.map((dc, dci) =>
                    <a className='warningLink' key={`duplicatedCarrier-${dc?.CarrierID}`} href={`/Carriers/ViewCarrier/${dc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{dc?.CarrierName}</a>
                  )}
                </div>
              </> :
              <>
                This carrier name is similar to the following carrier(s), are you sure you want to save a new one?
                <div className="text-left fs-12">
                  {similarCarriersList.map((sc, sci) =>
                    (<a className='warningLink' key={`similarCarrier-${sc?.CarrierID}`} href={`/Carriers/ViewCarrier/${sc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{sc?.CarrierName}</a>))}
                </div>
              </>
            }
          </div >
        ,
        question: ``,
      }
    )
  }

  // useEffect(() => {
  //   if (duplicateType && (duplicateCarriersList.length > 0 || similarCarriersList.length > 0)) {
  //     openAlertModal()
  //   }

  // }, [duplicateType, duplicateCarriersList, similarCarriersList]);
  
  // the core function of carrier name validation
  const validateCarrierNameCore = async (carrierNameValue) => {
    openLoader();
    APIs.checkCarrierNameSameOrSimilar({ CarrierName: carrierNameValue })
      .then((res) => {
        setDuplicateType(res?.duplicateType?.toLowerCase() || '');
        setDuplicateCarriersList(res?.duplicateCarriers || []);
        setSimilarCarriersList(res?.similarCarriers || []);
      })
      .catch((error) => { console.log('Error while checking is same or similar carrier name :', error) })
      .finally(() => { closeLoader() })
  };

  const debouncedAsyncValidationForCarrierName = debounce(validateCarrierNameCore , 2000);

  const addRelationshipContactHandler = (e, values, setFieldValue) => {
    e.stopPropagation();
    setFieldValue('CarrierRelationshipContacts', [
      ...values.CarrierRelationshipContacts,
      JSON.parse(JSON.stringify(emptyRelationshipContact))
    ]);
  }

  const addCarrierContactHandler = (e, values, setFieldValue) => {
    e.stopPropagation();
    setFieldValue('CarrierContacts', [
      ...values.CarrierContacts,
      JSON.parse(JSON.stringify(emptyCarrierContact))
    ]);
  }

  const reformatObjectToSend = (values)=>{
    const objectToSend = {
      CarrierName: values.CarrierName,
      CarrierCode: values.CarrierNumber,
      CarrierFederalTaxID: values?.CarrierFederalTaxID,

      CarrierRelationshipContacts: values?.CarrierRelationshipContacts.map((item) => {
        return {
          RelationshipContactFirstName: item.FirstName,
          RelationshipContactLastName: item.LastName,
          RelationshipContactJobTitle: item.JobTitle,
          RelationshipContactEmail: item.Email,
          RelationshipContactPhoneNumber: item.ContactPhone,
          RelationshipContactNotes: item.Notes,
        }
      }),

      CarrierContacts: values?.CarrierContacts.map((item) => {
        return {
          ContactFirstName: item.FirstName,
          ContactLastName: item.LastName,
          // ContactJobTitle: item.JobTitle,
          ContactEmail: item.Email,
          ContactPhoneNumber: item.PhoneNumber,
          ContactCategoryID:item?.CarrierContactCategoryID,
          ContactTypeID: item.CarrierContactTypeID,
          ContactDisplayOnCRs:item?.DisplayOnCRs
          }
        }),
      
      CarrierGeneralNotes: values?.CarrierGeneralNotes,
      CarrierContactNotes: values?.CarrierContactNotes,
    }

    if (duplicateType === "warning"){ 
       objectToSend['ContinueWithWarning'] = continueWithWarning;
    }

    return objectToSend;

  }

  const handleSubmit = async (values, { setSubmitting }) => {
    if (duplicateType) {
      if (duplicateType === "error") return;
      if (duplicateType === "warning" && !continueWithWarning) {
        openAlertModal(true);
        return;
      }
    }

    openLoader();
    const carrierObject = reformatObjectToSend(values);
    APIs?.addCarrier(carrierObject).then((res) => {
      if (res?.CarrierID){
        routeHistory.push(`/Carriers/ViewCarrier/${res?.CarrierID}`)
      }else{
        setDuplicateType(res?.duplicateType?.toLowerCase() || '');
        setDuplicateCarriersList(res?.duplicateCarriers || []);
        setSimilarCarriersList(res?.similarCarriers || []);
      }
    })
    .catch(err => console.log('Error While Creating a new Carrier:', err))
    .finally(() => {closeLoader()})
  };

  return (
    <div id="create-grp-request">
      <BreadcrumbSteps
        breadcrumbTitleCurrent="Create Carrier"
        isCurrent={true}
      />
      <TabsTitles tabTitle={["Create Carrier"]} />
      <Paper elevation={0} className="p-5">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ touched, errors, values, setFieldValue, handleBlur, submitCount }) =>
          (
            <Form className='d-flex flex-column'>
              {/* Carrier Information */}
              <Accordion defaultExpanded>
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel-header-cr-config"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  style={{ pointerEvents: 'none' }}
                >
                  <span>Carrier Information</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} className='d-flex flex-column'>
                      <GenericFormikInput
                        label={'Carrier Name'}
                        name={'CarrierName'}
                        type={'text'}
                        isRequired={true}
                        extraOnChange={(inputValue)=>{
                          debouncedAsyncValidationForCarrierName(inputValue)
                        }}
                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                      />
                      {duplicateType === "error" && <div className="text-danger"> This carrier name is duplicate as the following <span style={{ color: '#007bff ', textDecoration: 'underline' }} role='button' onClick={openAlertModal}>carrier</span></div>}
                      {duplicateType === "warning" && !continueWithWarning && <div className="text-alert-yellow">This carrier name is similar to the following <span style={{color:'#007bff ', textDecoration: 'underline'}} role='button' onClick={openAlertModal}>carrier(s)</span></div>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <GenericFormikInput
                        label={'Carrier Code'}
                        name={'CarrierNumber'}
                        type={'text'}
                        isRequired={true}
                        moreInfo='The Carrier Code is the Partner ID, which comes from isolved HCM'
                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <GenericFormikInput
                        label={'Carrier Federal Tax'}
                        name={'CarrierFederalTaxID'}
                        type={'text'}
                        isRequired={false}
                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              {/* Relationship Contacts */}
              <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel-header-cr-config"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  style={{ pointerEvents: 'none' }}
                >
                  <span>Relationship Contacts</span>
                  <div className="add-record-btn ml-auto"
                    style={{ pointerEvents: 'auto' }}
                    onClick={(e) => { addRelationshipContactHandler(e, values, setFieldValue) }
                    }
                  >
                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                      <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                    </svg>
                    ADD
                  </div>
                </AccordionSummary>
                <AccordionDetails className='p-0-imp'>
                  <>
                    <RelationshipContactsTable
                      carrierRelationshipContacts={values?.CarrierRelationshipContacts}
                      mode='add'
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </>
                </AccordionDetails>
              </Accordion>

              {/* Carrier Contacts */}
              <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel-header-cr-config"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  style={{ pointerEvents: 'none' }}
                >
                  <span>Carrier Contacts</span>
                  <div className="add-record-btn ml-auto"
                    style={{ pointerEvents: 'auto' }}
                    onClick={(e) => { addCarrierContactHandler(e, values, setFieldValue) }
                    }
                  >
                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                      <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                    </svg>
                    ADD
                  </div>
                </AccordionSummary>
                <AccordionDetails className='p-0-imp'>
                  <CarrierTabsCarrierContactTable
                    carrierContacts={values?.CarrierContacts}
                    mode='add'
                    formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                  />
                  {submitCount > 0 &&
                    errors['CarrierContacts'] &&
                    touched['CarrierContacts'] &&
                    typeof errors['CarrierContacts'] === 'string' && (
                      <div className="text-danger p-2">{errors['CarrierContacts']}</div>
                    )}
                </AccordionDetails>
              </Accordion>

              {/* Notes */}
              <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel-header-cr-config"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  style={{ pointerEvents: 'none' }}
                >
                  <span>Notes</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    <Grid item xs={12} sm={12} md={6}>
                      <GenericFormikInput
                        label={'Carrier Contact Notes'}
                        name={'CarrierContactNotes'}
                        type={'textArea'}
                        isRequired={false}
                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <GenericFormikInput
                        label={'General Notes'}
                        name={'CarrierGeneralNotes'}
                        type={'textArea'}
                        isRequired={false}
                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                      />
                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>

              <div className="w-100 text-right mt-5">
                <Button type="submit" variant="contained" className="blue-btn" >Submit</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  )
}

export default CreateCarrier
