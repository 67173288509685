
import MomentTZ from 'moment-timezone';
import { ConfirmModal } from './Components/Modals/ConfirmModal';
import { SuccessModal } from './Components/Modals/SuccessModal';
import { FailureModal } from './Components/Modals/FailureModal';
import { CustomTooltip } from './Components/Layout/Tooltip';
import { Zoom } from '@material-ui/core';
import infoCircleSVG from './Icons/info-circle.svg';

export function toESTTime(timeString, format = undefined) {
    const localTime = MomentTZ.utc(timeString).toDate();
    return MomentTZ(localTime).tz("America/New_York").format(format ?? "YYYY-MM-DD HH:mm:ss")
}


export function openModal(dialog, modalType, handleFunction, content) {
    switch (modalType) {
        case 0:
            return ConfirmModal(dialog, handleFunction, content);
        case 1:
            return SuccessModal(dialog, content);
        case 2:
            return FailureModal(dialog, content);
        default:
            break;
    }
}

export function convertBoolToYesNo(value) {
    return (value == 0 ? 'No' :
        value == 1 ? 'Yes' : '')
}

export function helperInfoIcon(msg) {
    return (<CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>{msg}</div>}>
        <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
            <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
        </svg>
    </CustomTooltip>)
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
