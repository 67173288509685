import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Button } from '@mui/material';
import GenericFormikInput from '../../../GenericFormikInput';
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import { openModal } from '../../../../_helper';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import { useParams } from 'react-router-dom';


const CarrierTabsTechnicalInfoGenericFTPSingleItem = ({ carrierGenericFTPTypes, carrierGenericFTPProtocolsTypes, carrierSSHKeys, el, index, isEditableForm, setIsEditableForm, formikObject,getSingleCarrierHandler,CarrierGenericFTPs, ...props }) => {
    const { touched, errors, values, setFieldValue, handleBlur, submitCount } = formikObject || {}
    const dialog = useModalDialog(0)
  const { openLoader, closeLoader } = useLoader();
  const { carrierIDURL } = useParams();

    const deleteFTPCore = () => {
        openLoader()
        const elementToDeleteIndex = CarrierGenericFTPs?.findIndex(c => c.CarrierGenericFTPId === el?.CarrierGenericFTPId)
        const newCarrierGenericFTPs = [...CarrierGenericFTPs];
        newCarrierGenericFTPs[elementToDeleteIndex] = {
            CarrierID: carrierIDURL,
            CarrierGenericFTPId: el.CarrierGenericFTPId,
            isDelete: 1
        }
        APIs.updateCarrierGenericFTP({ ftpList: newCarrierGenericFTPs })
            .then((result) => {
                getSingleCarrierHandler();
            }).catch((err) => {
                console.log('Error while Deleting FTP', err);
            })
            .finally(() => {
                closeLoader();
                setIsEditableForm(false);
            });
    };

    const deleteFTPHandler = () => {
        if (el?.CarrierGenericFTPId) {
            openModal(dialog, 0, () => deleteFTPCore(), {
                title: "Please Confirm",
                question: `Are you sure you want to delete this FTP ?`,
            })
        } else {
            const updatedFTPs = [...values.CarrierGenericFTPs];
            updatedFTPs.splice(index, 1);
            setFieldValue('CarrierGenericFTPs', updatedFTPs);
        }
    }

    return (
        <>
            <Accordion defaultExpanded className="mt-3">
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation()
                    }}
                    style={{ pointerEvents: "none" }}
                >
                    <span>{carrierGenericFTPTypes?.find((el) => el?.CarrierGenericFTPTypeId === values?.CarrierGenericFTPs[index]?.CarrierGenericFTPTypeId)?.CarrierGenericFTPTypeName}</span>
                    <div className="ml-auto d-flex" style={{ columnGap: 10 }}>
                        {isEditableForm && (
                            <Button
                                role="button"
                                className="icon-btn"
                                style={{ pointerEvents: "auto" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteFTPHandler();
                                }}

                            >
                                <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                </svg>
                            </Button>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="max-w-100 mx-0 mt-0">
                        {/* Transmission Type */}
                        <Grid item xs={12} sm={12} className="max-w-100 mx-0">
                            <GenericFormikInput
                                label={"Transmission Type"}
                                name={`CarrierGenericFTPs[${index}].CarrierGenericFTPTypeId`}
                                type={"optionList"}
                                isRequired={true}
                                optionsListConfig={{
                                    optionsListMenu: carrierGenericFTPTypes || [],
                                    nameKey: "CarrierGenericFTPTypeName",
                                    valueKey: "CarrierGenericFTPTypeId",
                                    emptyOption: {
                                        isThereEmptyOption: false,
                                        emptyOptionDisabled: false
                                    }
                                }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                isReadOnly={!isEditableForm}
                                readOnlyOptions={{ valueToShow: el?.CarrierGenericFTPTypeName }}
                            />
                        </Grid>
                        {/* Protocol Type */}
                        <Grid item xs={12} sm={12} md={6} className="max-w-100 mx-0">
                            <GenericFormikInput
                                label={'Protocol Type'}
                                name={`CarrierGenericFTPs[${index}].CarrierGenericFTPProtocolTypeId`}
                                type={"optionList"}
                                isRequired={true}
                                optionsListConfig={{
                                    optionsListMenu: carrierGenericFTPProtocolsTypes || [],
                                    nameKey: "CarrierGenericFTPProtocolTypeName",
                                    valueKey: "CarrierGenericFTPProtocolTypeId",
                                    emptyOption: {
                                        isThereEmptyOption: false,
                                        emptyOptionDisabled: false
                                    }
                                }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                isReadOnly={!isEditableForm}
                                readOnlyOptions={{ valueToShow: el?.CarrierGenericFTPProtocolTypeName }}
                            />
                        </Grid>
                        {/* SSH Keys //incase protocol type is SSH*/}
                        {values?.CarrierGenericFTPs[index]?.CarrierGenericFTPProtocolTypeId == "4" &&
                            <Grid item xs={12} sm={12} md={6} className="max-w-100 mx-0">
                                <GenericFormikInput
                                    label={"SSH Keys"}
                                    name={`CarrierGenericFTPs[${index}].CarrierSSHKeyId`}
                                    type={"optionList"}
                                    isRequired={true}
                                    optionsListConfig={{
                                        optionsListMenu: carrierSSHKeys || [],
                                        nameKey: "CarrierSSHKeyName",
                                        valueKey: "CarrierSSHKeyId",
                                        emptyOption: {
                                            isThereEmptyOption: false,
                                            emptyOptionDisabled: false
                                        }
                                    }}
                                    formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                    isReadOnly={!isEditableForm}
                                    readOnlyOptions={{ valueToShow: el?.CarrierSSHKeyName }}
                                />
                            </Grid>
                        }
                        {/* Site */}
                        <Grid item xs={12} sm={12} md={6} className="max-w-100 mx-0">
                            <GenericFormikInput label={"Site"} name={`CarrierGenericFTPs[${index}].Site`} type={"text"} isRequired={true} formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }} isReadOnly={!isEditableForm} readOnlyOptions={{ valueToShow: el?.Site }} />
                        </Grid>
                        {/* Port */}
                        <Grid item xs={12} sm={12} md={6} className="max-w-100 mx-0">
                            <GenericFormikInput label={"Port"} name={`CarrierGenericFTPs[${index}].Port`} type={"number"} isRequired={true} formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }} isReadOnly={!isEditableForm} readOnlyOptions={{ valueToShow: el?.Port }} />
                        </Grid>
                        {/* User Name */}
                        <Grid item xs={12} sm={12} md={6} className="max-w-100 mx-0">
                            <GenericFormikInput
                                label={"User Name"}
                                name={`CarrierGenericFTPs[${index}].Username`}
                                type={"text"}
                                isRequired={true}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                isReadOnly={!isEditableForm}
                                readOnlyOptions={{ valueToShow: el?.Username }}
                            />
                        </Grid>
                        {/* //incase protocol type is SSH*/}
                        {values?.CarrierGenericFTPs[index]?.CarrierGenericFTPProtocolTypeId != "4" &&
                            <Grid item xs={12} sm={12} md={6} className="max-w-100 mx-0">
                                <GenericFormikInput
                                    label={"Password"}
                                    name={`CarrierGenericFTPs[${index}].Password`}
                                    type={"password"}
                                    isRequired={true}
                                    formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                    isReadOnly={!isEditableForm}
                                    readOnlyOptions={{ valueToShow: `********` }}
                                />
                            </Grid>
                        }
                        {/* Directory */}
                        <Grid item xs={12} sm={12} md={6} className="max-w-100 mx-0">
                            <GenericFormikInput
                                label={"Directory"}
                                name={`CarrierGenericFTPs[${index}].Directory`}
                                type={"text"}
                                isRequired={true}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                isReadOnly={!isEditableForm}
                                readOnlyOptions={{ valueToShow: el?.Directory }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default CarrierTabsTechnicalInfoGenericFTPSingleItem;
