import React from 'react'
import { FormControl, Divider, Paper, Button, Select, MenuItem, Zoom } from "@material-ui/core";
import { useAuthentication } from "../../Data/Authentication";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { CustomTooltip } from "../Layout/Tooltip";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Auth } from 'aws-amplify';
import { SuccessModal } from "../Modals/SuccessModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { useHistory } from "react-router-dom";

const ChangePassword = ({ }) => {

    const { openLoader, closeLoader } = useLoader();
    const { authUser } = useAuthentication();
    const dialog = useModalDialog(0);
    const routeHistory = useHistory();
    const [newPassword, setNewPassword] = React.useState("");
    const [oldPassword, setOldPassword] = React.useState("");
    const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
    const [newPasswordValidation, setNewPasswordValidation] = React.useState("");
    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [error, setError] = React.useState("");
    const _colorOptions = {
        loggedInUser: "var(--orange)",
    };
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    React.useEffect(() => {
        if (newPassword !== confirmNewPassword) {
            setNewPasswordValidation("Your password and confirmation password are not matched");
        } else {
            setNewPasswordValidation("");
        }
    }, [confirmNewPassword, newPassword])


    const handelNewPassword = (e) => {
        setNewPassword(e.target.value);
    }
    const handelConfirmNewPassword = (e) => {
        setConfirmNewPassword(e.target.value);
    }

    const handelOldPassword = (e) => {
        setOldPassword(e.target.value);
    }

    const submitChangePassword = (e) => {
        e.preventDefault();

        if (newPassword !== confirmNewPassword) return;
        openLoader();
        Auth.currentAuthenticatedUser().then(user => {
            return Auth.changePassword(user, oldPassword, newPassword);
        })
            .then(data => {
                closeLoader();
                setOldPassword("");
                setConfirmNewPassword("");
                setNewPassword("");
                SuccessModal(dialog, {
                    title: 'Success',
                    body: `Password has been changed successfully.`,
                }, () => { routeHistory.push("/") });
            })
            .catch(err => {
                console.log(err);
                if (err.message == "Incorrect username or password.") {
                    setError("Incorrect current password.");
                } else {
                    setError(err.message);
                }
                closeLoader();
            });
    }

    return (



        <div className='outer-card-settings'>
            <form onSubmit={submitChangePassword} >
                <div className="row">
                    <div className="col-xl-12 alignvh-center">
                        <h6 className="form-title">Old Password </h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-12  alignvh-center">
                        {!userRoles?.includes("6") ? (<>
                            <input required type={showOldPassword ? "text" : "password"} placeholder="Old Password" className="text-input w-100" value={oldPassword} onChange={handelOldPassword} />
                            <span style={{ right: "20px", position: "absolute", cursor: "pointer" }}>
                                {showOldPassword ? <Visibility onClick={() => setShowOldPassword(false)} /> : <VisibilityOff onClick={() => setShowOldPassword(true)} />}
                            </span>
                        </>
                        ) :
                            <div className="textfield-div">
                                {authUser?.attributes["custom:newPassword"]}
                            </div>
                        }
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-12 alignvh-center">
                        <h6 className="form-title">New Password </h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-12  alignvh-center">
                        {!userRoles?.includes("6") ? (<>
                            <input required type={showNewPassword ? "text" : "password"} placeholder="New Password" className="text-input w-100" value={newPassword} onChange={handelNewPassword} />
                            <span style={{ right: "20px", position: "absolute", cursor: "pointer" }}>
                                {showNewPassword ? <Visibility onClick={() => setShowNewPassword(false)} /> : <VisibilityOff onClick={() => setShowNewPassword(true)} />}
                            </span>
                        </>
                        ) : <div className="textfield-div">
                            {authUser?.attributes["custom:newPassword"]}
                        </div>
                        }
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-12 alignvh-center">
                        <h6 className="form-title">Confirm New Password </h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-12 alignvh-center">
                        {!userRoles?.includes("6") ? (
                            <>
                                <input required type={showConfirmNewPassword ? "text" : "password"} placeholder="Confirm New Password" className="text-input w-100" value={confirmNewPassword} onChange={handelConfirmNewPassword} />
                                <span style={{ right: "20px", position: "absolute", cursor: "pointer" }}>
                                    {showConfirmNewPassword ? <Visibility onClick={() => setShowConfirmNewPassword(false)} /> : <VisibilityOff onClick={() => setShowConfirmNewPassword(true)} />}
                                </span>
                            </>
                        ) : (
                            <div className="textfield-div">
                                {authUser?.attributes["custom:newPassword"]}
                            </div>
                        )}
                    </div>


                    <div className="mt-2 col-xl-12 text-danger" hidden={!requestSubmitted}>
                        {newPasswordValidation}
                    </div>
                    {error && error !== "" && (
                        <>

                            <div className="mt-2 col-xl-12 text-danger" >
                                <label>Error: <span>{error}</span></label>
                            </div>
                        </>
                    )}
                </div>


                <div className="w-100 text-right mt-3 pt-3">
                    <Button type="submit" variant="contained" className="blue-btn"
                        onClick={() => { setRequestSubmitted(true); }}
                        disabled={userRoles?.includes("6")} // disabled in case role is client
                    >Change Password</Button>
                </div>

            </form>
        </div>

    )
}

export default ChangePassword