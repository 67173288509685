import React from "react";
import { useLoader } from "../../Layout/Loader";
import * as APIs from "../../../Data/APIs";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { Button, Checkbox, TextField, Divider } from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Moment from 'moment';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useAuthentication } from "../../../Data/Authentication";
import { useModalDialog } from '../../Layout/ModalDialogCustomized';
import { FailureModal } from '../../Modals/FailureModal';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { styled } from '@mui/system';
import Truncate from "react-truncate";
import dataReadyIconSVG from "../../../Icons/dataReadyIcon.svg";
import editPenIcon from "../../../Icons/editPen.svg";
import saveIconSVG from "../../../Icons/save.svg";
import oeDataReadyDisabled from "../../../Icons/oeDataReadyDisabled.svg";
import oeDataReadyEnabled from "../../../Icons/oeDataReadyEnabled.svg";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function HomeOERequestsSingleRecordTable({
    oeRequest,
    index,
    getOERequestsQuickData,
    getOERequestsAdvancedData,
    quickFiltersClicked,
    advancedFiltersClicked,
    groupsAndOEContactsList,
    selectedOEs,
    onSelectedOEs,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);

    const { openLoader, closeLoader } = useLoader();
    const [editIsolvedContact, setEditIsolvedContact] = React.useState(false);
    const [isolvedContact, setIsolvedContact] = React.useState("");
    const [checkedOEs, setCheckedOEs] = React.useState([]);

    const getTitleSendOEButton = () => {
        if (oeRequest?.OERequestStatusID === 4) {
            return <div>This button is disabled because OE request is Blocked</div>;
        } else if (oeRequest?.OERequestStatusID === 3) {
            return <div>This button is disabled because OE request is Cancelled</div>;
        } else if (Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate) {
            return <div>Plan Year Start Date has passed, updates will go through the weekly files</div>;
        } else if ((new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45) {
            return <div>The earliest for an OE file to be sent is 45 days before the PYSD to capture terminations as expected</div>;
        }
        else {
            return <div>OE Data is Ready</div>;
        }
    }

    const handleOEsSelectionChange = (value) => (e) => {
        setCheckedOEs(e.target.checked);
    }


    const handleOEsSelectionClick = (value) => (event) => {
        let selectedOEsArray = selectedOEs;
        if (!value || value === "") selectedOEsArray = [];
        else if (selectedOEsArray.indexOf(value) >= 0) {
            selectedOEsArray.splice(selectedOEsArray.indexOf(value), 1);
        }
        else {
            selectedOEsArray.push(value);
        }
        onSelectedOEs([...selectedOEsArray]);
    }

    const editIsolvedContactFn = () => {
        openLoader();
        const editIsolvedContactRequest = {
            OERequestID: oeRequest?.Id,
            IsolvedContact: isolvedContact?.CognitoID,
        };

        APIs.editOEClientContact(editIsolvedContactRequest).then((r) => {
            if (quickFiltersClicked) getOERequestsQuickData(); else getOERequestsAdvancedData();
            setEditIsolvedContact(false);
            closeLoader();
        });
    }

    React.useEffect(() => {
        let filterediSolvedContact = groupsAndOEContactsList.filter(s => s?.CognitoID === oeRequest?.IsolvedContactCognitoID)[0];
        if (oeRequest?.IsolvedContact !== "" && oeRequest?.IsolvedContact !== null) {
            setIsolvedContact(filterediSolvedContact);
        }
        else {
            setIsolvedContact(null);
        }
    }, [editIsolvedContact === true, oeRequest, groupsAndOEContactsList])

    const isIsovedSuperAdminOrSuperAdmin = () => {
        //attached action ID to isolvedSuperAdmin
        return userActions?.includes("57") || userActions?.includes("20");
    }

    const handleExpectedChanges = (OERequestId) => {
        openLoader();
        APIs.getOERequestDetails(OERequestId).then(d => {
            if (d?.success == 1) {
                dialog.openDialog(
                    <div>
                        <h5 className="black1 fw-700">Expected Changes</h5>
                        {/* <Divider /> */}
                    </div>,
                    <>
                        <textarea
                            type="text"
                            className="action-item-textarea textfield-div"
                            placeholder="Expected Changes"
                            rows={7}
                            disabled
                            value={d?.data?.OEChanges}
                            style={{ minWidth: '900px' }}
                        />
                    </>

                )
            }
        })
            .catch(err => console.log('err', err))
            .finally(() => {
                closeLoader();
            })
    }
    return (
        <div className="row table-row">
            {isIsovedSuperAdminOrSuperAdmin() && <div className="col table-cell first-cell" style={{ maxWidth: "7%" }}
            >
                <CustomTooltip TransitionComponent={Zoom} title={oeRequest?.SendOEFileDone === 1 ? `OE Data is Ready` : getTitleSendOEButton()}>
                    <Checkbox
                        disabled={
                            // !(userActions?.includes("57") ||
                            // userActions?.includes("20") )||
                            oeRequest?.SendOEFileDone === 1 ||
                            oeRequest?.OERequestStatusID === 3 ||
                            oeRequest?.OERequestStatusID === 4 ||
                            Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate ||
                            (new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                        }
                        checkedIcon={<DoneOutlineIcon />}

                        value={oeRequest}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checked={selectedOEs && selectedOEs.indexOf(oeRequest) >= 0}
                        onChange={handleOEsSelectionChange(oeRequest)}
                        onClick={handleOEsSelectionClick(oeRequest)}
                        color="default"
                    />
                </CustomTooltip></div>}
            <div className="col table-cell  first-cell">
                {!userRoles?.includes("6") ?
                    <a href={`/OERequests/ViewConnectivityRequest/${oeRequest?.ConnectivityRequestID}/ViewOERequest/${oeRequest?.Id}`}>{oeRequest?.Id}</a> :
                    <>{oeRequest?.Id}</>
                }
            </div>
            <div className="col table-cell " style={{ wordBreak: "break-word", fontSize: "13px" }}>
                {!userRoles?.includes("6") ?
                    <a href={`/GroupRequests/ViewGroupRequest/${oeRequest?.GroupRequestID}`}>{oeRequest?.PeoName ? oeRequest?.PeoName : oeRequest?.GroupName}</a> :
                    <>{oeRequest?.PeoName ? oeRequest?.PeoName : oeRequest?.GroupName}</>
                }
            </div>
            <div className="col table-cell">
                {!userRoles?.includes("6") ?
                    <a href={`/ConnectivityRequests/ViewConnectivityRequest/${oeRequest.ConnectivityRequestID}`}>{oeRequest?.CarrierName}</a> :
                    <>{oeRequest?.CarrierName}</>
                }
            </div>
            <div className="col table-cell">
                {oeRequest?.UpdatedGroupStructure === 1 ? "Yes" : "No"}
            </div>
            <div className="col table-cell  date">
                {Moment(oeRequest?.ClientDataExpectedDate).format('MM/DD/YYYY')}
            </div>
            {!userRoles?.includes("6") &&
                <div className="col table-cell">
                    {editIsolvedContact && (userActions?.includes("47") || userActions?.includes("20")) ? (
                        <>
                            <Autocomplete
                                options={groupsAndOEContactsList?.sort((s1, s2) => {
                                    return s2.IsEnabled - s1.IsEnabled
                                })}
                                autoHighlight
                                groupBy={(option) => option.IsEnabled}
                                getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                onChange={(e, newValue) => {
                                    setIsolvedContact(newValue);
                                }}
                                value={isolvedContact}
                                getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Button onClick={editIsolvedContactFn} color="inherit">
                                <svg width="15" height="21" viewBox="0 0 22 21" >
                                    <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                </svg>
                            </Button>
                        </>
                    ) : (
                        <>
                            {oeRequest?.IsolvedContact !== null ? oeRequest?.IsolvedContact : ""}
                            {(userActions?.includes("47") || userActions?.includes("20")) && (
                                <Button onClick={() => setEditIsolvedContact(true)} color="inherit">
                                    <svg width="20" height="20" viewBox="0 0 20 20" >
                                        <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                    </svg>

                                </Button>
                            )}
                        </>
                    )}
                </div>
            }
            {/*  */}
            {(userRoles?.includes("6") || userRoles?.includes("7"))
                &&
                <div className="col table-cell">
                    {/* <RemoveRedEyeIcon className="m-auto" role="button" /> */}
                    <Truncate lines={3} ellipsis={<span>... <a className="fs-13" role="button" style={{ color: "#2c87f0" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleExpectedChanges(oeRequest?.Id);
                        }}>Read More</a></span>}>
                        {oeRequest?.OEChanges}
                    </Truncate>
                </div>
            }
            <div className="col table-cell">  {/*Draft */}
                {oeRequest?.IsDraftOERequest === 1 ? "Yes" : "No"}
            </div>
            <div className="col table-cell date">
                {oeRequest?.OEFileSubmissionDate !== null && oeRequest?.OEFileSubmissionDate !== "0000-00-00" ? Moment(oeRequest?.OEFileSubmissionDate).format('MM/DD/YYYY') : ""}
            </div>
            <div className="col table-cell  " style={{ justifyContent: "center" }}>
                {(userActions?.includes("57") ||
                    userActions?.includes("20")) ? (
                    <CustomTooltip TransitionComponent={Zoom} title={oeRequest?.SendOEFileDone === 1 ? `OE Data is Ready` : getTitleSendOEButton()}>
                        <span>
                            <button disabled={
                                selectedOEs.length != 0 ||
                                oeRequest?.SendOEFileDone === 1 ||
                                oeRequest?.OERequestStatusID === 3 ||
                                oeRequest?.OERequestStatusID === 4 ||
                                Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate ||
                                (new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                            }
                                style={(selectedOEs.length != 0 || oeRequest?.SendOEFileDone === 1 || oeRequest?.OERequestStatusID === 3 || oeRequest?.OERequestStatusID === 4 || Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate) ? { pointerEvents: "none", } : {}} className="sendOEButtonDashboard" onClick={(e) => {
                                    openLoader();
                                    APIs.sendOEFile({ OERequestIDs: [oeRequest.Id] }).then(r => {
                                        if (r.success === "1") {
                                            openLoader();
                                            APIs.getOERequestDetails(oeRequest.Id).then(r => {
                                                openLoader();
                                                e.preventDefault();
                                                let editOEBody = {
                                                    OERequestID: r.data.Id,
                                                    PlanYearStartDate: r.data.PlanYearStartDate,
                                                    ClientDataExpectedDate: r.data.ClientDataExpectedDate,
                                                    OEType: r.data.OEType,
                                                    OEChanges: r.data.OEChanges,
                                                    ISolvedDataChanges: r.data.ISolvedDataChanges,
                                                    UpdatedGroupStructure: r.data.UpdatedGroupStructure,
                                                    IsolvedContact: r.data.IsolvedContactCognitoID,
                                                    OERequestStatusID: r.data.OERequestStatusID,
                                                    OERequestPhaseID: 4,
                                                    AssignedTo: r.data.AssignedToCognitoID,
                                                    OEFileSubmissionDate: r.data.OEFileSubmissionDate,
                                                    NoAttachmentUpdate: true,
                                                    IsDraftOERequest: r.data?.IsDraftOERequest,
                                                    CanResumeProducionPYSD: r.data?.CanResumeProducionPYSD ?? 0
                                                }
                                                // Api for edit OE Request 
                                                APIs.editOERequest(editOEBody).then(r => window.location.reload())
                                            })
                                        } else {
                                            closeLoader();
                                            FailureModal(dialog, {
                                                title: 'Failure',
                                                body: r.msg,
                                            })
                                        }
                                    })
                                }}>
                                {/* <ForwardToInboxIcon style={oeRequest?.SendOEFileDone === 1 ? { color: "var(--green)", fontSize: "30px" } : { cursor: "pointer", fontSize: "30px" }} /> */}
                                {oeRequest?.SendOEFileDone === 1 ?
                                    <svg width="19" height="13" viewBox="0 0 19 13" >
                                        <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                                    </svg> :
                                    oeRequest?.OERequestStatusID === 3 ||
                                        oeRequest?.OERequestStatusID === 4 ||
                                        Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate ||
                                        (new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                        </svg> :
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                        </svg>
                                }
                            </button>
                        </span>
                    </CustomTooltip>
                ) : (
                    <CustomTooltip TransitionComponent={Zoom} title={`OE Data is Ready`}>
                        {/* <ForwardToInboxIcon style={oeRequest?.SendOEFileDone === 1 ? { color: "var(--green)", fontSize: "30px" } : { fontSize: "30px" }} /> */}
                        {oeRequest?.SendOEFileDone === 1 ?
                            <svg width="19" height="13" viewBox="0 0 19 13" >
                                <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                            </svg> :
                            oeRequest?.OERequestStatusID === 3 ||
                                oeRequest?.OERequestStatusID === 4 ||
                                Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate ||
                                (new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                <svg width="15" height="15" viewBox="0 0 15 15" >
                                    <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                </svg> :
                                <svg width="15" height="15" viewBox="0 0 15 15" >
                                    <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                </svg>
                        }
                    </CustomTooltip>
                )}
            </div>
        </div>
    )
}

export default HomeOERequestsSingleRecordTable;
