import React from 'react'
import GenericFormikInput from '../../../../GenericFormikInput'
import { Button } from '@material-ui/core'
import deleteForeverSVG from "../../../../../Icons/trash-xmark.svg";

const RelationshipContactsTableRow = ({ carrierRelationshipContact, index, mode, formikObject, ...props }) => {
    const { touched, errors, values, setFieldValue, handleBlur, submitCount } = formikObject || {}
    return (
        <div className="row table-row">
            <div className="col px-0">
                <div className="row">
                    <div className="col-2 table-cell first-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierRelationshipContacts[${index}].FirstName`}
                            type={'text'}
                            placeholder='First Name'
                            isRequired={true}
                            isReadOnly={mode==="view"}
                            readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.FirstName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">                    
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].LastName`}
                                type={'text'}
                                placeholder='Last Name'
                                isRequired={true}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.LastName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].JobTitle`}
                                type={'text'}
                                placeholder='Job Title'
                                isRequired={false}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.JobTitle,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].Email`}
                                type={'text'}
                                placeholder='Email'
                                isRequired={true}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.Email,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].PhoneNumber`}
                                type={'text'}
                                placeholder='Phone Number'
                                isRequired={false}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.PhoneNumber,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].Notes`}
                                type={'textArea'}
                                placeholder='Notes'
                                isRequired={false}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.Notes,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                </div>
            </div>
                { mode !== "view" && ( 
            <div className="col-1 px-0 d-flex">
                        <Button
                            role="button"
                            className="icon-btn m-auto"
                            style={{ pointerEvents: "auto" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const updatedCarrierRelationshipContacts = [...values.CarrierRelationshipContacts];
                                updatedCarrierRelationshipContacts.splice(index, 1);
                                setFieldValue('CarrierRelationshipContacts', updatedCarrierRelationshipContacts);
                            }}>
                            <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                            </svg>
                        </Button>
            </div>
                        )}

        </div>
    )
}

export default RelationshipContactsTableRow
