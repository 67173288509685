import React from 'react';
import moment from 'moment';
import GenericFormikInput from '../../../../GenericFormikInput'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import editPenIcon from "../../../../../Icons/editPen.svg";
import cancelIconSVG from "../../../../../Icons/CancelIcon.svg";
import deleteForeverSVG from "../../../../../Icons/trash-xmark.svg";
import saveIconSVG from "../../../../../Icons/save.svg";
import { CustomTooltip } from "../../../../Layout/Tooltip";
import { Button, Zoom } from "@material-ui/core";
import * as APIs from '../../../../../Data/APIs';
import { useModalDialog } from "../../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../../Modals/SuccessModal";
import { FailureModal } from "../../../../Modals/FailureModal";
import { useLoader } from "../../../../Layout/Loader";


const validationSchema = Yup.object().shape({
    CarrierLegalDocumentTypeId: Yup.string().required('This Field is Required'),
    AttachmentName: Yup.string().required('This Field is Required'),
    LegalDocumentSignDate: Yup.date()
    .required('This Field is required')
    .typeError("Invalid date format"),
    LegalDocumentSignor: Yup.string().trim().required('This Field is Required'),
    
  });


const AgreementDocumentTableRow = ({ agreementDocument, legalDocumentTypes, index,currentMode = "editOrView", carrierDetails, addNewDocumentClicked, setAddNewDocumentClicked, getSingleCarrierHandler, ...props }) => {
    const [isEditable , setIsEditable]= React.useState(false);
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [decodedString, setDecodedString] = React.useState("");

    React.useEffect(()=>{
        if (currentMode == 'add'){
            setIsEditable(true)
    }
    },[currentMode])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }
    
    const handleSubmit = async (values, { setSubmitting }) => {
        let editCarrierLegalInfoBody;

        if (addNewDocumentClicked) {
            try {
                openLoader(); 
                const file = values?.AttachmentName;
                
                if (!file || !(file instanceof File)) {
                    console.log('File value:', file);
                    throw new Error('No valid file selected');
                }

                // Encode file to base64
                const base64File = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const base64 = reader.result.split(',')[1];
                        resolve(base64);
                    };
                    reader.onerror = error => reject(error);
                });

                const attachmentValue = file.name || '';
                const attachedDocumentInfo = {
                    Extension: attachmentValue ? attachmentValue.substring(attachmentValue.lastIndexOf('.') + 1) : '',
                    Value: base64File,
                }

                const uploadResponse = await APIs.uploadCarrierLegalDocumentAttachment(attachedDocumentInfo);
                editCarrierLegalInfoBody = {
                    CarrierID: carrierDetails?.CarrierID,
                    CarrierLegalDocumentTypeId: values?.CarrierLegalDocumentTypeId ? +(values?.CarrierLegalDocumentTypeId) : null,
                    LegalDocumentSignDate: values?.LegalDocumentSignDate? moment(values?.LegalDocumentSignDate).format('YYYY-MM-DD') : null,
                    LegalDocumentSignor: values?.LegalDocumentSignor ? values?.LegalDocumentSignor : "",
                    AttachmentName: file.name,
                    AttachmentExtension: attachmentValue ? attachmentValue.substring(attachmentValue.lastIndexOf('.') + 1) : '',
                    GUID: uploadResponse?.GUID,
                }
                await APIs.editCarrierLegalInfo(editCarrierLegalInfoBody);
                await getSingleCarrierHandler();
                setIsEditable(false);
                setAddNewDocumentClicked(false);
            } catch (error) {
                console.error('Error submitting document:', error);
            } finally {
                closeLoader(); 
                setSubmitting(false);
            }
        } else {
            try {
                openLoader(); 
                editCarrierLegalInfoBody = {
                    CarrierID: carrierDetails?.CarrierID,
                    CarrierLegalInfoId: values?.CarrierLegalInfoId ? +(values?.CarrierLegalInfoId) : "",
                    CarrierLegalDocumentTypeId: values?.CarrierLegalDocumentTypeId ? +(values?.CarrierLegalDocumentTypeId) : null,
                    LegalDocumentSignDate: values?.LegalDocumentSignDate? moment(values?.LegalDocumentSignDate).format('YYYY-MM-DD') : null,
                    LegalDocumentSignor: values?.LegalDocumentSignor ? values?.LegalDocumentSignor : "",
                    AttachmentName: agreementDocument?.AttachmentName,
                    AttachmentExtension: agreementDocument?.AttachmentExtension,
                    GUID: agreementDocument?.GUID,
                }
                await APIs.editCarrierLegalInfo(editCarrierLegalInfoBody);
                await getSingleCarrierHandler();
                setIsEditable(false);
            } catch (error) {
                console.error('Error updating document:', error);
            } finally {
                closeLoader(); 
                setSubmitting(false);
            }
        }
    };

    const handleDeleteAttachmentConfirm = () => {
        openModal(0, handleDeleteAttachment, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this Agreement?`,
        })
    }

    const handleDeleteAttachment = async () => {
        openLoader();
        const deleteBody = {
            CarrierID: carrierDetails?.CarrierID,
            CarrierLegalInfoId: +(agreementDocument?.CarrierLegalInfoId),
            AttachmentName: agreementDocument?.AttachmentName,
            AttachmentExtension: agreementDocument?.AttachmentExtension,
            GUID: agreementDocument?.GUID,
            isDelete: 1
        }
        APIs.editCarrierLegalInfo(deleteBody).then((r) => {
            getSingleCarrierHandler();
            setIsEditable(false);
            closeLoader(); 
        });
    };

    const handleDownloadFile = async () => {
        try {
            openLoader();

            // Call the API to get the file data (base64 string)
            const response = await APIs.getCarrierSingleAttachment({
                CarrierID: carrierDetails?.CarrierID,
                GUID: agreementDocument?.GUID,
                attachmentExtension: agreementDocument?.AttachmentExtension,
            });
    
            // Assuming `response.data` contains the base64-encoded string of the file
            const decodedString = response?.data;
    
            if (!decodedString) {
                console.error("Failed to retrieve the file data.");
                return;
            }
    
            // Convert base64 to binary and trigger the download
            const binaryString = window.atob(decodedString); // Decode base64 string to binary
            const binaryLen = binaryString.length;
            const ab = new ArrayBuffer(binaryLen); // Create an ArrayBuffer
            const ia = new Uint8Array(ab); // Create a Uint8Array view of the ArrayBuffer
    
            // Populate the Uint8Array with the binary data
            for (let i = 0; i < binaryLen; i++) {
                ia[i] = binaryString.charCodeAt(i);
            }
    
            // Create a Blob from the ArrayBuffer
            const blob = new Blob([ab]);
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob); // Create a download URL from the Blob
    
            // Set the filename and trigger the download
            link.href = url;
            link.download = agreementDocument?.AttachmentName || "downloaded-file"; // Set the filename
            document.body.appendChild(link); // Append the link to the body (required for download in some browsers)
            link.click(); // Trigger the download
            document.body.removeChild(link); // Remove the link after download
            URL.revokeObjectURL(url); // Revoke the object URL to free up memory
    
        } catch (error) {
            console.error("Error downloading the file:", error);
        } finally {
            closeLoader();
        }
    };
    

    return (
        <>
            <Formik
                initialValues={agreementDocument}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {({ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm, handleSubmit }) =>
                (
                    <Form className='d-flex flex-column'>
                        <div className="row table-row" id='agreement-documents'>
                            <div className="col-3 table-cell first-cell d-flex flex-column word-break-all w-100">
                                
                                    <GenericFormikInput
                                        name={`CarrierLegalDocumentTypeId`}
                                        type={'optionlist'}
                                        optionsListConfig={{
                                            optionsListMenu: legalDocumentTypes || [],
                                            nameKey: 'CarrierLegalDocumentTypeName',
                                            valueKey: 'CarrierLegalDocumentTypeId',
                                        }}
                                        isRequired={true}
                                        isReadOnly={!isEditable}
                                        readOnlyOptions={{
                                            valueToShow: agreementDocument?.CarrierLegalDocumentTypeName,
                                            extraCssClasses: "remove-textfield-div-style" 
                                        }}
                                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                    />
                                
                            </div>
                            <div className="col-3 table-cell w-100 d-flex flex-column" >
                                <GenericFormikInput
                                    type="attachment"
                                    name={`AttachmentName`}
                                    formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                    isRequired={true}
                                    isReadOnly={currentMode !== "add"}
                                    readOnlyOptions={{
                                        valueToShow: <span 
                                        onClick={handleDownloadFile} 
                                        style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                                    >
                                        {agreementDocument?.AttachmentName}
                                    </span>,
                                        extraCssClasses: "remove-textfield-div-style" 
                                    }}
                                    attachmentConfig={{
                                        maxFileSize: 20 * 1024 * 1024,
                                        dropzoneText: 'Attach File',
                                        filesLimit: 1,
                                        showPreviewsInDropzone: false,
                                        onFileDelete: () => {
                                        },
                                        containerClass: 'containerClassDrag h-100',
                                        extraCssClasses:'w-100'
                                    }}
                                />
                            </div>
                            <div className="col-3 table-cell d-flex flex-column word-break-all">
                                
                                    <GenericFormikInput
                                        name={`LegalDocumentSignDate`}
                                        type={'datetime'}
                                        isRequired={true}
                                        isReadOnly={!isEditable}
                                        format="yyyy-MM-dd"
                                        readOnlyOptions={{
                                            valueToShow: agreementDocument?.LegalDocumentSignDate && moment(agreementDocument?.LegalDocumentSignDate).format('MM/DD/YYYY'),
                                            extraCssClasses: "remove-textfield-div-style" 
                                        }}
                                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                    />
                                
                            </div>
                            <div className="col-2 table-cell d-flex flex-column word-break-all">
                                
                                    <GenericFormikInput
                                        name={`LegalDocumentSignor`}
                                        type={'text'}
                                        isRequired={true}
                                        isReadOnly={!isEditable}
                                        readOnlyOptions={{
                                            valueToShow: agreementDocument?.LegalDocumentSignor,
                                            extraCssClasses: "remove-textfield-div-style" 
                                        }}
                                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                    />
                                
                            </div>
                            <div className="col-1 table-cell d-flex flex-column">
                            {isEditable ? (
                            <div>
                                <div className="d-flex" style={{ justifyContent: "center" }}>
                                    <Button 
                                        className="icon-btn" 
                                        color="inherit" 
                                        onClick={handleSubmit}
                                        type="button"
                                    >
                                        <svg width="15" height="21" viewBox="0 0 22 21" >
                                            <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                        </svg>
                                    </Button>
                                    <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                                        <Button 
                                            className="pl-0 icon-btn" 
                                            color="inherit" 
                                            onClick={() => {
                                                if (currentMode != "add"){
                                                    setIsEditable(false);
                                                    resetForm();
                                                }
                                                else{
                                                    setAddNewDocumentClicked(false); 
                                                    resetForm();
                                                }
                                            }}
                                        >
                                            <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                                <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                            </svg>
                                        </Button>
                                    </CustomTooltip>
                                </div>
                                {currentMode !== "add" && (
                                    <CustomTooltip TransitionComponent={Zoom} title="Delete">
                                        <span>
                                            <Button 
                                                className="icon-btn" 
                                                color="inherit" 
                                                onClick={handleDeleteAttachmentConfirm}
                                            >
                                                <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                                                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                </svg>
                                            </Button>
                                        </span>
                                    </CustomTooltip>
                                )}
                            </div>
                        ) : (
                            <Button onClick={() => setIsEditable(true)} color="inherit">
                                <svg width="20" height="20" viewBox="0 0 20 20" >
                                    <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                </svg>
                            </Button>
                        )}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AgreementDocumentTableRow
