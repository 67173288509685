import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import GenericTable from "../../../Layout/GenericTable";
import Pagination from "../../../Layout/Pagination";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";

const CarrierTabsConnectivityRequests = ({ carrierDetails, ...props }) => {
  const { carrierIDURL } = useParams();
  const { openLoader, closeLoader } = useLoader();
  const [carrierConnectivityRequests, setCarrierConnectivityRequests] = React.useState([]);
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(30);
  const [totalCount, setTotalCount] = React.useState(0);

  useEffect(() => {
    const paginationObject = {
      CarrierID: carrierIDURL,
      Limit: pageSize,
      PageNumber: selectedPage,
    };

    openLoader();
    APIs.getSingleCarrierCRs(paginationObject)
      .then((res) => {
        const { totalCount, list } = res?.data || {};
        setTotalCount(totalCount);
        setCarrierConnectivityRequests(list);
      })
      .catch((err) =>
        console.log("Getting CRs for Single Carrier Error :", err)
      )
      .finally(() => closeLoader());
  }, [selectedPage, pageSize]);

  const baseColumnsArray = [
    {
      name: "Carrier Name",
      sortable: false,
      id: "CarrierName",
      selector: (c) => carrierDetails?.CarrierName === "Third Party"?`${carrierDetails?.CarrierName} (${c?.ThirdPartyName})`:`${carrierDetails?.CarrierName}`,
    },
    {
      name: "Client Name",
      sortable: false,
      id: "ClientName",
      selector: (c) => <a href={`/GroupRequests/ViewGroupRequest/${c?.GroupRequestID}`}>{c?.GroupName ? c?.GroupName : c?.PeoName}</a>,
    },
    {
      name: "Connectivity Request ID",
      sortable: false,
      id: "CRID",
      selector: (c) => <a href={`/ConnectivityRequests/ViewConnectivityRequest/${c?.ConnectivityRequestID}`}>{c?.ConnectivityRequestID}</a>
    },
    {
      name: "Connectivity Request Type",
      sortable: false,
      id: "CRType",
      selector: (c) => c?.ConnectivityRequestType,
    },
    {
      name: "Connectivity Request Phase",
      sortable: false,
      id: "CRPhase",
      selector: c => <div style={{ wordBreak: "keep-all" }} className={`phase-div 
        ${c?.ConnectivityRequestPhasesName === "Not Started" ? `notStarted` :
            c?.ConnectivityRequestPhasesName === "Requirements Gathering" ? `requirementGathering` :
                c?.ConnectivityRequestPhasesName === "Obtain Customer Dataset" ? `obtainCustomerDataset` :
                    c?.ConnectivityRequestPhasesName === "Resource Assignment" ? `resourceAssignment` :
                        c?.ConnectivityRequestPhasesName === "Dataset Validation" ? `datasetValidation` :
                            c?.ConnectivityRequestPhasesName === "Mapping" ? `mapping` :
                                c?.ConnectivityRequestPhasesName === "Testing" ? `testing` :
                                    c?.ConnectivityRequestPhasesName === "Ready for Production" ? `readyforproduction` : `production`}`}>
        {c?.ConnectivityRequestPhasesName}
    </div>
    },
  ];

  return (
    <React.Fragment>
      <GenericTable
        columns={[...baseColumnsArray]}
        data={carrierConnectivityRequests}
        noDataComponent={
          <div className="no-data-alert">
            <h6>No Results Found!</h6>
          </div>
        }
        onSort={() => {}}
      />
      <div>
        <Pagination
          stateLimit={true}
          pageSize={pageSize}
          page={selectedPage}
          onChange={(t, v) => {
            if (t === 1) setPageSize(v);
            else if (t === 2) setSelectedPage(v);
          }}
          count={totalCount}
        />
      </div>
    </React.Fragment>
  );
};

export default CarrierTabsConnectivityRequests;
