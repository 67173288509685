import React from 'react'
import GenericFormikInput from '../../../../GenericFormikInput'
import { Button } from '@material-ui/core'
import deleteForeverSVG from "../../../../../Icons/trash-xmark.svg";
import { convertBoolToYesNo } from '../../../../../_helper';

const CarrierTabsCarrierContactTableRow = ({ carrierContact, categories, types, mode, formikObject, index, ...props }) => {
    const { touched, errors, values, setFieldValue, handleBlur, submitCount } = formikObject || {}
    return (
        <div className='row table-row with-right-border'>
            <div className="col px-0">
                <div className="row">
                    <div className="col-2 table-cell first-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].FirstName`}
                            type={'text'}
                            placeholder='First Name'
                            isRequired={true}
                            isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.FirstName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].LastName`}
                            type={'text'}
                            placeholder='Last Name'
                            isRequired={true}
                            isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.LastName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].Email`}
                            type={'text'}
                            placeholder='Email'
                            isRequired={true}
                            isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.Email,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].PhoneNumber`}
                            type={'text'}
                            placeholder='Phone Number'
                            isRequired={true}
                            isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.PhoneNumber,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        
                            <GenericFormikInput
                                name={`CarrierContacts[${index}].CarrierContactCategoryID`}
                                type={'optionList'}
                                isRequired={true}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: categories?.find(c => c.CarrierContactCategoryId === carrierContact?.CarrierContactCategoryID)?.CarrierContactCategoryName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                optionsListConfig={
                                    {
                                        optionsListMenu: categories || [],
                                        nameKey: 'CarrierContactCategoryName',
                                        valueKey: 'CarrierContactCategoryId',
                                        emptyOption: {
                                            isThereEmptyOption: true,
                                            emptyOptionDisabled: false,
                                            emptyOptionText: ''
                                        }
                                    }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />

                    </div>
                    <div className="col-1 table-cell d-flex flex-column word-break-all">
                        
                            <GenericFormikInput
                                name={`CarrierContacts[${index}].CarrierContactTypeID`}
                                type={'optionList'}
                                isRequired={true}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: types?.find(c => c.CarrierContactTypeId === carrierContact?.CarrierContactTypeID)?.CarrierContactTypeName,
                                    extraCssClasses: "remove-textfield-div-style p-0" 
                                }
                            }
                                optionsListConfig={
                                    {
                                        optionsListMenu: types || [],
                                        nameKey: 'CarrierContactTypeName',
                                        valueKey: 'CarrierContactTypeId',
                                        emptyOption: {
                                            isThereEmptyOption: true,
                                            emptyOptionDisabled: false,
                                            emptyOptionText: ''
                                        }
                                    }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-1 table-cell d-flex flex-column word-break-all">
                            <GenericFormikInput
                                label=""
                                name={`CarrierContacts[${index}].DisplayOnCRs`}
                                type="switch"
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                isDisabled={false}
                                isReadOnly={mode==="view"}
                                readOnlyOptions={
                                {valueToShow: convertBoolToYesNo(carrierContact?.DisplayOnCRs) ,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                switchConfig={{
                                    color: 'primary',
                                }}
                            />
                    </div>
                </div>
            </div>
                { mode !== "view" && ( 
            <div className="col-1 px-0">
                <div className="table-cell h-100">
                    <Button
                        role="button"
                        className="icon-btn"
                        style={{ pointerEvents: "auto" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            const updatedCarrierContacts = [...values.CarrierContacts];
                            updatedCarrierContacts.splice(index, 1);
                            setFieldValue('CarrierContacts', updatedCarrierContacts);
                        }}

                    >
                        <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                    </Button>
                </div>
            </div>
                )}


        </div>
    )
}

export default CarrierTabsCarrierContactTableRow
