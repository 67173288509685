import React, { useState } from 'react';
import { helperInfoIcon } from '../_helper';
import { FormControl, FormControlLabel, MenuItem, Select, Switch, Input, Checkbox, ListItemText } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import BackupIcon from '@material-ui/icons/Backup';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import deleteForeverSVG from "../Icons/trash-xmark.svg"; // Adjust path as needed
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import calendarIconSVG from "../Icons/fal-calendar-alt.svg";
import { Visibility, VisibilityOff } from '@material-ui/icons';

// ===========================================SAMPLES - multipleSelectDropDown
//    <GenericFormikInput
//                     label="Select Items"
//                     type="multiselect"
//                     name="selectedItems"
//                     formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
//                     isRequired={true}
//                     multiSelectConfig={{
//                      type: "dropdown-multiselect",
//                       optionsListMenu: [
//                         { id: 1, name: 'Option 1' },
//                         { id: 2, name: 'Option 2' },
//                       ],
//                       valueKey: 'id',
//                       nameKey: 'name',
//                       seprator: ' , '
//                     }}
//                   />


// ===================================== SAMPLES - multipleSelectCheckBox
//    <GenericFormikInput
//                     label="Select Items"
//                     type="multiselect"
//                     name="selectedItems"
//                     formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
//                     isRequired={true}
//                     multiSelectConfig={{
//                       type: "multiselect-list",
//                       selectAll: true,
//                       optionsListMenu: [
//                         { id: 1, name: 'Option 1' },
//                         { id: 2, name: 'Option 2' },
//                       ],
//                       valueKey: 'id',
//                       nameKey: 'name',
//                       seprator: ' , '
//                     }}
//                   />

const GenericFormikInput = ({
  label,
  type = 'text',
  name,
  placeholder = '',
  inputClassName = '',
  isRequired = false,
  moreInfo = '',
  textareaConfig = { rows: 3 },
  isDisabled = false,
  isReadOnly = false,
  readOnlyOptions = {
    extraCssClasses: '',
    valueToShow: ''
  },
  formikObject = {},
  switchConfig = null, // Config for the Switch component
  optionsListConfig = {
    optionsListMenu: [],
    nameKey: '',
    valueKey: '',
  },
  multiSelectConfig = null, // Config for the Multi-Select component
  attachmentConfig = {
    maxFileSize: 20 * 1024 * 1024,
    dropzoneText: 'Click here to add file',
    filesLimit: 1,
    showPreviewsInDropzone: false,
    onFileDelete: () => { },
    containerClass: 'containerClassDrag',
    extraCssClasses: ''
  },
  extraOnChange = () => { },
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { touched, errors, values, setFieldValue, handleBlur, submitCount } = formikObject;
  const normalInputValidTypes = ['text', 'number', 'email'];
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  // Enhanced helper function to access nested values
  const getNestedValue = (path, obj) =>
    path
      .replace(/\[(\w+)\]/g, '.$1') // Convert [index] to .index
      .split('.')
      .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);

  const value = getNestedValue(name, values) || '';
  const error = getNestedValue(name, errors);
  const isTouched = getNestedValue(name, touched);

  return (
    <>
      <div className="d-flex">
        {label && (
          <h6 className="form-title">
            {label}
            {isRequired && <span className="text-danger mb-2 ml-2">*</span>}
          </h6>
        )}
        {moreInfo && helperInfoIcon(moreInfo)}
      </div>


      {isReadOnly ? (
        <div className={`textfield-div ${readOnlyOptions?.extraCssClasses}`}>
          {readOnlyOptions?.valueToShow}
        </div>
      ) : (
        <>
          {/* Handle standard input types */}
          {normalInputValidTypes.includes(type.toLowerCase()) && (
            <input
              type={type}
              name={name}
              value={value}
              onChange={(event) => {
                setFieldValue(name, event.target.value);
                if (extraOnChange) extraOnChange(event.target.value)

              }}
              onBlur={handleBlur}
              className={`text-input w-100 ${inputClassName}`}
              placeholder={placeholder}
              disabled={isDisabled}
              readOnly={isReadOnly}
              {...rest}
            />
          )}

          {/* Handle options list */}
          {type.toLowerCase() === 'optionlist' && (
            <FormControl label={label} focused={false} variant="outlined" className="w-100 ml-0-imp">
              <Select
                className="text-dropdown"
                value={getNestedValue(name, values) || ""}
                onChange={(e) => {
                  setFieldValue(name, e.target.value);
                }}
              >
                {optionsListConfig?.emptyOption?.isThereEmptyOption && (
                  <MenuItem value="" disabled={optionsListConfig?.emptyOption?.emptyOptionDisabled}>
                    {optionsListConfig?.emptyOption?.emptyOptionText}
                  </MenuItem>
                )}

                {optionsListConfig?.optionsListMenu?.map((item, index) => (
                  <MenuItem
                    key={`option-${index}`}
                    value={item[optionsListConfig.valueKey]}
                  >
                    {item[optionsListConfig.nameKey] || ""}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Handle Password */}
          {type.toLowerCase() === 'password' && (
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                name={name}
                value={value}
                onChange={(event) => setFieldValue(name, event.target.value)}
                onBlur={handleBlur}
                className={`text-input w-100 ${inputClassName}`}
                placeholder={placeholder}
                disabled={isDisabled}
                readOnly={isReadOnly}
                autocomplete="new-password"
                {...rest}
              />

              <span style={{ right: '12px', position: 'absolute', cursor: 'pointer', top: '5px' }}>
                {showPassword ? (
                  <Visibility className="fs-18" onClick={() => setShowPassword(false)} />
                ) : (
                  <VisibilityOff className="fs-18" onClick={() => setShowPassword(true)} />
                )}
              </span>
            </div>
          )}

          {/* Handle textarea */}
          {type.toLowerCase() === 'textarea' && (
            <textarea
              value={value}
              name={name}
              onChange={(event) => setFieldValue(name, event.target.value)}
              onBlur={handleBlur}
              placeholder={placeholder}
              className={`action-item-textarea w-100 ${inputClassName}`}
              rows={textareaConfig?.rows}
              disabled={isDisabled}
              readOnly={isReadOnly}
              {...rest}
            />
          )}

          {/* Handle Switch */}
          {type.toLowerCase() === 'switch' && (
            <FormControlLabel
              control={
                <Switch
                  checked={getNestedValue(name, values) || false}
                  onChange={(event) => setFieldValue(name, event.target.checked)}
                  disabled={isDisabled}
                  color={switchConfig.color || 'primary'}
                />
              }
              label={switchConfig.label || ''}
            />
          )}

          {/* Handle Multi-Select dropdown */}
          {type.toLowerCase() === 'multiselect' && (
            <>
              {multiSelectConfig?.type === "dropdown-multiselect" &&
                <FormControl
                  focused={false}
                  variant="outlined"
                  className='w-100'
                >
                  <Select
                    className={`multiple-select-dropdown w-100 ${inputClassName}`}
                    multiple
                    value={getNestedValue(name, values) || []}
                    onChange={(e) => setFieldValue(name, e.target.value)}
                    input={<Input />}
                    renderValue={(selected) =>
                      selected
                        .map((val) => {
                          const selectedItem = multiSelectConfig.optionsListMenu.find(
                            (item) => item[multiSelectConfig.valueKey] === val
                          );
                          return selectedItem?.[multiSelectConfig.nameKey] || val;
                        })
                        .join(multiSelectConfig?.seprator || ', ')
                    }
                  >
                    {multiSelectConfig?.optionsListMenu?.map((item, index) => (
                      <MenuItem key={`multi-select-${index}`} value={item[multiSelectConfig.valueKey]}>
                        <Checkbox
                          checked={
                            (getNestedValue(name, values) || []).indexOf(
                              item[multiSelectConfig.valueKey]
                            ) > -1
                          }
                        />
                        <ListItemText primary={item[multiSelectConfig.nameKey]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }

            </>
          )}

          {/* Handle Multi-Select list */}
          {type.toLowerCase() === 'multiselect' && multiSelectConfig && (
            <>
              {multiSelectConfig?.type === "multiselect-list" &&
                <div className={`row plans-list-div container-div p-4 checkbox-list d-flex flex-column ${inputClassName}`}>
                  {/* Select All Checkbox */}
                  {multiSelectConfig?.selectAll && (
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          id={`plans-option-select-all`}
                          checked={
                            getNestedValue(name, values)?.length ===
                            multiSelectConfig.optionsListMenu.length
                          }
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            if (isChecked) {
                              setFieldValue(
                                name,
                                multiSelectConfig.optionsListMenu.map(
                                  (item) => item[multiSelectConfig.valueKey]
                                )
                              );
                            } else {
                              setFieldValue(name, []);
                            }
                          }}
                        />
                      }
                      label="Select All"
                    />
                  )}

                  {/* Render Options */}
                  {multiSelectConfig.optionsListMenu.map((item, index) => (
                    <FormControlLabel
                      key={`checkbox-list-${index}`}
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          id={`checkbox-list-${index}`}
                          value={item[multiSelectConfig.valueKey]}
                          checked={getNestedValue(name, values)?.includes(
                            item[multiSelectConfig.valueKey]
                          )}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const currentValues = getNestedValue(name, values) || [];
                            if (isChecked) {
                              setFieldValue(name, [...currentValues, item[multiSelectConfig.valueKey]]);
                            } else {
                              setFieldValue(
                                name,
                                currentValues.filter((val) => val !== item[multiSelectConfig.valueKey])
                              );
                            }
                          }}
                        />
                      }
                      label={item[multiSelectConfig.nameKey]}
                    />
                  ))}



                </div>
              }
            </>
          )}

          {/* Add new attachment type handler */}
          {type.toLowerCase() === 'attachment' && (
            <>
              {value && value.name ? (
                <div className="fs-13 fw-400 link-style btn-like-link textfield-div">
                  <span>{value.name}</span>
                  <span>
                    <svg
                      width="21"
                      height="24"
                      viewBox="0 0 21 24"
                      className="ml-3 mt-2"
                      onClick={() => {
                        setFieldValue(name, '');
                        attachmentConfig.onFileDelete && attachmentConfig.onFileDelete();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                    </svg>
                  </span>
                  <span className="ml-auto color blue-main-color">
                    <BackupIcon />
                  </span>
                </div>
              ) : (
                <div className={attachmentConfig?.extraCssClasses ? attachmentConfig?.extraCssClasses : ''}>
                  <DropzoneArea
                    maxFileSize={attachmentConfig.maxFileSize}
                    dropzoneParagraphClass={"MuiDropzoneArea-text-updated-gray"}
                    filesLimit={attachmentConfig.filesLimit}
                    dropzoneText={
                      <div className="dz-message fs-14" data-dz-message style={{ display: "grid" }}>
                        <b>{attachmentConfig.dropzoneText}</b>
                      </div>
                    }
                    dropzoneClass={attachmentConfig.containerClass}
                    onChange={(files) => {
                      if (files && files.length > 0) {
                        setFieldValue(name, files[0]);
                        setOpenSnackBar(true);
                      }
                    }}
                    showPreviewsInDropzone={attachmentConfig.showPreviewsInDropzone}
                    showAlerts={false}
                  />
                </div>
              )}

              <Snackbar
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="success"
                  sx={{ width: '100%' }}
                >
                  File {value?.name} successfully added.
                </Alert>
              </Snackbar>
            </>
          )}

          {/* DateTime Picker - if the type is 'datetime' */}
          {type.toLowerCase() === 'datetime' && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="date-div remove-dateField-validation-style alignvh-center w-100" style={{ margin: "0" }}>
                <KeyboardDatePicker
                  autoOk
                  className={`ml-xl-1 col-12 ${inputClassName}`}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  value={value || null}
                  onChange={(date) => setFieldValue(name, date)}
                  onBlur={handleBlur}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  keyboardIcon={
                    <svg width="14" height="16" viewBox="0 0 14 16">
                      <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                    </svg>
                  }
                  disabled={isDisabled}
                />
              </div>
            </MuiPickersUtilsProvider>
          )}

        </>
      )}

      {/* Display error message */}
      {!isReadOnly && submitCount > 0 && isTouched && error && <div className="text-danger" style={{ fontSize: "11px", fontWeight: "400" }}>{error}</div>}
    </>
  );
};

export default GenericFormikInput;

