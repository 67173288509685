import React from "react";
import { useAuthentication } from '../../Data/Authentication';
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import BreadcrumbSteps from "../Layout/BreadcrumbSteps";
import MultipleTabsTitles from "../../Components/Layout/MultipleTabsTitles";
import CarrierTabsGeneralInfo from './CarrierTabs/GenralInfoTab/CarrierTabsGeneralInfo';
import CarrierTabsLegalInfo from './CarrierTabs/LegalInfoTab/CarrierTabsLegalInfo';
import CarrierTabsCarrierRequirements from './CarrierTabs/CarrierRequirementsTab/CarrierTabsCarrierRequirements';
import CarrierTabsTechnicalInfo from './CarrierTabs/TechnicalInfoTab/CarrierTabsTechnicalInfo';
import CarrierTabsOE from "./CarrierTabs/OETab/CarrierTabsOE";
import CarrierTabsConnectivityRequests from "./CarrierTabs/ConnectivityRequestsTab/CarrierTabsConnectivityRequests";
import CarrierTabsChangeLogs from "./CarrierTabs/ChangeLogsTab/CarrierTabsChangeLogs";

function CarrierConfig() {
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const { carrierIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const [carrierDetails, setCarrierDetails] = React.useState();
    const [tabValue, setTabValue] = React.useState(0);
    const [onCarrierReqEdit, setOnCarrierReqEdit] = React.useState(false);
    const [onOeEdit, setOnOeEdit] = React.useState(false);
    const [onLegalInfoEdit, setOnLegalInfoEdit] = React.useState(false);
    const [onTechnicalInfoEdit, setOnTechnicalInfoEdit] = React.useState(false);
    const [onGeneralInfoEdit, setOnGeneralInfoEdit] = React.useState(false);

    const getSingleCarrierHandler = () => {
        openLoader();
        APIs.getSingleCarrierDetails(carrierIDURL)
            .then((r) => setCarrierDetails(r?.data))
            .catch((err) => { console.error("Getting Single Carrier Details Error", err); })
            .finally(() => {
                setOnCarrierReqEdit(false);
                setOnOeEdit(false);
                setOnLegalInfoEdit(false);
                setOnTechnicalInfoEdit(false);
                setOnGeneralInfoEdit(false);
                closeLoader();
            });
        ;
    }

    const isEligibleToViewLegalandTechnicalInfoTabs = () => {
        return userRoles?.includes("7") // Super Admin
            || userRoles?.includes("2") // iSolved Super Admin
            || userRoles?.includes("3") // Admin Direct
            || userRoles?.includes("1") // Analyst
            || (userRoles?.includes("3") && userRoles?.includes("4")); //Combined Admin Direct and Network
    }

    const carrierTabsList = [
        " General Info",
        isEligibleToViewLegalandTechnicalInfoTabs() ? "Legal Info" : null,
        "Carrier Requirements",
        isEligibleToViewLegalandTechnicalInfoTabs() ? "Technical Info" : null,
        "OE",
        "Connectivity Requests",
        "Change Logs"
    ].filter(Boolean);

    const carrierTabsListComponents = [
        <CarrierTabsGeneralInfo carrierDetails={carrierDetails} getSingleCarrierHandler={getSingleCarrierHandler} onEdit={onGeneralInfoEdit} onSetEdit={(s) => setOnGeneralInfoEdit(s)} />,
        isEligibleToViewLegalandTechnicalInfoTabs() ? <CarrierTabsLegalInfo carrierDetails={carrierDetails} getSingleCarrierHandler={getSingleCarrierHandler} onEdit={onLegalInfoEdit} onSetEdit={(s) => setOnLegalInfoEdit(s)} /> : null,
        <CarrierTabsCarrierRequirements carrierDetails={carrierDetails} getSingleCarrierHandler={getSingleCarrierHandler} onEdit={onCarrierReqEdit} onSetEdit={(s) => setOnCarrierReqEdit(s)} />,
        isEligibleToViewLegalandTechnicalInfoTabs() ? <CarrierTabsTechnicalInfo carrierDetails={carrierDetails} getSingleCarrierHandler={getSingleCarrierHandler} onEdit={onTechnicalInfoEdit} onSetEdit={(s) => setOnTechnicalInfoEdit(s)} /> : null,
        <CarrierTabsOE carrierDetails={carrierDetails} getSingleCarrierHandler={getSingleCarrierHandler} onEdit={onOeEdit} onSetEdit={(s) => setOnOeEdit(s)} />,
        <CarrierTabsConnectivityRequests carrierDetails={carrierDetails} />,
        <CarrierTabsChangeLogs carrierDetails={carrierDetails} />,
    ].filter(Boolean);

    const handleTabChange = (e, newValue) => {
        setOnCarrierReqEdit(false);
        setOnOeEdit(false);
        setOnLegalInfoEdit(false);
        setOnTechnicalInfoEdit(false);
        setOnGeneralInfoEdit(false);
        setTabValue(newValue)
    };

    React.useEffect(() => {
        getSingleCarrierHandler()
    }, [])

    return (
        <div id="connect-req-config">
            {carrierDetails && (
                <BreadcrumbSteps
                    breadcrumbTitleCurrent={`${carrierDetails?.CarrierName}`}
                    isCurrent={true}
                />
            )}
            <MultipleTabsTitles tabTitle={carrierTabsList} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />

            <Paper elevation={0} className="p-5">
                {carrierTabsListComponents[tabValue]}
            </Paper>
        </div >
    )
}

export default CarrierConfig;