import React, { useEffect, useState } from 'react'
import { useAuthentication } from '../../../../Data/Authentication';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { convertBoolToYesNo } from '../../../../_helper';
import * as APIs from '../../../../Data/APIs';
import { useLoader } from '../../../Layout/Loader';
import GenericFormikInput from '../../../GenericFormikInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import editRequestIcon from "../../../../Icons/editIcon.svg";
import { Button } from "@material-ui/core";


const validationSchema = Yup.object().shape({

    CarrierName: Yup.string().required('This Field is Required'),
    CarrierHasMinimumSize: Yup.string().required('This Field is Required'),
    CarrierGroupMinimum: Yup.number().typeError('This field must be a number'),

});

const CarrierTabsCarrierRequirements = ({ carrierDetails, getSingleCarrierHandler, onEdit, onSetEdit, ...props }) => {
    const [initialValues, setInitialValues] = React.useState({
        CarrierName: carrierDetails?.CarrierName,
        CarrierPlatforms: carrierDetails?.Requirements?.CarrierPlatforms ? carrierDetails?.Requirements?.CarrierPlatforms : '',
        CarrierHasMinimumSize: carrierDetails?.Requirements?.CarrierHasMinimumSize == null ? 'Unknown' : "" + carrierDetails?.Requirements?.CarrierHasMinimumSize,
        CarrierGroupMinimum: carrierDetails?.Requirements?.CarrierGroupMinimum ? "" + carrierDetails?.Requirements?.CarrierGroupMinimum : '',
        CarrierIndividualsInGroupMinimumIds: carrierDetails?.Requirements?.CarrierIndividualsInGroupMinimumIDs || [],
        CarrierMinimumCanbeLowered: "" + carrierDetails?.Requirements?.CarrierMinimumCanbeLowered,
        CarrierRequirementsNotes: carrierDetails?.Requirements?.CarrierRequirementsNotes ? carrierDetails?.Requirements?.CarrierRequirementsNotes : ''

    })
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const { Requirements } = carrierDetails || {}
    const { openLoader, closeLoader } = useLoader();
    const [individualsGroupMinimum, setIndividualsGroupMinimum] = useState([]);
    // const [onEdit, setOnEdit] = React.useState(false);
    const [expanded, setExpanded] = React.useState("carrierRequirementsTab");

    useEffect(() => {
        openLoader();
        APIs.getCarrierIndividualsInGroupMinimum()
            .then(res => setIndividualsGroupMinimum(res?.data))
            .catch(error => console.log('Getting Carrier Individuals In Group Minimum error:', error))
            .finally(() => closeLoader())
    }, [])

    const handleSubmit = async (values, { setSubmitting }) => {
        openLoader();
        const editCarrierRequirementsDetailsBody = {
            CarrierID: carrierDetails?.CarrierID,
            CarrierPlatforms: values?.CarrierPlatforms,
            CarrierHasMinimumSize: values?.CarrierHasMinimumSize === "Unknown" ? null : +(values?.CarrierHasMinimumSize),
            CarrierGroupMinimum: +(values?.CarrierGroupMinimum),
            CarrierMinimumCanbeLowered: +(values?.CarrierMinimumCanbeLowered) == null ? null : +(values?.CarrierMinimumCanbeLowered),
            Notes: values?.CarrierRequirementsNotes,
            CarrierIndividualsInGroupMinimumIds: values?.CarrierIndividualsInGroupMinimumIds ? values?.CarrierIndividualsInGroupMinimumIds : [],
        }

        APIs.editCarrierRequirementsInfo(editCarrierRequirementsDetailsBody).then((r) => {
            getSingleCarrierHandler();

        });

    };

    const handleCancel = (resetForm) => {
        resetForm();
        onSetEdit(false);
    };


    return (
        <React.Fragment>
            {/* Carrier Requirements */}
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }) =>
                (
                    <Form className='d-flex flex-column'>
                        <Accordion defaultExpanded expanded={expanded === 'carrierRequirementsTab'}>
                            <AccordionSummary
                                aria-controls="panel2-content"
                                id="panel-header-cr-config"
                            >
                                <span>Carrier Requirements</span>
                                {(userRoles?.includes("7") // Super Admin
                                    // || userRoles?.includes("2")  //iSolved Super Admin (this line needs to be uncommented next sprint)
                                ) && (

                                        <Button variant="contained" className="blue-outlined-btn mr-2 ml-auto" onClick={() => {
                                            if (onEdit) {
                                                handleCancel(resetForm);
                                            } else {
                                                onSetEdit(true);
                                            }
                                        }} >
                                            <svg width="20" height="24" viewBox="0 0 25 24">
                                                <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                            </svg>
                                            <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                                        </Button>
                                    )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Carrier Name'}
                                            name={'CarrierName'}
                                            type={'text'}
                                            isRequired={true}
                                            isReadOnly={true}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.CarrierName
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Carrier Platforms'}
                                            name={'CarrierPlatforms'}
                                            type={'textarea'}
                                            moreInfo='Some Carriers have multiple client platforms'
                                            isRequired={false}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                extraCssClasses: "max-height preformatted-text",
                                                valueToShow: Requirements?.CarrierPlatforms
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Carrier Has Minimum Group Size Requirements'}
                                            name={'CarrierHasMinimumSize'}
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: [{ optionNameKey: "Yes", optionValueKey: "1" }, { optionNameKey: "No", optionValueKey: "0" }, { optionNameKey: "Unknown", optionValueKey: "Unknown" }],
                                                    nameKey: 'optionNameKey',
                                                    valueKey: 'optionValueKey',

                                                }}
                                            isRequired={true}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.Requirements?.CarrierHasMinimumSize === 1 ? "Yes" : carrierDetails?.Requirements?.CarrierHasMinimumSize === 0 ? "No" : "Unknown"
                                            }}
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Group Minimum'}
                                            name={'CarrierGroupMinimum'}
                                            type={'text'}
                                            isRequired={false}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: Requirements?.CarrierGroupMinimum
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Can the minimum be lowered?'}
                                            name={'CarrierMinimumCanbeLowered'}
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: [{ optionNameKey: "Yes", optionValueKey: "1" }, { optionNameKey: "No", optionValueKey: "0" }],
                                                    nameKey: 'optionNameKey',
                                                    valueKey: 'optionValueKey',

                                                }}
                                            isRequired={false}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: convertBoolToYesNo(Requirements?.CarrierMinimumCanbeLowered)
                                            }}
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label="Included Individuals in Calculating the Group Minimum"
                                            type="multiselect"
                                            name="CarrierIndividualsInGroupMinimumIds"
                                            isRequired={false}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: individualsGroupMinimum
                                                    .filter(el => Requirements?.CarrierIndividualsInGroupMinimumIDs?.includes(el.CarrierIndividualsInGroupMinimumId))
                                                    .map(el => el.CarrierIndividualsInGroupMinimumName)
                                                    .join(', ')
                                            }}
                                            multiSelectConfig={{
                                                type: "dropdown-multiselect",
                                                optionsListMenu: individualsGroupMinimum || [],
                                                valueKey: 'CarrierIndividualsInGroupMinimumId',
                                                nameKey: 'CarrierIndividualsInGroupMinimumName',
                                                seprator: ' , '
                                            }}
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <GenericFormikInput
                                            label={'Carrier Requirement Notes'}
                                            name={'CarrierRequirementsNotes'}
                                            type={'textarea'}
                                            isRequired={false}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                extraCssClasses: "max-height preformatted-text",
                                                valueToShow: Requirements?.CarrierRequirementsNotes
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>


                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {onEdit && (
                            <div className="w-100 text-right mt-5">
                                <Button type="button" variant="contained" className="blue-outlined-btn mr-2" onClick={() => handleCancel(resetForm)}>Cancel</Button>
                                <Button type="submit" variant="contained" className="blue-btn" >Submit</Button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default CarrierTabsCarrierRequirements
