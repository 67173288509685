import React, { useEffect } from "react";
import GenericTable from "../../../Layout/GenericTable";
import Pagination from "../../../Layout/Pagination";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import { useParams } from "react-router-dom";
import Truncate from "react-truncate";
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';


const CarrierTabsChangeLogs = ({ carrierDetails, ...props }) => {
  const dialog = useModalDialog(0);
  const { carrierIDURL } = useParams();
  const { openLoader, closeLoader } = useLoader();
  const [carrierChangeLogs, setCarrierChangeLogs] = React.useState([]);
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(30);
  const [totalCount, setTotalCount] = React.useState(0);
  // test
  useEffect(() => {
    const paginationObject = {
      CarrierID: carrierIDURL,
      Limit: pageSize,
      PageNumber: selectedPage,
    };

    openLoader();
    APIs.getSingleCarrieChangeLogs(paginationObject)
      .then((res) => {
        const { totalCount, list } = res?.data || {};
        setTotalCount(totalCount);
        setCarrierChangeLogs(list);
      })
      .catch((err) =>
        console.log("Getting Change Logs for Single Carrier Error :", err)
      )
      .finally(() => closeLoader());
  }, [selectedPage, pageSize]);

  const baseColumnsArray = [
    {
      name: "Carrier Name",
      sortable: false,
      id: "CarrierName",
      selector: (c) => carrierDetails?.CarrierName,
    },
    {
      name: "Carrier Code",
      sortable: false,
      id: "CarrierCode",
      selector: (c) => carrierDetails?.GeneralInfo?.CarrierNumber,
    },
    {
      name: "Field",
      sortable: false,
      id: "Field",
      selector: (c) => c?.ChangedField,
    },
    {
      name: "Old Value",
      sortable: false,
      id: "OldValue",
      selector: c => <Truncate lines={3} ellipsis={<span>...{' '} <button className='btn-like-link fs-13' style={{ color: '#2c87f0' }} onClick={e => { e.stopPropagation(); dialog.openDialog( 'Old Value:', <div><p style={{ wordBreak: 'break-word' }}>{c?.OldValue}</p> </div>,[] ); }}> Read More </button> </span> }>
                        {c?.OldValue}
                      </Truncate>,
    },
    {
      name: "New Value",
      sortable: false,
      id: "NewValue",
      selector: c => <Truncate lines={3} ellipsis={<span>...{' '} <button className='btn-like-link fs-13' style={{ color: '#2c87f0' }} onClick={e => { e.stopPropagation(); dialog.openDialog( 'New Value:', <div> <p style={{ wordBreak: 'break-word' }}>{c?.NewValue}</p> </div>,[] ); }}> Read More </button> </span> }>
                        {c?.NewValue}
                      </Truncate>,
    },
    {
      name: "Last Modified Date",
      sortable: false,
      id: "LastModifiedDate",
      selector: (c) => c?.Updated,
    },
    {
      name: "Last Modified By",
      sortable: false,
      id: "LastModifiedBy",
      selector: (c) => c?.ChangedBy,
    },
  ];

  return (
    <React.Fragment>
      <GenericTable
        columns={[...baseColumnsArray]}
        data={carrierChangeLogs}
        noDataComponent={
          <div className="no-data-alert">
            <h6>No Results Found!</h6>
          </div>
        }
        onSort={() => {}}
      />
      <div>
        <Pagination
          stateLimit={true}
          pageSize={pageSize}
          page={selectedPage}
          onChange={(t, v) => {
            if (t === 1) setPageSize(v);
            else if (t === 2) setSelectedPage(v);
          }}
          count={totalCount}
        />
      </div>
    </React.Fragment>
  );
};

export default CarrierTabsChangeLogs;
