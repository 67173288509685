import React, { useState } from "react";
import { Divider, Paper } from "@material-ui/core";
import TabsTitles from "../../Layout/TabsTitles";
import dateTimeSVG from "../../../Images/dateTime.svg";
import BreadcrumbSteps from "../../Layout/BreadcrumbSteps";
import * as APIs from "../../../Data/APIs";
import { useParams } from "react-router-dom";
import Moment from 'moment';
import { useLoader } from "../../Layout/Loader"
import { useHistory } from "react-router-dom";
import CreateConnectivity from "./CreateConnectivity";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import Grid from '@mui/material/Grid';

function AddConnectivity() {
    const { groupRequestIDURL } = useParams();
    const [groupReq, setGroupReq] = useState();
    const routeHistory = useHistory();


    const { openLoader, closeLoader } = useLoader();

    const [expanded, setExpanded] = React.useState("connectivityRequest");
    const carrierContactTypes = ["Test", "Production/Data Issues"];


    // Get group request data to the view group request page
    React.useEffect(() => {
        if (groupReq?.groupRequest?.IsPEO === 1) closeLoader();
        else {
            openLoader();
            APIs.getGroupRequestData(groupRequestIDURL).then((r) => setGroupReq({ groupRequest: r?.data?.GroupRequest, connectivities: r?.data?.connectivities }));
        }
    }, [])

    return (
        <div id="view-group-request">
            {groupReq && (
                <BreadcrumbSteps
                    breadcrumbTitle={`View Group Request  "${groupReq?.groupRequest?.Peo?.PeoName ? groupReq?.groupRequest?.Peo?.PeoName : groupReq?.groupRequest?.GroupName}"`}
                    isCurrent={false}
                    breadcrumbTitleCurrent="Add Connectivity"
                    breadcrumbLink={`/GroupRequests/ViewGroupRequest/${groupReq?.groupRequest?.GroupRequestID}`}
                />
            )}
            <TabsTitles tabTitle={["Add Connectivity Request"]} />
            <Paper elevation={0} className="p-5">
                {/* <div className="row w-100 alignvh-center">
                    <h5 className="black1 fw-700">Group Request Information</h5>
                </div> */}
                {/* <Divider className="mt-3 mb-5" /> */}
                <Accordion defaultExpanded>
                    <AccordionSummary
                        aria-controls="panel2-content"
                        id="panel-header-cr-config"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        style={{ pointerEvents: 'none' }}
                    >
                        <span>Group Request Information</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Instance URL</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.GroupInstanceURL}
                                </div>
                            </Grid>

                            {groupReq?.groupRequest?.IsPEO !== 1 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Client Name</h6>

                                        <div className="textfield-div">
                                            {groupReq?.groupRequest?.GroupName}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Client Number</h6>

                                        <div className="textfield-div">
                                            {groupReq?.groupRequest?.GroupNumber}
                                        </div>
                                    </Grid>
                                </>
                            )}

                            {groupReq?.groupRequest?.IsPEO === 1 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">PEO Name</h6>

                                        <div className="textfield-div">
                                            {groupReq?.groupRequest?.Peo?.PeoName}
                                        </div>
                                    </Grid>
                                </>
                            )}


                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Client Size</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.GroupSize}
                                </div>
                            </Grid>

                            {/* <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Client Effective Date</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {groupReq?.groupRequest?.GroupEffectiveDate ? Moment(groupReq?.groupRequest?.GroupEffectiveDate).format('MM/DD/YYYY') : ""}
                        </div>
                    </div> */}

                            {groupReq?.groupRequest?.SubGroups?.length > 0 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Subgroups</h6>

                                        <div className="textfield-div checkbox-list">
                                            {groupReq?.groupRequest?.SubGroups?.map((s, si) => (
                                                <div key={`subgroups-${si}`}>{`${s.GroupName} (${s?.GroupNumber})`}</div>
                                            ))}
                                        </div>
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">OE Contact</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.OEContact}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Migration</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.MigrationGroupRequest}
                                </div>
                            </Grid>

                            {/* Migration Added Field*/}
                            {(groupReq?.groupRequest?.MigrationGroupRequest === "Everything Benefits" || groupReq?.groupRequest?.MigrationGroupRequest === "eBenefits Network") &&
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Migration Start Date</h6>

                                        <div className="textfield-div">
                                            {groupReq?.groupRequest?.MigrationStartDate ? Moment(groupReq?.groupRequest?.MigrationStartDate).format('MM/DD/YYYY') : null}
                                            <div className="ml-auto">
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Planned First Production Date
                                        </h6>

                                        <div className="textfield-div">
                                            {groupReq?.groupRequest?.PlannedFirstProductionDate ? Moment(groupReq?.groupRequest?.PlannedFirstProductionDate).format('MM/DD/YYYY') : null}
                                            <div className="ml-auto">
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>


                                    </Grid>


                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Migration Phase</h6>

                                        <div className="textfield-div">
                                            {groupReq?.groupRequest?.MigrationPhaseName}
                                        </div>

                                    </Grid>

                                </>
                            }

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Status</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.GroupRequestStatusName}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Phase</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.PhaseName}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Submitted Date</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.Created ? Moment(groupReq?.groupRequest?.Created).format('MM/DD/YYYY') : null}
                                    <div className="ml-auto">
                                        <svg width="14" height="16" viewBox="0 0 14 16">
                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                        </svg>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Created By</h6>

                                <div className="textfield-div">
                                    {groupReq?.groupRequest?.CreatedBy}
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Grid item xs={12}>
                    <div className="mt-5">
                        <CreateConnectivity groupReq={groupReq} carrierContactTypes={carrierContactTypes} />
                    </div>
                </Grid>

            </Paper>
        </div >
    )
}

export default AddConnectivity;